<template>
  <div
    class="user-id"
    :class="{
      'user-id--disabled': userDisabled,
    }"
  >
    <UserAvatar
      :image="image ?? null"
      :initials="helperService.userInitials(userNameFirstName, userNameLastName)"
      :size="userAvatarSize"
      :color="userAvatarColor"
      :isDisabled="userDisabled"
    />
    <UserName
      :firstName="userNameFirstName"
      :lastName="userNameLastName"
      :size="userNameSize"
      :layout="userNameLayout"
      :order="userNameOrder"
      :isDisabled="userDisabled"
    />
  </div>
</template>

<script>
import UserName from '@/components/user/UserName.vue'
import UserAvatar from '@/components/user/UserAvatar.vue'

export default {
  name: 'UserID',

  components: {
    UserName,
    UserAvatar,
  },

  props: {
    // Avatar
    userAvatarImage: {
      type: Object,
    },
    userAvatarSize: {
      type: String,
      default: 'normal',
    },
    userAvatarColor: {
      type: [String, Number],
      default: '18',
    },
    // User name
    userNameFirstName: {
      type: String,
      required: true,
    },
    userNameLastName: {
      type: String,
      required: true,
    },
    userNameSize: {
      type: String,
      default: 'normal',
    },
    userNameLayout: {
      type: String,
      default: 'inline',
    },
    userNameOrder: {
      type: String,
      default: 'normal',
    },
    userDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      componentImage: null,
    }
  },

  watch: {
    image(n) {
      this.image = n
    },
  },

  computed: {
    image: {
      get() {
        return this.userAvatarImage !== undefined
          ? this.userAvatarImage
          : this.$store.state.auth.user.data.avatar
      },
      set(val) {
        this.componentImage = val
      },
    },
  },

}
</script>

<style lang="scss" scoped>
/* USER ID */

.user-id {
  display: flex;
  align-items: center;

  > .user-avatar {
    margin-right: $gutter-eighth * 3; // 12px
    flex-shrink: 0;
  }

  a > & {

    > .user-name {
      color: $body-color-base;
    }

    @include hocus() {
      > .user-name {
        text-decoration: underline;
      }
    }
  }

  .topbar & {
    > .user-avatar {
      margin-right: 0;
    }

    > .user-name {
      display: none;
    }

    @include bp($breakpoint-user-menu-with-name) {
      > .user-avatar {
        margin-right: $gutter-quarter;
      }

      > .user-name {
        display: initial;
      }
    }
  }
}
</style>
