<template>
  <div class="alert"
    :class="`alert--${type} alert--${active ? 'visible' : 'hidden'}`"
  >
    <div class="alert-icon">
      <SvgIcon :name="iconName" />
    </div>
    <div v-if="content" class="alert-content"  v-html="content"  />
    <div v-else class="alert-content" ><slot></slot></div>

    <div v-if="hasCloseBtn" class="alert-close">
      <Btn @click="active = false" icon="times-circle" round grow/>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/base/SvgIcon.vue'
import Btn from '@/components/base/Btn.vue'

const iconNames = {
  info: 'info-circle',
  success: 'check-circle',
  warning: 'exclamation-triangle',
  error: 'exclamation-triangle',
}

export default {
  name: 'alert',

  components: {
    SvgIcon,
    Btn,
  },

  props: {
    type: {
      type: String,
      default: 'info', // Can be 'info', 'success', 'warning' or 'error'
    },
    content: {
      type: String,
      required: false,
    },
    hasCloseBtn: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      active: true,
    }
  },

  computed: {
    iconName() {
      return iconNames[this.type]
    },
  },
}
</script>

<style lang="scss" scoped>
/* ALERT */

.alert {
  display: flex;
  align-items: center;
  padding: $gutter-quarter $gutter-half;
  border-radius: $border-radius-big;
  color: white;
  @include shadow(1);
  transition: all 0.5s;
}

.alert--hidden {
  display: none; // TODO: animate collapse / fade out
}

.alert-icon {
  height: $icon-size-base;
  margin-right: $gutter-half;
}

.alert-content {
  flex-grow: 1;
}

.alert--info { background-color: $color-info; }
.alert--success { background-color: $color-success; }
.alert--warning { background-color: $color-warning; }
.alert--error { background-color: $color-error; }
</style>
