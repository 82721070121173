export default [
  /**
   * My entity
   */
  {
    path: '/mon-entite',
    name: 'myEntity',
    meta: {
      public: false,
      title: 'Mon entité',

    },
    component: () => import(
      '@/views/entity/EntityView.vue'
    ),
  },
  {
    path: '/mon-entite/edition',
    name: 'myEntityEdit',
    meta: {
      public: false,
      title: 'Modifier mon entité',
    },
    component: () => import(
      '@/views/entity/EntityAddEditView.vue'
    ),
  },
  {
    path: '/mon-entite/parametres',
    name: 'myEntityParameter',
    meta: {
      public: false,
      title: 'Paramètres de mon entité',
      permissions: 'entiteparametre_show',
    },
    component: () => import(
      '@/views/entity/MyEntity/MyEntityParameterView.vue'
    ),
  },
  {
    path: '/mon-entite/parametre/:id?/edition',
    name: 'myEntityParameterEdit',
    meta: {
      public: false,
      title: 'Édition d\'un paramètre de mon entité',
      permissions: 'entiteparametre_edit',
    },
    component: () => import(
      '@/views/entity/MyEntity/MyEntityParameterEditView.vue'
    ),
  },
]
