/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const initialState = {
  selection: {
    data: [],
  },
}

// initial state
const state = () => (initialState)

// actions
const actions = {
  save({ commit }, data) {
    commit('updateSelection', data)
  },
  remove({ commit }) {
    commit('clearSelection')
  },
  clear({ commit }) {
    commit('clearSelection')
  },
}

// mutations
const mutations = {
  clearSelection(state) {
    state.selection.data = []
    console.log('Clear', state.selection.data)
  },
  updateSelection(state, data) {
    state.selection.data = [...state.selection.data, data]
    console.log('Store', state.selection.data)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
