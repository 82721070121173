export default [
  // Essais
  { // Listing
    path: '/essais',
    name: 'essais',
    meta: {
      title: 'Essais',
      public: false,
      permissions: 'essai_list',
    },
    component: () => import(
      '@/views/essais/Essais/EssaisView.vue'
    ),
  },

  { // Listing des essais des entités filles
    path: '/essais/entites-filles',
    name: 'essaisChildren',
    meta: {
      title: 'Essais de mes entités filles',
      public: false,
      permissions: 'essai_list',
    },
    component: () => import(
      '@/views/essais/Essais/Children/EssaisView.vue'
    ),
  },

  // Bibliothèque de plans
  {
    path: '/essais/plans',
    name: 'essaisLibrary',
    meta: {
      title: 'Bibliothèque de plans',
      public: false,
      permissions: 'essaiplan_list',
    },
    component: () => import(
      '@/views/essais/Essais/EssaisLibraryView.vue'
    ),
  },

  { // Consultation
    path: '/essai/:id',
    name: 'essai',
    meta: {
      title: 'Consulter un essai',
      permissions: 'essai_show',
    },
    component: () => import(
      '@/views/essais/Essais/EssaiView.vue'
    ),
    redirect: { name: 'essaiGeneralInformation' },
    children: [
      { // Informations générales
        path: '',
        name: 'essaiGeneralInformation',
        meta: {
          title: 'Informations générales',
        },
        component: () => import(
          '@/views/essais/Essais/SingleSubviews/GeneralInformationSubview.vue'
        ),
      },
      { // Fiche parcellaire
        path: 'parcelle',
        name: 'essaiParcelle',
        meta: {
          title: 'Fiche parcellaire',
        },
        component: () => import(
          '@/views/essais/Essais/SingleSubviews/ParcelSubview.vue'
        ),
      },

      { // Visualisation du plan
        path: 'plan',
        name: 'essaiTestPlan',
        meta: {
          title: 'Plan d’essai',
        },
        component: () => import(
          '@/views/essais/Essais/SingleSubviews/TestPlanSubview.vue'
        ),
      },

      { // Calendrier
        path: 'calendrier',
        name: 'essaiCalendar',
        meta: {
          title: 'Calendrier',
          permissions: 'essaievenement_olist',
        },
        component: () => import(
          '@/views/essais/Essais/EssaiCalendarView.vue'
        ),
      },

      { // Fiche bilan
        path: 'bilan',
        name: 'essaiFicheBilan',
        meta: {
          title: 'Fiche bilan',
          permissions: 'essaifichebilan_olist',
        },
        component: () => import(
          '@/views/essais/Essais/SingleSubviews/FicheBilanSubview.vue'
        ),
      },
      { // Fichier
        path: 'fichiers',
        name: 'essaiFiles',
        meta: {
          title: 'Fichiers',
          permissions: 'essaifichier_show,fichier_list',
        },
        component: () => import(
          '@/views/essais/Essais/EssaiFilesView.vue'
        ),
      },
      { // Rapport statistiques
        path: 'rapports-statistiques',
        name: 'essaiFixedReports',
        meta: {
          title: 'Rapports statistiques',
          permissions: 'essairapport_olist',
        },
        component: () => import(
          '@/views/essais/Essais/EssaiFixedReportsView.vue'
        ),
        children: [

        ],
      },
      { // Rapport statistiques
        path: 'rapports-statistiques/:rfid',
        name: 'essaiFixedReport',
        meta: {
          title: 'Rapport statistique',
          permissions: 'essairapport_olist',
        },
        component: () => import(
          '@/views/essais/Essais/EssaiFixedReportView.vue'
        ),
      },
      { // Import / Export
        path: 'import-export',
        name: 'essaiImportExport',
        meta: {
          title: 'Import / Export',
          // permissions: 'essairapport_olist',
        },
        component: () => import(
          '@/views/essais/Essais/EssaiImportExportListView.vue'
        ),
      },
      { // Import
        path: 'import',
        name: 'essaiImport',
        meta: {
          title: 'Import',
          // permissions: 'essairapport_olist',
        },
        component: () => import(
          '@/views/essais/Essais/EssaiImportView.vue'
        ),
        redirect: { name: 'essaiImportDataAssignement' },
        children: [
          { // Assignation des variables
            path: 'assignation-variables',
            name: 'essaiImportDataAssignement',
            meta: {
              title: 'Assignation des variables',
            },
            component: () => import(
              '@/views/essais/Essais/EssaiImportDataAssignementView.vue'
            ),
          },
          { // Rapport préliminaire
            path: 'rapport-preliminaire',
            name: 'essaiImportReport',
            meta: {
              title: 'Rapport préliminaire',
            },
            component: () => import(
              '@/views/essais/Essais/EssaiImportPreliminaryReportView.vue'
            ),
          },
          { // Rapport préliminaire
            path: 'recapitulatif',
            name: 'essaiImportResume',
            meta: {
              title: 'Récapitulatif',
            },
            component: () => import(
              '@/views/essais/Essais/EssaiImportPreliminaryReportView.vue'
            ),
          },
        ],
      },
    ],
  },

  { // Consultation essais des entités filles
    path: '/essai/:id/entites-filles',
    name: 'essaiChild',
    meta: {
      title: 'Consulter un essai d\'une entité fille',
      permissions: 'essai_show',
    },
    component: () => import(
      '@/views/essais/Essais/Children/EssaiView.vue'
    ),
    redirect: { name: 'essaiChildGeneralInformation' },
    children: [
      { // Informations générales
        path: '',
        name: 'essaiChildGeneralInformation',
        meta: {
          title: 'Informations générales',
        },
        component: () => import(
          '@/views/essais/Essais/Children/SingleSubviews/GeneralInformationSubview.vue'
        ),
      },
      { // Fiche parcellaire
        path: 'parcelle',
        name: 'essaiChildParcelle',
        meta: {
          title: 'Fiche parcellaire',
        },
        component: () => import(
          '@/views/essais/Essais/Children/SingleSubviews/ParcelSubview.vue'
        ),
      },

      { // Visualisation du plan
        path: 'plan',
        name: 'essaiChildTestPlan',
        meta: {
          title: 'Plan d’essai',
        },
        component: () => import(
          '@/views/essais/Essais/Children/SingleSubviews/TestPlanSubview.vue'
        ),
      },

      { // Calendrier
        path: 'calendrier',
        name: 'essaiChildCalendar',
        meta: {
          title: 'Calendrier',
          permissions: 'essaievenement_olist',
        },
        component: () => import(
          '@/views/essais/Essais/Children/EssaiCalendarView.vue'
        ),
      },

      { // Fiche bilan
        path: 'bilan',
        name: 'essaiChildFicheBilan',
        meta: {
          title: 'Fiche bilan',
          permissions: 'essaifichebilan_olist',
        },
        component: () => import(
          '@/views/essais/Essais/Children/SingleSubviews/FicheBilanSubview.vue'
        ),
      },
      { // Fichier
        path: 'fichiers',
        name: 'essaiChildFiles',
        meta: {
          title: 'Fichiers',
          permissions: 'essaifichier_show,fichier_list',
        },
        component: () => import(
          '@/views/essais/Essais/Children/EssaiFilesView.vue'
        ),
      },
      { // Rapport statistiques
        path: 'rapports-statistiques',
        name: 'essaiChildFixedReports',
        meta: {
          title: 'Rapports statistiques',
          permissions: 'essairapport_olist',
        },
        component: () => import(
          '@/views/essais/Essais/Children/EssaiFixedReportsView.vue'
        ),
      },
    ],
  },

  { // Consulter tâches expérimentales des essais des entites filles
    path: '/essai/:id/entites-filles/tache-experimentale/:tid',
    name: 'essaiExperimentalTaskChild',
    meta: {
      title: 'Consulter une tâche expérimentale',
      permissions: 'essaievenement_oshow',
    },
    component: () => import(
      '@/views/essais/Essais/Children/ExperimentalTask/ExperimentalTaskView.vue'
    ),
    redirect: { name: 'essaiExperimentalTaskGeneralInformationChild' },
    children: [
      { // Informations générales
        path: '',
        name: 'essaiExperimentalTaskGeneralInformationChild',
        meta: {
          title: 'Informations générales',
        },
        component: () => import(
          '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/GeneralInformationSubview.vue'
        ),
      },
      { // Organe
        path: 'organe',
        name: 'essaiExperimentalTaskOrganChild',
        meta: {
          title: 'Organe',
        },
        component: () => import(
          '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/OrganSubview.vue'
        ),
      },
      { // Contamination artificielle
        path: 'contamination-artificielle',
        name: 'essaiExperimentalTaskArtificialContaminationChild',
        meta: {
          title: 'Contamination artificielle',
        },
        component: () => import(
          '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/ArtificialContaminationSubview.vue'
        ),
      },
      // Conditions récolte
      {
        path: 'conditions-recolte',
        name: 'essaiExperimentalTaskConditionsRecolteChild',
        meta: {
          title: 'Conditions de récolte',
          public: false,
        },
        component: () => import(
          '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/ConditionsRecolteSubview.vue'
        ),
      },
      // Destruction en végétation
      {
        path: 'destruction-vegetation',
        name: 'essaiExperimentalTaskDestructionVegetationChild',
        meta: {
          title: 'Destruction en végétation',
          public: false,
        },
        component: () => import(
          '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/DestructionSubview.vue'
        ),
      },
      // Destruction en récolte
      {
        path: 'destruction-recolte',
        name: 'essaiExperimentalTaskDestructionRecolteChild',
        meta: {
          title: 'Destruction en récolte',
          public: false,
        },
        component: () => import(
          '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/DestructionSubview.vue'
        ),
      },
      {
        path: 'preuves-destruction',
        name: 'essaiExperimentalTaskDestructionProofChild',
        meta: {
          title: 'Preuves de destruction',
          public: false,
        },
        component: () => import(
          '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/DestructionProofSubview.vue'
        ),
      },

      // Notations
      {
        path: 'parametre',
        name: 'essaiExperimentalTaskParameterChild',
        meta: {
          title: 'Paramètres',
          public: false,
        },
        component: () => import(
          '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/ParameterSubview.vue'
        ),
      },
      // variables etudiees
      {
        path: 'variables-etudiees',
        name: 'essaiExperimentalTaskVariableStudiedListChild',
        meta: {
          title: 'Variables étudiées',
          public: false,
        },
        component: () => import(
          '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/VariablesStudiedList.vue'
        ),
      },
      {
        path: 'variable-etudiee/:veid/definition',
        name: 'essaiExperimentalTaskVariableStudiedSettingsChild',
        meta: {
          title: 'Variable étudiée',
          public: false,
        },
        component: () => import(
          '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/VariableStudiedSubview.vue'
        ),
      },
      {
        path: 'variable-etudiee/:veid/niveau-saisie',
        name: 'essaiExperimentalTaskVariableStudiedEntryLevelChild',
        meta: {
          title: 'Variable étudiée',
          public: false,
        },
        component: () => import(
          '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/VariableStudiedSubview.vue'
        ),
      },
      {
        path: 'reservation',
        name: 'essaiExperimentalTaskNotationStateChild',
        meta: {
          public: false,
        },
        component: () => import(
          '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/NotationStateSubview.vue'
        ),
      },
      // variables etudiees
      {
        path: 'variables-calculees',
        name: 'essaiExperimentalTaskVariableCalculatedChild',
        meta: {
          title: 'Variables calculées',
          public: false,
        },
        component: () => import(
          '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/VariableCalculatedSubview.vue'
        ),
      },
    ],
  },

  { // Création rapport fixe entités filles
    path: '/essai/:id/entites-filles/rapport-statistiques/creer',
    name: 'essaiChildFixedReportAddView',
    meta: {
      title: 'Création d\'un rapport fixe d\'une entité fille',
      permissions: 'essairapport_oadd',
    },
    component: () => import(
      '@/views/essais/Essais/Children/EssaiFixedReportsAddEditView.vue'
    ),
    redirect: { name: 'essaiChildFixedReportTitleAdd' },
    children: [
      { // Informations générales
        path: ':rid?/intitule',
        name: 'essaiChildFixedReportTitleAdd',
        meta: {
          title: 'Intitulé',
        },
        component: () => import(
          '@/views/essais/Essais/Children/FixedReport/TitleAddSubview.vue'
        ),
      },
      { // Informations générales
        path: ':rid?/modalites',
        name: 'essaiChildFixedReportModalitiesAdd',
        meta: {
          title: 'Modalités',
        },
        component: () => import(
          '@/views/essais/Essais/Children/FixedReport/ModalitiesAddSubview.vue'
        ),
      },
      { // Informations générales
        path: ':rid?/repetitions',
        name: 'essaiChildFixedReportRepetitionsAdd',
        meta: {
          title: 'Répétitions',
        },
        component: () => import(
          '@/views/essais/Essais/Children/FixedReport/RepetitionsAddSubview.vue'
        ),
      },
      { // Informations générales
        path: ':rid?/variables',
        name: 'essaiChildFixedReportVariablesAdd',
        meta: {
          title: 'Variables',
        },
        component: () => import(
          '@/views/essais/Essais/Children/FixedReport/VariablesAddSubview.vue'
        ),
      },
    ],
  },

  { // Création
    path: '/essai/creer',
    name: 'essaiAddView',
    meta: {
      title: 'Création d\'un essai',
      permissions: 'essai_add',
    },
    component: () => import(
      '@/views/essais/Essais/EssaiAddEditView.vue'
    ),
    redirect: { name: 'essaiGeneralInformationAdd' },
    children: [
      { // Informations générales
        path: ':id?/informations-generales',
        name: 'essaiGeneralInformationAdd',
        meta: {
          title: 'Informations générales',
        },
        component: () => import(
          '@/views/essais/Essais/AddEditSubviews/GeneralInformationAddEditSubview.vue'
        ),
      },
      { // Parcelle
        path: ':id?/parcelle',
        name: 'essaiParcelleAdd',
        meta: {
          title: 'Parcelle',
        },
        component: () => import(
          '@/views/essais/Essais/AddEditSubviews/ParcelleAddEditSubview.vue'
        ),
      },
      { // Plan de l\'essai
        path: ':id?/plan',
        name: 'essaiTestPlanAdd',
        meta: {
          title: 'Plan de l\'essai',
        },
        component: () => import(
          '@/views/essais/Essais/AddEditSubviews/TestPlanAddEditSubview.vue'
        ),
      },
    ],
  },

  { // Création rapport fixe
    path: '/essai/:id/rapport-statistiques/creer',
    name: 'essaiFixedReportAddView',
    meta: {
      title: 'Création d\'un rapport fixe',
      permissions: 'essairapport_oadd',
    },
    component: () => import(
      '@/views/essais/Essais/EssaiFixedReportsAddEditView.vue'
    ),
    redirect: { name: 'essaiFixedReportTitleAdd' },
    children: [
      { // Informations générales
        path: ':rid?/intitule',
        name: 'essaiFixedReportTitleAdd',
        meta: {
          title: 'Intitulé',
        },
        component: () => import(
          '@/views/essais/Essais/AddEditSubviews/FixedReport/TitleAddSubview.vue'
        ),
      },
      { // Informations générales
        path: ':rid?/modalites',
        name: 'essaiFixedReportModalitiesAdd',
        meta: {
          title: 'Modalités',
        },
        component: () => import(
          '@/views/essais/Essais/AddEditSubviews/FixedReport/ModalitiesAddSubview.vue'
        ),
      },
      { // Informations générales
        path: ':rid?/repetitions',
        name: 'essaiFixedReportRepetitionsAdd',
        meta: {
          title: 'Répétitions',
        },
        component: () => import(
          '@/views/essais/Essais/AddEditSubviews/FixedReport/RepetitionsAddSubview.vue'
        ),
      },
      { // Informations générales
        path: ':rid?/variables',
        name: 'essaiFixedReportVariablesAdd',
        meta: {
          title: 'Variables',
        },
        component: () => import(
          '@/views/essais/Essais/AddEditSubviews/FixedReport/VariablesAddSubview.vue'
        ),
      },
    ],
  },

  { // Edition
    path: '/essai/editer/:id',
    name: 'essaiEditView',
    meta: {
      title: 'Modifier un essai',
      permissions: 'essai_edit',
    },
    component: () => import(
      '@/views/essais/Essais/EssaiAddEditView.vue'
    ),
    redirect: { name: 'essaiGeneralInformationEdit' },
    children: [
      { // Informations générales
        path: 'informations-generales',
        name: 'essaiGeneralInformationEdit',
        meta: {
          title: 'Informations générales',
        },
        component: () => import(
          '@/views/essais/Essais/AddEditSubviews/GeneralInformationAddEditSubview.vue'
        ),
      },
      { // Parcelle
        path: 'parcelle',
        name: 'essaiParcelleEdit',
        meta: {
          title: 'Parcelle',
        },
        component: () => import(
          '@/views/essais/Essais/AddEditSubviews/ParcelleAddEditSubview.vue'
        ),
      },
      { // Plan de l\'essai
        path: 'plan',
        name: 'essaiTestPlanEdit',
        meta: {
          title: 'Plan de l\'essai',
        },
        component: () => import(
          '@/views/essais/Essais/AddEditSubviews/TestPlanAddEditSubview.vue'
        ),
      },
    ],
  },

  { // Bilan
    path: '/essai/editer/:id/bilan',
    name: 'essaiFicheBilanEdit',
    meta: {
      title: 'Bilan',
      public: false,
    },
    component: () => import(
      '@/views/essais/Essais/AddEditSubviews/FicheBilanAddEditSubview.vue'
    ),
  },

  { // Ajouter/Editer tâches expérimentales
    path: '/essai/:id/tache-experimentale/:tid/editer',
    name: 'essaiExperimentalTaskEdit',
    meta: {
      title: 'Éditer une tâche expérimentale',
      permissions: 'essaievenement_oadd,essaievenement_oedit',
    },
    component: () => import(
      '@/views/essais/ExperimentalTask/ExperimentalTaskAddEditView.vue'
    ),
    redirect: { name: 'essaiExperimentalTaskGeneralInformationEdit' },
    children: [
      { // Informations générales
        path: '',
        name: 'essaiExperimentalTaskGeneralInformationEdit',
        meta: {
          title: 'Informations générales',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/GeneralInformationSubview.vue'
        ),
      },
      { // Organe
        path: 'organe',
        name: 'essaiExperimentalTaskOrganEdit',
        meta: {
          title: 'Organe',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/OrganSubview.vue'
        ),
      },
      { // Contamination artificielle
        path: 'contamination-artificielle',
        name: 'essaiExperimentalTaskArtificialContaminationEdit',
        meta: {
          title: 'Contamination artificielle',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/ArtificialContaminationSubview.vue'
        ),
      },
      { // Traitement phyto et ferti
        path: 'fiche-pesees/phytosanitaire',
        name: 'essaiExperimentalTaskFichePeseesPhytoEdit',
        meta: {
          title: 'Produit phytosanitaire',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/FichePeseesPhytoSubview.vue'
        ),
      },
      { // Traitement phyto et ferti
        path: 'fiche-pesees/semis',
        name: 'essaiExperimentalTaskFichePeseesSemisEdit',
        meta: {
          title: 'Semis',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/FichePeseesSemisSubview.vue'
        ),
      },
      { // Fiche pesées SEMIS
        path: 'fiche-pesees/fertilisant',
        name: 'essaiExperimentalTaskFichePeseesFertiEdit',
        meta: {
          title: 'Fertilisant',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/FichePeseesFertiSubview.vue'
        ),
      },
      { // Traitement phyto et ferti
        path: 'conditions-traitement',
        name: 'essaiExperimentalTaskConditionsTraitementEdit',
        meta: {
          title: 'Conditions de traitement',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/ConditionsTraitementSubview.vue'
        ),
      },
      { // Traitement phyto et ferti
        path: 'volumes-non-epandus',
        name: 'essaiExperimentalTaskVolumesNonEpandusEdit',
        meta: {
          title: 'Volumes non épandus',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/VolumesNonEpandusSubview.vue'
        ),
      },
      // Conditions récolte
      {
        path: 'conditions-recolte',
        name: 'essaiExperimentalTaskConditionsRecolteEdit',
        meta: {
          title: 'Conditions de récolte',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/ConditionsRecolteSubview.vue'
        ),
      },
      // Destruction en végétation
      {
        path: 'destruction-vegetation',
        name: 'essaiExperimentalTaskDestructionVegetationEdit',
        meta: {
          title: 'Destruction en végétation',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/DestructionSubview.vue'
        ),
      },
      // Destruction en récolte
      {
        path: 'destruction-recolte',
        name: 'essaiExperimentalTaskDestructionRecolteEdit',
        meta: {
          title: 'Destruction en récolte',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/DestructionSubview.vue'
        ),
      },
      // Preuves de destruction
      {
        path: 'preuves-destruction',
        name: 'essaiExperimentalTaskDestructionProofEdit',
        meta: {
          title: 'Preuves de destruction',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/DestructionProofSubview.vue'
        ),
      },
      // Notations
      {
        path: 'parametre',
        name: 'essaiExperimentalTaskParameterEdit',
        meta: {
          title: 'Paramètres',
          public: false,

        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/ParameterSubview.vue'
        ),
      },
      // variables etudiees
      {
        path: 'variables-etudiees',
        name: 'essaiExperimentalTaskVariableStudiedListEdit',
        meta: {
          title: 'Variables étudiées',
          public: false,
          permissions: 'essaievenementnotationvariable_olist',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/VariablesStudiedList.vue'
        ),

      },
      // variables etudiees
      {
        path: 'variable-etudiee/:veid?/definition/creer',
        name: 'essaiExperimentalTaskVariableStudiedSettingsAdd',
        meta: {
          title: 'Variable étudiée',
          public: false,
          permissions: 'essaievenementnotationvariable_oadd',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/VariableStudiedSubview.vue'
        ),
      },
      {
        path: 'variable-etudiee/:veid/definition/editer',
        name: 'essaiExperimentalTaskVariableStudiedSettingsEdit',
        meta: {
          title: 'Variable étudiée',
          public: false,
          permissions: 'essaievenementnotationvariable_oedit',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/VariableStudiedSubview.vue'
        ),
      },
      {
        path: 'variable-etudiee/:veid?/niveau-saisie/creer',
        name: 'essaiExperimentalTaskVariableStudiedEntryLevelAdd',
        meta: {
          title: 'Variable étudiée',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/VariableStudiedSubview.vue'
        ),
      },
      {
        path: 'variable-etudiee/:veid/niveau-saisie/editer',
        name: 'essaiExperimentalTaskVariableStudiedEntryLevelEdit',
        meta: {
          title: 'Variable étudiée',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/VariableStudiedSubview.vue'
        ),
      },
      {
        path: 'reservation',
        name: 'essaiExperimentalTaskNotationStateEdit',
        meta: {
          title: 'Réservation',
          public: false,
          permissions: 'essaievenementnotationreservation_olist',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/NotationStateSubview.vue'
        ),
      },
      // Notation - Variables calculées
      {
        path: 'variables-calculees',
        name: 'essaiExperimentalTaskVariableCalculatedEdit',
        meta: {
          title: 'Variables calculées',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/AddEditSubviews/VariableCalculatedSubview.vue'
        ),
      },
    ],
  },

  { // Consulter tâches expérimentales
    path: '/essai/:id/tache-experimentale/:tid',
    name: 'essaiExperimentalTask',
    meta: {
      title: 'Consulter une tâche expérimentale',
      permissions: 'essaievenement_oshow',
    },
    component: () => import(
      '@/views/essais/ExperimentalTask/ExperimentalTaskView.vue'
    ),
    redirect: { name: 'essaiExperimentalTaskGeneralInformation' },
    children: [
      { // Informations générales
        path: '',
        name: 'essaiExperimentalTaskGeneralInformation',
        meta: {
          title: 'Informations générales',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/GeneralInformationSubview.vue'
        ),
      },
      { // Organe
        path: 'organe',
        name: 'essaiExperimentalTaskOrgan',
        meta: {
          title: 'Organe',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/OrganSubview.vue'
        ),
      },
      { // Contamination artificielle
        path: 'contamination-artificielle',
        name: 'essaiExperimentalTaskArtificialContamination',
        meta: {
          title: 'Contamination artificielle',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/ArtificialContaminationSubview.vue'
        ),
      },
      { // Traitement phyto et ferti
        path: 'fiche-pesees/phytosantaire',
        name: 'essaiExperimentalTaskFichePeseesPhyto',
        meta: {
          title: 'Produit phytosanitaire',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/FichePeseesPhytoSubview.vue'
        ),
      },
      { // Fiche pesées SEMIS
        path: 'fiche-pesees/semis',
        name: 'essaiExperimentalTaskFichePeseesSemis',
        meta: {
          title: 'Semis',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/FichePeseesSemisSubview.vue'
        ),
      },
      { // Fiche pesées SEMIS
        path: 'fiche-pesees/fertilisant',
        name: 'essaiExperimentalTaskFichePeseesFerti',
        meta: {
          title: 'Fertilisant',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/FichePeseesFertiSubview.vue'
        ),
      },

      { // Traitement phyto et ferti
        path: 'conditions-traitement',
        name: 'essaiExperimentalTaskConditionsTraitement',
        meta: {
          title: 'Conditions de traitement',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/ConditionsTraitementSubview.vue'
        ),
      },
      { // Traitement phyto et ferti
        path: 'volumes-non-epandus',
        name: 'essaiExperimentalTaskVolumesNonEpandus',
        meta: {
          title: 'Volumes non épandus',
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/VolumesNonEpandusSubview.vue'
        ),
      },
      // Conditions récolte
      {
        path: 'conditions-recolte',
        name: 'essaiExperimentalTaskConditionsRecolte',
        meta: {
          title: 'Conditions de récolte',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/ConditionsRecolteSubview.vue'
        ),
      },
      // Destruction en végétation
      {
        path: 'destruction-vegetation',
        name: 'essaiExperimentalTaskDestructionVegetation',
        meta: {
          title: 'Destruction en végétation',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/DestructionSubview.vue'
        ),
      },
      // Destruction en récolte
      {
        path: 'destruction-recolte',
        name: 'essaiExperimentalTaskDestructionRecolte',
        meta: {
          title: 'Destruction en récolte',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/DestructionSubview.vue'
        ),
      },
      {
        path: 'preuves-destruction',
        name: 'essaiExperimentalTaskDestructionProof',
        meta: {
          title: 'Preuves de destruction',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/DestructionProofSubview.vue'
        ),
      },

      // Notations
      {
        path: 'parametre',
        name: 'essaiExperimentalTaskParameter',
        meta: {
          title: 'Paramètres',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/ParameterSubview.vue'
        ),
      },
      // variables etudiees
      {
        path: 'variables-etudiees',
        name: 'essaiExperimentalTaskVariableStudiedList',
        meta: {
          title: 'Variables étudiées',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/VariablesStudiedList.vue'
        ),
      },
      {
        path: 'variable-etudiee/:veid/definition',
        name: 'essaiExperimentalTaskVariableStudiedSettings',
        meta: {
          title: 'Variable étudiée',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/VariableStudiedSubview.vue'
        ),
      },
      {
        path: 'variable-etudiee/:veid/niveau-saisie',
        name: 'essaiExperimentalTaskVariableStudiedEntryLevel',
        meta: {
          title: 'Variable étudiée',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/VariableStudiedSubview.vue'
        ),
      },
      {
        path: 'reservation',
        name: 'essaiExperimentalTaskNotationState',
        meta: {
          title: 'Réservation',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/NotationStateSubview.vue'
        ),
      },
      // variables etudiees
      {
        path: 'variables-calculees',
        name: 'essaiExperimentalTaskVariableCalculated',
        meta: {
          title: 'Variables calculées',
          public: false,
        },
        component: () => import(
          '@/views/essais/ExperimentalTask/SingleSubviews/VariableCalculatedSubview.vue'
        ),
      },
    ],
  },
]
