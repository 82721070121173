<template>
  <Btn class="btn-toggle-menu-panel" grow @click="emitEventToggleMainMenu">
    <div class="hamburger-wrapper">
      <div class="hamburger"></div>
    </div>
  </Btn>
</template>

<script>
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'Hamburger',

  components: {
    Btn,
  },

  methods: {
    // EVENT EMITTING
    emitEventToggleMainMenu() {
      this.emitter.emit('toggle-menu-panel')
    },
  },
}
</script>

<style lang="scss" scoped>
/* HAMBURGER */

// HAMBURGER BUTTON

.btn-toggle-menu-panel {
  padding: ((($touch-target-size-base - $hamburger-size) / 2) - 0.1rem);
  border: 1px solid;
}

// HAMBURGER ICON

.hamburger-wrapper {
  height: $hamburger-size;
  width: $hamburger-size;
  text-align: center;
  position: relative;

  @include hocus() {
    .hamburger,
    .hamburger:before,
    .hamburger:after {
      background-color: currentColor;
    }
  }
}

.hamburger,
.hamburger:before,
.hamburger:after {
  display: block;
  height: $hamburger-size / 8;
  width: 100%;
  background-color: currentColor;
  transform: rotate(0deg);
  transition: transform 0.75s $ease-bump, background-color 0.25s;
}

.hamburger:before,
.hamburger:after {
  content: "";
  position: absolute;
}

.hamburger {
  position: relative;
  top: ($hamburger-size / 2 - $hamburger-size / 16);

  &:before {
    top: ($hamburger-size * 3 / 8);
  }
  &:after {
    top: (-$hamburger-size * 3 / 8);
  }
}

// Morph to close "X" icon when active

/*.app-wrapper*/
.is-panel-menu-open {
  .hamburger-wrapper {
    @include hocus() {
      .hamburger {
        background-color: transparent;
      }
      .hamburger:before,
      .hamburger:after {
        background-color: currentColor;
      }
    }
  }

  .hamburger {
    background-color: rgba(255, 255, 255, 0);
    transform: rotate(-90deg);

    &:after {
      transform: translateY($hamburger-size * 3 / 8) rotate(-135deg);
    }

    &:before {
      transform: translateY(-$hamburger-size * 3 / 8) rotate(-45deg);
    }
  }
}
</style>
