import { useForm, useFormValues } from 'vee-validate'

const FormService = class {
  initFrom(validationSchema) {
    // Create a form context with the validation schema
    const {
      errors, values, handleSubmit, isSubmitting,
      setValues, setFieldValue, setFieldError, resetForm, validate,
    } = useForm({
      validationSchema,
    })

    this.errors = errors
    this.isSubmitting = isSubmitting
    this.validationSchema = validationSchema
    this.handleSubmit = handleSubmit
    this.setValues = setValues
    this.setFieldError = setFieldError
    this.setFieldValue = setFieldValue
    this.resetForm = resetForm
    this.validate = validate
    this.values = values
    this.currentFormValues = useFormValues()
    return this
  }

  setFormValues(values) {
    this.setValues(values)
  }

  setEmitter(emitter) {
    this.emitter = emitter
  }

  handleErrors(isSubmitting, message = '') {
    if (Object.values(this.errors.value).flat().length && isSubmitting) {
      console.log('[formService error]', this.errors.value)
      this.emitter.emit('alert', {
        type: 'error',
        content: `Il y a ${Object.values(this.errors.value).flat().length
        } erreur(s)<br/>${message}`,
      })
    }
  }

  handleApiError(response, message = '') {
    let fields

    if (response.data && 'fields' in response.data) {
      fields = response.data.fields
    }
    const error = response?.data?.error

    let errorMessage = ''
    if (error !== undefined) {
      errorMessage = error
    }
    if (message) {
      errorMessage = message
    }

    if (fields) {
      Object.entries(fields).forEach(([key]) => {
        this.setFieldError(key, fields[key].toString())
      })
      this.handleErrors(true, errorMessage)
    } else {
      this.emitter.emit('alert', {
        type: 'error',
        content: `Une erreur est survenue.<br/>${errorMessage}`,
      })
    }
  }

  populateShema(data) {
    const schema = {}
    if (this.validationSchema) {
      Object.entries(this.validationSchema.fields).forEach(([key]) => {
        schema[key] = data[key]
      })
    }
    return schema
  }

  setValidationSchema(validationSchema) {
    this.validationSchema = validationSchema
  }
}

export default FormService
