/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const initialState = {
  modalities: [],
  entities: [],
}

// initial state
const state = () => (initialState)

// actions
const actions = {
  selectModalities({ commit }, data) {
    commit('updateModalitiesSelection', data)
  },
  selectEntities({ commit }, data) {
    commit('updateEntitiesSelection', data)
  },
  removeModality({ commit }, data) {
    commit('removeModalityItem', data)
  },
  removeEntity({ commit }, data) {
    commit('removeEntityItem', data)
  },
  clear({ commit }) {
    commit('clearSelection')
  },
}

// mutations
const mutations = {
  clearSelection(state) {
    state.modalities = []
    state.entities = []
  },
  updateModalitiesSelection(state, data) {
    state.modalities = data
  },
  updateEntitiesSelection(state, data) {
    state.entities = data
  },
  removeModalityItem(state, data) {
    state.modalities.splice(state.modalities.indexOf(data.item), 1)
  },
  removeEntityItem(state, data) {
    state.entities.splice(state.entities.indexOf(data.item), 1)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
