/*
eslint-disable
*/

class HelperService {
  constructor() {
    this.emitter = ''
    this.store = {}
  }

  setEmitter(emitter) {
    this.emitter = emitter
  }

  setStore(store) {
    this.store = store
  }

  setFetchService(fetchService) {
    this.fetchService = fetchService
  }

  currentEnv(env) {
    return process.env.VUE_APP_MODE === env
  }

  /**
   * Generate and uid
   *
   * @param value
   * @returns {string}
   */

  generateUid(value) {
    let slug = ''
    // Change to lower case
    const valueLower = value.toLowerCase()
    // Letter "e"
    slug = valueLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
    // Letter "a"
    slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
    // Letter "o"
    slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
    // Letter "u"
    slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
    // Letter "d"
    slug = slug.replace(/đ/gi, 'd')
    // Trim the last whitespace
    slug = slug.replace(/\s*$/g, '')
    // Change whitespace to "-"
    slug = slug.replace(/\s+/g, '-')

    return slug
  }

  /**
   * Generate random string
   * @param length
   * @returns {string}
   */

  generateRandomString(length) {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let result = ''
    for (let i = 0; i < length; i += 1) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
    }
    return result
  }

  getCombinaisons(...string) {
    const results = []
    // Since you only want pairs, there's no reason
    // to iterate over the last element directly
    for (let i = 0; i < string.length - 1; i += 1) {
      // This is where you'll capture that last value
      for (let j = i + 1; j < string.length; j += 1) {
        results.push([string[i], string[j]])
      }
    }
    return results
  }

  /**
   * Check if the connected user has a profil or multiple profils
   * In the case of multiple profiles, the default validation condition is an "AND"
   *
   * @param  {string} profils Can be a comma separated list of uids profils,
   * @param  {string} method validation condition AND,OR
   * or a unique uid profil
   * @returns {boolean}
   */

  userHasProfil(profils, method = 'AND') {
    let res = false
    if (this.store.state.auth.user.data.permissions !== undefined) {
      const userProfilsUids = this.store.state.auth.user.data.profils.map((profil) => profil.uid)
      res = true
      const profilsArray = profils.split(',')

      if (method === 'OR') {
        const filteredArray = profilsArray.filter((value) => userProfilsUids.includes(value.replace(/\s/g, '')))
        if (filteredArray.length > 0) {
          return true
        }
      } else {
        profilsArray.forEach((profil) => {
          if (!res) {
            return
          }
          if (userProfilsUids.indexOf(profil.replace(/\s/g, '')) === -1) {
            res = false
          }
        })
      }
    }
    return res
  }

  /**
   * Check if the connected user has a permission or multiple permissions
   * In the case of multiple permissions, the default validation condition is an "AND"
   *
   * @param  {string} permissions Can be a comma separated list of uids permissions
   * @param  {string} method validation condition AND,OR
   * or a unique uid permission
   * @returns {boolean}
   */

  userHasPermission(permissions, method = 'AND') {
    let res = false
    if (this.store.state.auth.user.data.permissions !== undefined) {
      res = true
      const userPermissionssUids = this.store.state.auth.user.data.permissions.map(
        (perm) => perm.uid,
      )
      // eslint-disable-next-line
      let permissionsClean = permissions.replace(' ', '')
      const permissionsArray = permissionsClean.split(',')

      if (method === 'OR') {
        const filteredArray = permissionsArray.filter((value) => userPermissionssUids.includes(value.replace(/\s/g, '')))

        if (filteredArray.length > 0) {
          return true
        }
        return false
      }
      permissionsArray.forEach((perm) => {
        if (!res) {
          return
        }
        if (userPermissionssUids.indexOf(perm.replace(/\s/g, '')) === -1) {
          res = false
        }
      })
    }
    return res
  }

  userInitials(firstName, lastName) {
    return firstName ? firstName.charAt(0) + lastName.charAt(0) : ''
  }

  isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]'
  }

  cloneObject(obj) {
    const newObj = JSON.parse(JSON.stringify(obj))
    return newObj
  }

  sanitizeValues(obj) {
    if (Object.keys(obj).length) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || typeof obj[key] === 'undefined') {
          // eslint-disable-next-line no-param-reassign
          delete obj[key]
        }
      })
    }
    return obj
  }

  displayBoolean(value) {
    if (typeof value === 'boolean') {
      return (value) ? 'Oui' : 'Non'
    }
    return value
  }

  displayDate(value) {
    if (value) {
      const date = new Date(value)
      return date.toLocaleDateString('fr')
    }
    return '—'
  }

  displayTime(value) {
    if (value) {
      const date = new Date(value)
      return date.toLocaleTimeString('fr')
    }
    return '—'
  }

  formatDateForApi(value) {
    if (value) {
      const date = new Date(value)
      const iso = date.toISOString().match(/(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})/)

      return `${iso[1]} ${iso[2]}`
    }
    return null
  }

  formatDateForInput(value) {
    if (value) {
      return value?.split(' ').shift()
    }
    return null
  }

  degToDms(deg) {
    if (deg) {
      let d = Math.floor(deg)
      const minfloat = (deg - d) * 60
      let m = Math.floor(minfloat)
      const secfloat = (minfloat - m) * 60
      let s = secfloat.toFixed(1)
      if (s === 60) {
        m += 1
        s = 0
      }
      if (m === 60) {
        d += 1
        m = 0
      }
      return `${d}°${m}'${s}`
    }
    return '—'
  }

  getReferentials() {
    if (localStorage.getItem('referentials')) {
      return JSON.parse(localStorage.getItem('referentials'))
    }
    return null
  }

  getReferentialByUid(uid) {
    if (localStorage.getItem('referentials')) {
      const referentials = JSON.parse(localStorage.getItem('referentials'))
      return referentials[uid]
    }
    return null
  }

  sortByOrder(element) {
    return element.sort((a, b) => a.ordre - b.ordre)
  }

  round(number, precision) {
    const facteur = 10 ** precision
    const temp = number * facteur
    return Math.round(temp) / facteur
  }

  arrayDiff(newArray, oldArray, key = 'id') {
    const updated = newArray.filter((x) => oldArray.find((item) => item[key] === x[key]))
    const deleted = oldArray.filter((x) => !newArray.find((item) => item[key] === x[key]))
    const added = newArray.filter((x) => !oldArray.find((item) => item[key] === x[key]))

    return {
      added,
      updated,
      deleted
    }
  }

  loadReferentialsIds() {
    return new Promise((success, reject) => {
      this.fetchService
        .get('dictionnaire/referentiel', {
          limit: 0,
        })
        .then((response) => {
          const referentials = {}
          const { data } = response
          data.forEach((element) => {
            referentials[element.uid] = element.id
          })

          localStorage.setItem('referentials', JSON.stringify(referentials))


          success({ loaded: true })
        }).catch((error) => {
          reject(error)
        })
    })
  }

  truncateText(text, start = 50, end = 20) {
    if (text && text.length > start + end) {
      return text.substring(0, start) + '...' + text.substring(text.length - end)
    }
    return text
  }
}

export default new HelperService()
