/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const initialState = {
  plans: [],
}

// initial state
const state = () => (initialState)

// getter
const getters = {
  isDirty(state, planId) {
    const index = state.plans.findIndex((plan) => plan.id === planId)
    return state.plans[index]
  },
}

// actions
const actions = {
  setPlan({ commit }, data) {
    const plan = {
      id: data.id,
      nb_repetitions: data.nb_repetitions,
      availableModa: [],
      availableRep: [],
      repetitions: [],
      dirty: false,
    }

    for (let i = 0; i < data.nb_repetitions; i += 1) {
      plan.repetitions[i] = []
    }

    commit('updatePlan', plan)
  },
  addModa({ commit }, moda) {
    commit('addModa', moda)
  },
  removeModa({ commit }, moda) {
    commit('removeModa', moda)
  },
  setDirty({ commit }, planId) {
    commit('updateDirty', planId)
  },
}

// mutations
const mutations = {
  updatePlan(state, data) {
    const index = state.plans.findIndex((plan) => plan.id === data.id)
    if (index === -1) {
      state.plans.push(data)
    }
  },
  updateDirty(state, planId) {
    const index = state.plans.findIndex((plan) => plan.id === planId)
    state.plans[index].dirty = true
  },
  addModa(state, moda) {
    const index = state.plans.findIndex((plan) => plan.id === moda.plan_id)
    state.plans[index].availableModa.push(moda)
    state.plans[index].availableRep.push(moda.repetition)

    for (let i = 0; i < state.plans[index].nb_repetitions; i += 1) {
      if (moda.repetition === (i + 1)) {
        state.plans[index].repetitions[i].push(moda)
      }
    }
  },
  removeModa(state, moda) {
    const index = state.plans.findIndex((plan) => plan.id === moda.plan_id)

    const modaIndex = state.plans[index].availableModa.findIndex((item) => item.modalite.id === moda.modalite.id)
    state.plans[index].availableModa.splice(modaIndex, 1)

    const repIndex = state.plans[index].availableRep.findIndex((item) => item === moda.repetition)
    state.plans[index].availableRep.splice(repIndex, 1)

    const modaByRepIndex = state.plans[index].repetitions[moda.repetition - 1].findIndex((item) => item.modalite.id === moda.modalite.id)
    state.plans[index].repetitions[moda.repetition - 1].splice(modaByRepIndex, 1)
  },
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
}
