<template>
  <nav class="navbar-menu navbar-menu--dropdown">
    <ul class="menu">
      <MenuItem
        v-for="itemData in menuData"
        :key="itemData.label"
        :item-data="itemData"
      />
    </ul>
  </nav>
</template>

<script>
import MenuItem from '@/components/layout/MenuItem.vue'

export default {
  name: 'MenuItemDropdown',

  components: {
    MenuItem,
  },

  props: {
    menuData: Array,
  },
}
</script>

<style lang="scss" scoped>
/* MENU ITEM DROPDOWN */

.navbar-menu--dropdown {
  position: fixed;
  width: $sidebar-width-mobile;
  top: $header-height-mobile;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  transform: translateX(-$sidebar-width-mobile);
  background-color: white;
  @include shadow(1);
  z-index: +1;
  transition: all 0.25s $ease-in-out;

  .menu-item--dropdown-open > & {
    transform: translateX(0);
  }

  @include bp($breakpoint-sidebar-compact) {
    top: $header-height-tablet;
  }

  @include bp($breakpoint-sidebar-full) {
    position: absolute;
    height: auto;
    max-height: 100%;
    width: $sidebar-width-desktop;
    bottom: auto;
    top: 0;
    left: $sidebar-width-desktop;
    transform: translateX(-100%);
    z-index: -1;
    opacity: 0;

    .menu-item--dropdown-open > & {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
</style>
