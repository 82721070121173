export default [
  /** **************************************
   * Role profiles
   ************************************** */
  { // Listing
    path: '/admin/profils-de-droit',
    name: 'roleProfiles',
    meta: {
      public: false,
      title: 'Liste des profils de droit',
      permissions: 'profil_add,profil_edit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/RoleProfile/RoleProfilesView.vue'
    ),
  },
  { // Create & update
    path: '/admin/profils-de-droit/formulaire',
    name: 'roleProfileAddEdit',
    meta: {
      public: false,
      title: 'Ajouter un profil de droit',
      permissions: 'profil_add,profil_edit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/RoleProfile/RoleProfileAddEditView.vue'
    ),
    children: [
      {
        path: '/admin/profils-de-droit/formulaire/:categoryId/:id?',
        name: 'roleProfileListing',
        meta: {
          title: 'Affichage des permissions d\'une catégorie de droit',
          public: true,
          permissions: 'profil_add,profil_edit',
        },
        component: () => import(
          /* webpackChunkName: "admin" */
          '@/views/admin/RoleProfile/RoleProfileListingSubview.vue'
        ),
      },
    ],
  },
  { // Read
    path: '/admin/profil-de-droit/:id',
    name: 'roleProfile',
    meta: {
      public: false,
      title: 'Profil de droit',
      permissions: 'profil_add,profil_edit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/RoleProfile/RoleProfileView.vue'
    ),
  },

  /** **************************************
   * Referential
   ************************************** */
  { // liste des reférenciel et autres valeurs editable
    path: '/admin/referentiels',
    name: 'references',
    meta: {
      public: false,
      title: 'Liste des référentiels',
      permissions: 'variable_add,variable_edit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/ReferencesView.vue'
    ),
    redirect: { name: 'referentialConditionsTraitement' },
    children: [
      { // Fiche conditions de traitement
        path: 'conditions-traitement',
        name: 'referentialConditionsTraitement',
        meta: {
          title: 'Fiche conditions de traitement',
          permissions: 'valeur_oadd,valeur_oedit',
        },
        component: () => import(
          /* webpackChunkName: "admin" */
          '@/views/admin/Referential/ReferencesView.vue'
        ),
      },
      { // Fiches parcellaires
        path: 'parcellaire',
        name: 'referentialParcels',
        meta: {
          title: 'Fiche parcellaires',
          permissions: 'valeur_oadd,valeur_oedit',
        },
        component: () => import(
          /* webpackChunkName: "admin" */
          '@/views/admin/Referential/ReferencesView.vue'
        ),
      },
      { // Fiches parcellaires
        path: 'essais',
        name: 'referentialEssais',
        meta: {
          title: 'Fiche essais',
          permissions: 'valeur_oadd,valeur_oedit',
        },
        component: () => import(
          /* webpackChunkName: "admin" */
          '@/views/admin/Referential/ReferencesView.vue'
        ),
      },
      { // Produits
        path: 'produits',
        name: 'referentialProducts',
        meta: {
          title: 'Produits',
          permissions: 'valeur_oadd,valeur_oedit',
        },
        component: () => import(
          /* webpackChunkName: "admin" */
          '@/views/admin/Referential/ReferencesView.vue'
        ),
      },
      { // Protocoles
        path: 'protocole',
        name: 'referentialProtocols',
        meta: {
          title: 'Protocoles',
          permissions: 'valeur_oadd,valeur_oedit',
        },
        component: () => import(
          /* webpackChunkName: "admin" */
          '@/views/admin/Referential/ReferencesView.vue'
        ),
      },
      { // Divers
        path: 'pesee',
        name: 'referentialPesee',
        meta: {
          title: 'Fiche pesées',
          permissions: 'valeur_oadd,valeur_oedit',
        },
        component: () => import(
          /* webpackChunkName: "admin" */
          '@/views/admin/Referential/ReferencesView.vue'
        ),
      },
      { // Divers
        path: 'sous-themes',
        name: 'referentialSubThemes',
        meta: {
          title: 'Sous-thèmes',
          permissions: 'valeur_oadd,valeur_oedit',
        },
        component: () => import(
          /* webpackChunkName: "admin" */
          '@/views/admin/Referential/ReferencesView.vue'
        ),
      },
      { // Divers
        path: 'divers',
        name: 'referentialDivers',
        meta: {
          title: 'Divers',
          permissions: 'valeur_oadd,valeur_oedit',
        },
        component: () => import(
          /* webpackChunkName: "admin" */
          '@/views/admin/Referential/ReferencesView.vue'
        ),
      },
    ],
  },
  { // liste des valeurs
    path: '/admin/referentiel/type/:type?/:id?',
    name: 'referential',
    meta: {
      public: false,
      title: 'Référentiel',
      permissions: 'valeur_oadd,valeur_oedit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/ReferentialView.vue'
    ),
  },
  { // liste des valeurs
    path: '/admin/referentiel/:type?/:id?/sous-thematiques',
    name: 'thematique',
    meta: {
      public: false,
      title: 'Sous-thèmes',
      permissions: 'valeur_oadd,valeur_oedit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/ReferentialView.vue'
    ),
  },
  { // Créer un référentiel
    path: '/admin/referentiel/type/:type?/:id?/creer',
    name: 'referentialAdd',
    meta: {
      public: false,
      title: 'Ajouter un élément dans un référentiel',
      permissions: 'valeur_oadd,valeur_oedit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/ReferentialItemAddEditView.vue'
    ),
  },
  { // Editer un élément dans un référentiel
    path: '/admin/referentiel/type/:type?/:id?/editer/:id_value?',
    name: 'referentialEdit',
    meta: {
      public: false,
      title: 'Modifier un élément dans un référentiel',
      permissions: 'valeur_oadd,valeur_oedit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/ReferentialItemAddEditView.vue'
    ),
  },

  /**
   * Vues customs dans les référentiels
   */

  // Culture
  { // Créer une culture
    path: '/admin/referentiel/type/:type?/:id?/creer',
    name: 'cultureAdd',
    meta: {
      public: false,
      title: 'Ajouter une culture',
      permissions: 'valeur_oadd,valeur_oedit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/CultureItemAddEditView.vue'
    ),
  },
  { // Ajouter un élément dans une culture
    path: '/admin/referentiel/type/:type?/:id?/editer/:id_value?',
    name: 'cultureEdit',
    meta: {
      public: false,
      title: 'Modifier une culture',
      permissions: 'valeur_oadd,valeur_oedit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/CultureItemAddEditView.vue'
    ),
  },
  { // Créer ou éditer une famille de culture
    path: '/admin/referentiel/type/:type?/:id?/creer',
    name: 'cultureFamilyAdd',
    meta: {
      public: false,
      title: 'Ajouter une famille de cultures',
      permissions: 'valeur_oadd,valeur_oedit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/CultureFamilyItemAddEditView.vue'
    ),
  },
  { // Editer une famille de culture
    path: '/admin/referentiel/type/:type?/:id?/editer/:id_value?',
    name: 'cultureFamilyEdit',
    meta: {
      public: false,
      title: 'Modifier une famille de cultures',
      permissions: 'valeur_oadd,valeur_oedit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/CultureFamilyItemAddEditView.vue'
    ),
  },
  { // Créer ou éditer une famille de produit
    path: '/admin/referentiel/type/:type?/:id?/creer',
    name: 'productFamilyAdd',
    meta: {
      public: false,
      title: 'Ajouter une famille de produits',
      permissions: 'valeur_oadd,valeur_oedit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/ProductFamilyItemAddEditView.vue'
    ),
  },
  { // Editer une famille de produits
    path: '/admin/referentiel/type/:type?/:id?/editer/:id_value?',
    name: 'productFamilyEdit',
    meta: {
      public: false,
      title: 'Modifier une famille de produits',
      permissions: 'valeur_oadd,valeur_oedit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/ProductFamilyItemAddEditView.vue'
    ),
  },

  // Composant
  { // Créer un composant
    path: '/admin/referentiel/type/:type?/creer',
    name: 'composantAdd',
    meta: {
      public: false,
      title: 'Ajouter un composant',
      permissions: 'valeur_oadd,valeur_oedit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/ComposantAddEditView.vue'
    ),
  },
  { // Éditer un composant
    path: '/admin/referentiel/type/:type?/:id?/editer',
    name: 'composantEdit',
    meta: {
      public: false,
      title: 'Modifier un composant',
      permissions: 'valeur_oadd,valeur_oedit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/ComposantAddEditView.vue'
    ),
  },

  // Pictogrammes
  { // Créer un pictogramme
    path: '/admin/referentiel/type/:type/creer',
    name: 'pictogramAdd',
    meta: {
      public: false,
      title: 'Ajouter un pictogramme',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/PictogramAddEditView.vue'
    ),
  },
  { // Éditer un composant
    path: '/admin/referentiel/type/:type/:id/editer',
    name: 'pictogramEdit',
    meta: {
      public: false,
      title: 'Modifier un pictogramme',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/PictogramAddEditView.vue'
    ),
  },

  // Sous-thematiques
  { // Créer une sous thematique
    path: '/admin/referentiel/type/:type/:id/sous-thematiques/creer',
    name: 'thematiqueAdd',
    meta: {
      public: false,
      title: 'Ajouter une sous-thématique',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/SousThematiqueAddEditView.vue'
    ),
  },
  { // Éditer un sous thematique
    path: '/admin/referentiel/type/:type?/:id?/sous-thematiques/:tid?/editer',
    name: 'thematiqueEdit',
    meta: {
      public: false,
      title: 'Modifier une sous-thématique',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/SousThematiqueAddEditView.vue'
    ),
  },

  // Catégories de fichier
  { // Créer une catégorie de fichier
    path: '/admin/categories-fichier/type/:type?/creer',
    name: 'fileCategoryAdd',
    meta: {
      public: false,
      title: 'Ajouter une catégorie de fichier',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/FileCategoryAddEditView.vue'
    ),
  },
  { // Éditer une catégorie de fichier
    path: '/admin/categories-fichier/type/:type?/:id?/editer',
    name: 'fileCategoryEdit',
    meta: {
      public: false,
      title: 'Modifier une catégorie de fichier',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/FileCategoryAddEditView.vue'
    ),
  },

  // Enfants des catégories de fichier
  { // Listing
    path: '/admin/categories-fichier/type/:type/:id/sous-categories',
    name: 'fileSubCategories',
    meta: {
      title: 'Sous-catégories de la catégorie de fichier',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/FileSubCategoriesView.vue'
    ),
  },
  {
    // Ajouter
    path: '/admin/categories-fichier/type/:type/:id/sous-categories/creer',
    name: 'fileSubCategoryAdd',
    meta: {
      title: 'Ajouter une sous-catégorie à la catégorie de fichier',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/FileCategoryAddEditView.vue'
    ),
  },
  {
    // Editer
    path: '/admin/categories-fichier/type/:type/:id/sous-categories/:sId/edition',
    name: 'fileSubCategoryEdit',
    meta: {
      title: 'Modifier les sous-catégories de la catégorie de fichier',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/FileCategoryAddEditView.vue'
    ),
  },

  // Stade
  { // Créer un stade
    path: '/admin/referentiel/type/:type?/creer',
    name: 'stadeAdd',
    meta: {
      public: false,
      title: 'Ajouter un stade',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/StadeAddEditView.vue'
    ),
  },
  { // Éditer un composant
    path: '/admin/referentiel/type/:type?/:id?/editer',
    name: 'stadeEdit',
    meta: {
      public: false,
      title: 'Modifier un stade',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Referential/StadeAddEditView.vue'
    ),
  },

  /** **************************************
   * Entities
   ************************************** */
  { // Listing
    path: '/admin/entites',
    name: 'entities',
    meta: {
      public: false,
      title: 'Entités',
      permissions: 'entite_show,entite_add,entite_edit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Entity/EntitiesView.vue'
    ),
  },
  { // Create
    path: '/admin/entite/creation',
    name: 'entityAdd',
    meta: {
      public: false,
      title: 'Création d\'une entité',
      permissions: 'entite_add',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/entity/EntityAddEditView.vue'
    ),
  },
  { // Read
    path: '/admin/entite/:id',
    name: 'entity',
    meta: {
      public: false,
      title: 'Affichage d\'une entité',
      permissions: 'entite_show',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/entity/EntityView.vue'
    ),
  },
  { //
    path: '/admin/entite/:id/edition',
    name: 'entityEdit',
    meta: {
      public: false,
      title: 'Édition d\'une entité',
      permissions: 'entite_edit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/entity/EntityAddEditView.vue'
    ),
  },

  /** **************************************
   * Logs
   ************************************** */
  { // Listing
    path: '/admin/logs',
    name: 'logs',
    meta: {
      public: false,
      title: 'Liste des logs',
      permissions: 'log_list',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Log/LogsView.vue'
    ),
  },
  { // Listing
    path: '/admin/log/:id',
    name: 'log',
    meta: {
      public: false,
      title: 'Log',
      permissions: 'log_show',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Log/LogView.vue'
    ),
  },

  /** **************************************
   * Mails
   ************************************** */
  { // Listing
    path: '/admin/mails',
    name: 'mails',
    meta: {
      public: false,
      title: 'Liste des mails envoyés',
      permissions: 'utilitiesmail_list',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Mail/MailsView.vue'
    ),
  },
  { // Listing
    path: '/admin/mail/:id',
    name: 'mail',
    meta: {
      public: false,
      title: 'Mail',
      permissions: 'utilitiesmail_show',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Mail/MailView.vue'
    ),
  },

  /** **************************************
   * Étiquette
   ************************************** */

  // Modèles d'étiquettes
  {
    path: '/modeles-etiquette',
    name: 'modelsLabel',
    meta: {
      public: false,
      title: 'Modèles d\'étiquette',
      permissions: 'etiquettemodele_add,etiquettemodele_edit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/ModelsLabel/ModelsLabelView.vue'
    ),
  },
  {
    path: '/modele-etiquette/:id',
    name: 'modelLabel',
    meta: {
      public: false,
      title: 'Voir un modèle d\'étiquette',
      permissions: 'etiquettemodele_add,etiquettemodele_edit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/ModelsLabel/ModelLabelView.vue'
    ),
  },
  {
    path: '/modele-etiquette/creer',
    name: 'modelLabelAdd',
    meta: {
      public: false,
      title: 'Ajouter un modèle d\'étiquette',
      permissions: 'etiquettemodele_add',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/ModelsLabel/ModelsLabelAddEditView.vue'
    ),
  },
  {
    path: '/modele-etiquette/:id/editer',
    name: 'modelLabelEdit',
    meta: {
      public: false,
      title: 'Modifier un modèle d\'étiquette',
      permissions: 'etiquettemodele_edit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/ModelsLabel/ModelsLabelAddEditView.vue'
    ),
  },

  // Gabarit
  {
    path: '/gabarits-etiquette',
    name: 'templatesLabel',
    meta: {
      public: false,
      title: 'Gabarits d\'étiquettes',
      permissions: 'dictionariesetiquettegabarit_add,dictionariesetiquettegabarit_edit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/ModelsLabel/TemplatesLabelView.vue'
    ),
  },
  {
    path: '/gabarit-etiquette/:id',
    name: 'templateLabel',
    meta: {
      public: false,
      title: 'Voir un gabarit d\'étiquette',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/ModelsLabel/TemplateLabelView.vue'
    ),
  },
  {
    path: '/gabarit-etiquette/creer',
    name: 'templateLabelAdd',
    meta: {
      public: false,
      title: 'Ajouter un gabarit d\'étiquette',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/ModelsLabel/TemplatesLabelAddEditView.vue'
    ),
  },
  {
    path: '/gabarit-etiquette/:id/editer',
    name: 'templateLabelEdit',
    meta: {
      public: false,
      title: 'Éditer un gabarit d\'étiquette',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/ModelsLabel/TemplatesLabelAddEditView.vue'
    ),
  },

  // Type de valeurs d'étiquette
  {
    path: '/type-valeurs-etiquette',
    name: 'statutLabelValues',
    meta: {
      public: false,
      title: 'Type de valeurs d\'étiquette',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/ModelsLabel/StatutLabelValuesView.vue'
    ),
  },
  {
    path: '/type-valeur-etiquette/creer',
    name: 'statutLabelValueAdd',
    meta: {
      public: false,
      title: 'Ajouter un type de valeur d\'étiquette',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/ModelsLabel/StatutLabelValueAddEditView.vue'
    ),
  },
  {
    path: '/type-valeur-etiquette/:id/editer',
    name: 'statutLabelValueEdit',
    meta: {
      public: false,
      title: 'Éditer un type de valeur d\'étiquette',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/ModelsLabel/StatutLabelValueAddEditView.vue'
    ),
  },
  // VARIABLES PHASE E
  {
    path: '/admin/variables',
    name: 'variables',
    meta: {
      public: false,
      title: 'Liste des variables',
      permissions: 'variable_add,variable_edit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Variable/VariablesView.vue'
    ),
  },
  {
    path: '/admin/variable/:id',
    name: 'variable',
    meta: {
      public: false,
      title: 'Consulter une variable',
      permissions: 'variable_add,variable_edit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Variable/VariableView.vue'
    ),
  },
  {
    path: '/admin/variable/creer',
    name: 'variableAdd',
    meta: {
      public: false,
      title: 'Ajouter une variable',
      permissions: 'variable_add',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Variable/VariableAddEditView.vue'
    ),
  },
  {
    path: '/admin/variable/:id/editer',
    name: 'variableEdit',
    meta: {
      public: false,
      title: 'Modifier une variable',
      permissions: 'variable_edit',
    },
    component: () => import(
      /* webpackChunkName: "admin" */
      '@/views/admin/Variable/VariableAddEditView.vue'
    ),
  },
]
