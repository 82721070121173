<template>
  <div
    class="user-avatar"
    :class="userAvatarClass"
    :style="image ? `background-image: url('${avatar}');` : ''"
  >
    <span class="avatar-initials" v-if="!image">
      {{ initials }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'UserAvatar',

  props: {
    image: {
      type: [String, Object],
    },
    initials: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'normal',
    },
    color: {
      type: [String, Number],
      default: '18',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      avatar: '',
      loading: false,
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    '$props.image': function () {
      this.getAvatar()
    },
  },

  computed: {
    userAvatarClass() {
      return {
        [`bg-material-color-${this.color}`]: true,
        'user-avatar--small': this.size === 'small',
        'user-avatar--large': this.size === 'large',
        'user-avatar--disabled': this.isDisabled,
      }
    },
  },

  async mounted() {
    if (this.image) {
      this.getAvatar()
    }
  },

  methods: {
    getAvatar() {
      this.loading = true

      if (this.image) {
        this.fileService.displayImage(this.image.jeton).then((response) => {
          this.avatar = response.data
        })
      }

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
/* USER AVATAR */

$user-avatar-size: 8rem;
$user-avatar-size-small: 4rem;
$user-avatar-size-large: 18rem;

.user-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  @include size($user-avatar-size);
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;

  .topbar-menu & {
    box-shadow: inset 0 0 0 1px currentColor;
  }
}

.user-avatar--small {
  @include size($user-avatar-size-small);
}

.user-avatar--large {
  @include size($user-avatar-size-large);
}

.user-avatar--disabled {
  opacity: 0.5;
  filter: saturate(0);
}

// AVATAR INITIALS

.avatar-initials {
  font-size: $font-size-base * 2;
  text-transform: uppercase;
  color: white;
  font-weight: $font-weight-light;

  .user-avatar--small & {
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
  }

  .user-avatar--large & {
    font-size: $font-size-base * 4;
    font-weight: $font-weight-lighter;
  }
}
</style>
