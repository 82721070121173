/* eslint-disable */
import * as yup from 'yup'

yup.setLocale({
  mixed: {
    required: 'Le champ est obligatoire.',
    notType: 'Le champ est invalide.'
  },
  string:{
    email: 'Le champ doit être un email valide.',
    required: 'Le champ est obligatoire.',
    min: 'Le champ doit être avoir au moins ${min} caractères.',
  },
  array: {
    min: 'Le champ doit avoir au moins ${min} élément.',
  },
  date: {
    max: 'Le champ doit être inférieur à ${max}.'
  },
  number: {
    default:'Le champ doit etre numérique',
    required: 'Le champ est obligatoire',
    min: 'Le champ doit être supérieure ou égale à ${min}',
    max: 'Le champ doit être inférieur ou égal à ${max}',
    lessThan: 'Le champ doit être inférieure à ${less}',
    moreThan: 'Le champ doit être supérieure à ${more}',
    positive: 'Le champ doit être un nombre positif',
    negative: 'Le champ doit être un nombre négatif',
    integer: 'Le champ doit être un entier',
  }
})

export default yup
