<template>
  <div
    class="user-name"
    :class="{
      'user-name--small': size === 'small',
      'user-name--block': layout === 'block',
      'user-name--reverse': order === 'reverse',
      'user-name--disabled': isDisabled,
    }"
  >
    <div class="user-first-name">{{ firstName }}</div>
    <div class="user-last-name">{{ lastName }}</div>
  </div>
</template>

<script>
export default {
  name: 'UserName',

  props: {
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'normal',
    },
    layout: {
      type: String,
      default: 'inline',
    },
    order: {
      type: String,
      default: 'normal',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
/* USER NAME */

.user-name {
  font-weight: $font-weight-semibold;
  text-align: left; // In buttons
  display: flex;
  flex-wrap: wrap;
  font-size: $font-size-big;
  line-height: $line-height-small;
  transition: all 0.25s;
}

.user-name--small {
  font-size: $font-size-base;
}

.user-name--block {
  flex-wrap: wrap;

  > * {
    flex-basis: 100%;
  }
}

.user-name--reverse {}

.user-name--disabled {
  opacity: 0.5;
  text-decoration: line-through;
}

// USER FIRST AND LAST NAME

.user-first-name {
  font-weight: $font-weight-normal;
  margin-right: $gutter-eighth;

  .user-name--reverse & {
    order: +100;
  }

  .user-name--reverse:not(.user-name--block) & {
    margin-right: 0;
    margin-left: $gutter-eighth;
  }
}

.user-last-name {
  .user-name--reverse & {
    &::after {
      content: ",";
    }
  }
}

</style>
