/*
eslint-disable
*/
import fetchService from './fetch.service'
import helperService from './helper.service'

class GroupingService {
  constructor() {
    this.emitter = ''
    this.store = {}
    this.routeur = {}
  }

  setEmitter(emitter) {
    this.emitter = emitter
  }

  setStore(store) {
    this.store = store
  }

  setRouteur(routeur) {
    this.routeur = routeur
  }

  async setFiltersItems() {
    const items = {};
  
    // Année
    const yearResponse = await fetchService.get(`dictionnaire/referentiel/${helperService.getReferentialByUid('anneerecolte')}/valeur`, {
      limit: 0,
      sort: 'valeur.ASC',
    });
    items['in%protocole%annee%id'] = await yearResponse.data;
  
    // Coopérative
    const cooperativeResponse = await fetchService.get(`entite`, {
      limit: 0,
      sort: 'nom.ASC',
      filters: 'type.uid:eq(COOPERATIVE)',
    });
    items['in%utilisateur%entite%id'] = cooperativeResponse.data.map(d => ({ label: d.nom, value: d }));
  
    // Départements
    items['ilk%parcelle%cp'] = this.store.state.app.departements.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
  
    // Fournisseurs
    const fournisseurResponse = await fetchService.get(`fournisseur`, {
      limit: 0,
      sort: 'nom.ASC',
    });
    items['in%protocole%fournisseur%id'] = await fournisseurResponse.data;
  
    // Stress
    const stressResponse = await fetchService.get(`dictionnaire/referentiel/${helperService.getReferentialByUid('stress')}/valeur`, {
      limit: 0,
      sort: 'valeur.ASC',
    });
    const stressTempIdItems = [];
    const stressPluvioIdItems = [];
    stressResponse.data.filter(d => d.uid !== 'normale').forEach(d => {
      const value = { id: d.id };
      const isExcedentaire = d.uid === 'excedentaire';
      const order = isExcedentaire ? '2' : '1';
  
      stressTempIdItems.push({ value: { ...value, moment: 'pre', order }, label: `Préfloraison : ${isExcedentaire ? 'excédentaire' : 'déficitaire'}` });
      stressTempIdItems.push({ value: { ...value, moment: 'during', order: +order + 1 }, label: `Floraison : ${isExcedentaire ? 'excédentaire' : 'déficitaire'}` });
      stressTempIdItems.push({ value: { ...value, moment: 'post', order: +order + 3 }, label: `Postfloraison : ${isExcedentaire ? 'excédentaire' : 'déficitaire'}` });
  
      stressPluvioIdItems.push({ value: { ...value, moment: 'pre', order }, label: `Préfloraison : ${isExcedentaire ? 'excédentaire' : 'déficitaire'}` });
      stressPluvioIdItems.push({ value: { ...value, moment: 'during', order: +order + 1 }, label: `Floraison : ${isExcedentaire ? 'excédentaire' : 'déficitaire'}` });
      stressPluvioIdItems.push({ value: { ...value, moment: 'post', order: +order + 3 }, label: `Postfloraison : ${isExcedentaire ? 'excédentaire' : 'déficitaire'}` });
    });
  
    const sortItems = (items) => items.sort((a, b) => a.value.order - b.value.order);
    items['in%fiche%stress%temp%id'] = sortItems(stressTempIdItems);
    items['in%fiche%stress%pluvio%id'] = sortItems(stressPluvioIdItems);
  
    // Type de sol
    const typeSolResponse = await fetchService.get(`dictionnaire/referentiel/${helperService.getReferentialByUid('typesol')}/valeur`, {
      limit: 0,
      sort: 'valeur.ASC',
    });
    items['in%parcelle%sol%type%id'] = await typeSolResponse.data;
  
    // Culture
    const cultureResponse = await fetchService.get(`culture`, {
      limit: 0,
      sort: 'nom.ASC',
    });
    items['in%parcelle%culture%id'] = await cultureResponse.data;
  
    // Travail du sol
    const travailSolResponse = await fetchService.get(`dictionnaire/referentiel/${helperService.getReferentialByUid('travaildusol')}/valeur`, {
      limit: 0,
      sort: 'valeur.ASC',
    });
    items['in%parcelle%travail%id'] = await travailSolResponse.data;

    // Unités
    const unitesResponse = await fetchService.get(`unite`, {
      limit: 0,
      sort: 'valeur.ASC',
    });
    items['in%parcelle%unites%id'] = await unitesResponse.data;
  
    return items;
  }
  

  encodeQueryParams(filters) {
    const query = [];
  
    Object.values(filters).forEach(filter => {
      const { type, parent, values, key } = filter;
  
      if (values || values !== '') {
        let chunkQuery = [];
        let chunkValues = [];
        let parents = [];
        let words = [];
        let value = null;
        let date = null;
  
        switch (type) {
          case 'gte':
            query.push(`${parent}:gte(${values})`);
            break;
          case 'lte':
            query.push(`${parent}:lte(${values})`);
            break;
          case 'date_gte':
            value = values.split('-');
            value[1] -= 1;
            date = new Date(value[0], value[1], 1).toLocaleDateString().split('/');
            const formattedDateGte = date.reverse().join('-');
            query.push(`${parent}:gte(${formattedDateGte})`);
            break;
          case 'date_lte':
            value = values.split('-');
            value[1] -= 1;
            date = new Date(value[0], value[1] + 1, 0).toLocaleDateString().split('/');
            const formattedDateLte = date.reverse().join('-');
            query.push(`${parent}:lte(${formattedDateLte})`);
            break;
          case 'bool':
            if (typeof values === 'string') {
              query.push(`${parent}:${values === 'true' ? 'ist' : 'isf'}()`);
            }
            break;
          case 'conditional_bool':
            chunkQuery = [];
            parents = parent.split('|');
            if (typeof values === 'string') {
              parents.forEach(p => {
                chunkQuery.push(`${p}:${values === 'true' ? 'nn' : 'n'}()`);
              });
              if (chunkQuery.length > 0) {
                query.push('[' + chunkQuery.join(`${values === 'true' ? '|u|' : '|n|'}`) + ']');
              }
            }
            break;
          case 'ilk':
            words = values.split(' ');
            words.forEach(w => {
              query.push(`${parent}:ilk(%${w}%)`);
            });
            break;
          case 'ilk_multiple':
            chunkValues = values.map(v => `${parent}:ilk(${v.key}%)`);
            if (chunkValues.length > 0) {
              query.push('[' + chunkValues.join('|u|') + ']');
            }
            break;
          case 'in_multiple':
            chunkQuery = [];
            parents = parent.split('|');
            parents.forEach(p => {
              chunkValues = Object.values(values).map(val => val.key || val[key]);
              if (chunkValues.length > 0) {
                chunkQuery.push(`${p}.${key}:in(${chunkValues.join(',')})`);
              }
            });
            if (chunkQuery.length > 0) {
              query.push('[' + chunkQuery.join('|u|') + ']');
            }
            break;
          case 'in_stress':
            chunkQuery = [];
            chunkValues = {
              pre: [],
              post: [],
              during: []
            };
            Object.values(values).forEach(val => {
              switch (val.moment) {
                case 'pre':
                  chunkValues.pre.push(val[key]);
                  break;
                case 'post':
                  chunkValues.post.push(val[key]);
                  break;
                default:
                  chunkValues.during.push(val[key]);
                  break;
              }
            });
            if (chunkValues.pre.length > 0) {
              chunkQuery.push(`${parent}_pre.${key}:in(${chunkValues.pre.join(',')})`);
            }
            if (chunkValues.post.length > 0) {
              chunkQuery.push(`${parent}_post.${key}:in(${chunkValues.post.join(',')})`);
            }
            if (chunkValues.during.length > 0) {
              chunkQuery.push(`${parent}.${key}:in(${chunkValues.during.join(',')})`);
            }
            if (chunkQuery.length > 0) {
              query.push('[' + chunkQuery.join('|u|') + ']');
            }
            break;
          case 'in':
          default:
            chunkValues = Object.values(values).map(val => val.key || val[key]);
            if (chunkValues.length > 0) {
              query.push(`${parent}.${key}:in(${chunkValues.join(',')})`);
            }
            break;
        }
      }
    });
  
    return query.join('|n|');
  }
}

export default new GroupingService()
