export default [
  {
    path: '/styleguide',
    name: 'styleguide',
    component: () => import(
      '@/views/styleguide/StyleguideView.vue'
    ),

    children: [
      {
        path: 'colors',
        name: 'colors',
        meta: {
          title: '<span>Styleguide</span> - Colors',
          public: true,
        },
        component: () => import(
          '@/views/styleguide/StyleguideColorsView.vue'
        ),
      },
      {
        path: 'typography',
        name: 'typography',
        meta: {
          title: '<span>Styleguide</span> - Typography',
          public: true,
        },
        component: () => import(
          '@/views/styleguide/StyleguideTypographyView.vue'
        ),
      },
      {
        path: 'icons',
        name: 'icons',
        meta: {
          title: '<span>Styleguide</span> - Icon',
          public: true,
        },
        component: () => import(
          '@/views/styleguide/StyleguideIconsView.vue'
        ),
      },
      {
        path: 'buttons',
        name: 'buttons',
        meta: {
          title: '<span>Styleguide</span> - Buttons',
          public: true,
        },
        component: () => import(
          '@/views/styleguide/StyleguideButtonsView.vue'
        ),
      },
      {
        path: 'layout',
        name: 'layout',
        meta: {
          title: '<span>Styleguide</span> - Layout',
          public: true,
        },
        component: () => import(
          '@/views/styleguide/StyleguideLayoutView.vue'
        ),
      },
      {
        path: 'forms',
        name: 'forms',
        meta: {
          title: '<span>Styleguide</span> - Forms',
          public: true,
        },
        component: () => import(
          '@/views/styleguide/StyleguideFormsView.vue'
        ),
      },
      {
        path: 'forms-advanced',
        name: 'forms-advanced',
        meta: {
          title: '<span>Styleguide</span> - Forms advanced',
          public: true,
        },
        component: () => import(
          '@/views/styleguide/StyleguideFormsAdvancedView.vue'
        ),
      },
      {
        path: 'lists',
        name: 'lists',
        meta: {
          title: '<span>Styleguide</span> - Lists',
          public: true,
        },
        component: () => import(
          '@/views/styleguide/StyleguideListsView.vue'
        ),
      },
      {
        path: 'tables',
        name: 'tables',
        meta: {
          title: '<span>Styleguide</span> - Tables',
          public: true,
          // noScroll: true,
        },
        component: () => import(
          '@/views/styleguide/StyleguideTablesView.vue'
        ),
      },
      {
        path: 'modatables',
        name: 'modatables',
        meta: {
          title: '<span>Styleguide</span> - Moda Tables',
          public: true,
          noScroll: true,
        },
        component: () => import(
          '@/views/styleguide/StyleguideModaTablesView.vue'
        ),
      },
      {
        path: 'misc',
        name: 'misc',
        meta: {
          title: '<span>Styleguide</span> - Misc',
          public: true,
        },
        component: () => import(
          '@/views/styleguide/StyleguideMiscView.vue'
        ),
      },
      {
        path: 'micro-parcels',
        name: 'micro-parcels',
        meta: {
          title: '<span>Styleguide</span> - Micro-parcels',
          public: true,
        },
        component: () => import(
          '@/views/styleguide/StyleguideMicroParcelsView.vue'
        ),
      },
      {
        path: 'search',
        name: 'search',
        meta: {
          title: '<span>Styleguide</span> - Search',
          public: true,
        },
        component: () => import(
          '@/views/styleguide/StyleguideSearchView.vue'
        ),
      },
      {
        path: 'fetchservice',
        name: 'fetchservice',
        meta: {
          title: '<span>Styleguide</span> - Fetch Service',
          public: true,
        },
        component: () => import(
          '@/views/styleguide/StyleguideFetchServiceView.vue'
        ),
      },
    ],
  },

]
