export default [
  // Protocoles
  { // Listing
    path: '/protocoles',
    name: 'protocols',
    meta: {
      title: 'Protocoles de mon entité',
      permissions: 'protocole_list',
    },
    component: () => import(
      '@/views/protocols/ProtocolsView.vue'
    ),
  },
  { // Listing partagé
    path: '/protocoles/partage',
    name: 'protocolsShare',
    meta: {
      title: 'Partagés avec mon entité',
      permissions: 'protocole_shared',
    },
    component: () => import(
      '@/views/protocols/ProtocolsSharedView.vue'
    ),
  },
  { // Consultation
    path: '/protocole/:id',
    name: 'protocol',
    meta: {
      title: 'Consulter un protocole',
      permissions: 'protocole_show',
    },
    component: () => import(
      '@/views/protocols/ProtocolView.vue'
    ),
    redirect: { name: 'protocolGeneralInformation' },
    children: [
      { // Informations générales
        path: '/protocole/:id',
        name: 'protocolGeneralInformation',
        meta: {
          title: 'Informations générales',
        },
        component: () => import(
          '@/views/protocols/SingleSubviews/GeneralInformationSubview.vue'
        ),
      },
      { // Implantation de l'essai
        path: 'implantation-essai',
        name: 'protocolExperimentImplementation',
        meta: {
          title: 'Implantation de l\'essai',
        },
        component: () => import(
          '@/views/protocols/SingleSubviews/ExperimentImplementationSubview.vue'
        ),
      },
      { // Facteurs étudies
        path: 'facteurs-etudies',
        name: 'protocolStudiedExperiment',
        meta: {
          title: 'Facteurs étudiés',
        },
        component: () => import(
          '@/views/protocols/SingleSubviews/StudiedExperimentSubview.vue'
        ),
      },
      { // Couverture de l'essai
        path: 'couverture-essai',
        name: 'protocolCoverageExperiment',
        meta: {
          title: 'Couverture sur l\'essai',
        },
        component: () => import(
          '@/views/protocols/SingleSubviews/CoverageExperimentSubview.vue'
        ),
      },
      { // Produits phytosanitaires
        path: 'produits-phytosanitaires',
        name: 'protocolPhytosanitaryProducts',
        meta: {
          title: 'Produits phytosanitaires',
        },
        component: () => import(
          '@/views/protocols/SingleSubviews/PhytosanitaryProductsSubview.vue'
        ),
      },

      { // Tests statistiques prévus
        path: 'tests-statistiques',
        name: 'protocolStatisticalTests',
        meta: {
          title: 'Tests statistiques prévus',
        },
        component: () => import(
          '@/views/protocols/SingleSubviews/StatisticalTestsSubview.vue'
        ),
      },

      { // Calendrier des notations
        path: 'calendrier-notations',
        name: 'protocolRatingCalendar',
        meta: {
          title: 'Calendrier des notations',
        },
        component: () => import(
          '@/views/protocols/SingleSubviews/RatingCalendarSubview.vue'
        ),
      },
      { // Versions et validations
        path: 'versions-validation',
        name: 'protocolVersionsAndValidation',
        meta: {
          title: 'Versions et validations',
          permissions: 'protocoleversionvalidation_oshow',
        },
        component: () => import(
          '@/views/protocols/SingleSubviews/VersionsAndValidationSubview.vue'
        ),
      },
    ],
  },
  { // Création
    path: '/protocole/creer/:id?',
    name: 'protocolAdd',
    meta: {
      title: 'Création d\'un protocole',
      permissions: 'protocole_add',
    },
    component: () => import(
      '@/views/protocols/ProtocolAddEditView.vue'
    ),
    redirect: { name: 'protocolGeneralInformationAdd' },
    children: [
      { // Informations générales
        path: 'informations-generales',
        name: 'protocolGeneralInformationAdd',
        meta: {
          title: 'Informations générales',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/GeneralInformationAddEditSubview.vue'
        ),
      },
      { // 2eme etape
        path: 'implantation-essai',
        name: 'protocolExperimentImplementationAdd',
        meta: {
          title: 'Implantation des essais',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/ExperimentImplementationAddEditSubview.vue'
        ),
      },
      { // 3eme etape
        path: 'facteurs-etudies',
        name: 'protocolStudiedExperimentAdd',
        meta: {
          title: 'Facteurs étudiés',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/StudiedExperimentAddEditSubview.vue'
        ),
      },
      { // 4eme etape
        path: 'couverture-essai',
        name: 'protocolCoverageExperimentAdd',
        meta: {
          title: 'Couverture sur l\'essai',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/CoverageExperimentAddEditSubview.vue'
        ),
      },
      { // 5eme étape
        path: 'produits-phytosanitaires',
        name: 'protocolPhytosanitaryProductsAdd',
        meta: {
          title: 'Produits phytosanitaires',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/PhytosanitaryProductsAddEditSubview.vue'
        ),
      },
      { // 6eme étape
        path: 'tests-statistiques',
        name: 'protocolStatisticalTestsAdd',
        meta: {
          title: 'Tests statistiques prévus',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/StatisticalTestsAddEditSubview.vue'
        ),
      },
      { // 7eme etape
        path: 'calendrier-notations',
        name: 'protocolRatingCalendarAdd',
        meta: {
          title: 'Calendrier des notations',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/RatingCalendarAddEditSubview.vue'
        ),
      },
      { // 8eme etape
        path: 'versions-validation',
        name: 'protocolVersionsAndValidationAdd',
        meta: {
          title: 'Versions et validations',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/VersionsAndValidationAddEditSubview.vue'
        ),
      },
    ],
  },

  { // Edition
    path: '/protocole/editer/:id',
    name: 'protocolEdit',
    meta: {
      title: 'Edition d\'un protocole',
      permissions: 'protocole_edit',
    },
    component: () => import(
      '@/views/protocols/ProtocolAddEditView.vue'
    ),
    redirect: { name: 'protocolGeneralInformationEdit' },
    children: [
      { // Informations générales
        path: 'informations-generales',
        name: 'protocolGeneralInformationEdit',
        meta: {
          title: 'Informations générales',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/GeneralInformationAddEditSubview.vue'
        ),
      },
      { // 2eme etape
        path: 'implantation-essai',
        name: 'protocolExperimentImplementationEdit',
        meta: {
          title: 'Implantation des essais',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/ExperimentImplementationAddEditSubview.vue'
        ),
      },
      { // 3eme etape
        path: 'facteurs-etudies',
        name: 'protocolStudiedExperimentEdit',
        meta: {
          title: 'Facteurs étudiés',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/StudiedExperimentAddEditSubview.vue'
        ),
      },
      { // 4eme etape
        path: 'couverture-essai',
        name: 'protocolCoverageExperimentEdit',
        meta: {
          title: 'Couverture sur l\'essai',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/CoverageExperimentAddEditSubview.vue'
        ),
      },
      { // 5eme étape
        path: 'produits-phytosanitaires',
        name: 'protocolPhytosanitaryProductsEdit',
        meta: {
          title: 'Produits phytosanitaires',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/PhytosanitaryProductsAddEditSubview.vue'
        ),
      },

      { // 6eme étape
        path: 'tests-statistiques',
        name: 'protocolStatisticalTestsEdit',
        meta: {
          title: 'Tests statistiques prévus',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/StatisticalTestsAddEditSubview.vue'
        ),
      },

      { // 7eme etape
        path: 'calendrier-notations',
        name: 'protocolRatingCalendarEdit',
        meta: {
          title: 'Calendrier des notations',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/RatingCalendarAddEditSubview.vue'
        ),
      },
      { // 8eme etape
        path: 'versions-validation',
        name: 'protocolVersionsAndValidationEdit',
        meta: {
          title: 'Versions et validations',
        },
        component: () => import(
          '@/views/protocols/AddEditSubviews/VersionsAndValidationAddEditSubview.vue'
        ),
      },

    ],
  },

  { // Listing des versions
    path: '/protocole/:id?/versions',
    name: 'protocolVersions',
    meta: {
      title: 'Versions d\'un protocole',
      permissions: 'protocoleversion_olist',
    },
    redirect: { name: 'protocolVersionCurrent' },
    component: () => import(
      '@/views/protocols/ProtocolVersionsView.vue'
    ),
  },
  { // Listing des entités avec lesquels le protocole est partagé
    path: '/protocole/:id?/partages',
    name: 'protocolShared',
    meta: {
      title: 'Partages d\'un protocole',
      permissions: 'protocole_children',
    },
    component: () => import(
      '@/views/protocols/ProtocolSharedView.vue'
    ),
  },
  { // Protocole à partager - Parent
    path: '/protocole/:id?/partage',
    name: 'protocolSharedModalities',
    meta: {
      title: 'Partages des modalités d\'un protocole',
    },
    component: () => import(
      '@/views/protocols/ProtocolSharedAddView.vue'
    ),
    redirect: { name: 'protocolModalities' },
    children: [
      { // Modalités
        path: 'modalites',
        name: 'protocolModalities',
        meta: {
          title: 'Modalités à partager',
        },
        component: () => import(
          '@/views/protocols/SharedAddSubviews/ModalitiesAddSubview.vue'
        ),
      },
      { // Entités
        path: 'entites',
        name: 'protocolEntities',
        meta: {
          title: 'Entités à partager',
        },
        component: () => import(
          '@/views/protocols/SharedAddSubviews/EntitiesAddSubview.vue'
        ),
      },
      { // Informations générales
        path: 'recapitulatif',
        name: 'protocolSummary',
        meta: {
          title: 'Récapitulatif',
        },
        component: () => import(
          '@/views/protocols/SharedAddSubviews/SummaryAddSubview.vue'
        ),
      },
    ],
  },
  { // Listing des fichiers
    path: '/protocole/:id?/fichiers',
    name: 'protocolFiles',
    meta: {
      title: 'Fichiers d\'un protocole',
      permissions: 'protocolfichier_show,fichier_list',
    },
    component: () => import(
      '@/views/protocols/ProtocolFilesView.vue'
    ),
  },

  // Événements
  { // Ajouter/Editer tâches expérimentales
    path: '/protocole/:id/evenement/:tid/editer',
    name: 'protocolExperimentalTaskEdit',
    meta: {
      title: 'Éditer une tâche expérimentale',
      permissions: 'essaievenement_oadd,essaievenement_oedit',
    },
    component: () => import(
      '@/views/protocols/ExperimentalTask/ExperimentalTaskAddEditView.vue'
    ),
    redirect: { name: 'protocolExperimentalTaskGeneralInformationEdit' },
    children: [
      { // Informations générales
        path: '',
        name: 'protocolExperimentalTaskGeneralInformationEdit',
        meta: {
          title: 'Informations générales',
        },
        component: () => import(
          '@/views/protocols/ExperimentalTask/AddEditSubviews/GeneralInformationSubview.vue'
        ),
      },
      // Notations - Variables étudiées
      {
        path: 'variables-etudiees',
        name: 'protocolExperimentalTaskVariableStudiedListEdit',
        meta: {
          title: 'Variables étudiées',
          public: false,
          permissions: 'essaievenementnotationvariable_olist',
        },
        component: () => import(
          '@/views/protocols/ExperimentalTask/AddEditSubviews/Notation/VariablesStudiedList.vue'
        ),
      },
      {
        path: 'variable-etudiee/:veid?/definition/creer',
        name: 'protocolExperimentalTaskVariableStudiedSettingsAdd',
        meta: {
          title: 'Variable étudiée',
          public: false,
          permissions: 'essaievenementnotationvariable_oadd',
        },
        component: () => import(
          '@/views/protocols/ExperimentalTask/AddEditSubviews/Notation/VariableStudiedSubview.vue'
        ),
      },
      {
        path: 'variable-etudiee/:veid/definition/editer',
        name: 'protocolExperimentalTaskVariableStudiedSettingsEdit',
        meta: {
          title: 'Variable étudiée',
          public: false,
          permissions: 'essaievenementnotationvariable_oedit',
        },
        component: () => import(
          '@/views/protocols/ExperimentalTask/AddEditSubviews/Notation/VariableStudiedSubview.vue'
        ),
      },
      {
        path: 'variable-etudiee/:veid?/niveau-saisie/creer',
        name: 'protocolExperimentalTaskVariableStudiedEntryLevelAdd',
        meta: {
          title: 'Variable étudiée',
          public: false,
        },
        component: () => import(
          '@/views/protocols/ExperimentalTask/AddEditSubviews/Notation/VariableStudiedSubview.vue'
        ),
      },
      {
        path: 'variable-etudiee/:veid/niveau-saisie/editer',
        name: 'protocolExperimentalTaskVariableStudiedEntryLevelEdit',
        meta: {
          title: 'Variable étudiée',
          public: false,
        },
        component: () => import(
          '@/views/protocols/ExperimentalTask/AddEditSubviews/Notation/VariableStudiedSubview.vue'
        ),
      },
      // Notation - Variables calculées
      {
        path: 'variables-calculees',
        name: 'protocolExperimentalTaskVariableCalculatedEdit',
        meta: {
          title: 'Variables calculées',
          public: false,
        },
        component: () => import(
          '@/views/protocols/ExperimentalTask/AddEditSubviews/Notation/VariableCalculatedSubview.vue'
        ),
      },
    ],
  },

  { // Consulter tâches expérimentales
    path: '/protocole/:id/evenement/:tid',
    name: 'protocolExperimentalTask',
    meta: {
      title: 'Consulter une tâche expérimentale',
      permissions: 'essaievenement_oshow',
    },
    component: () => import(
      '@/views/protocols/ExperimentalTask/ExperimentalTaskView.vue'
    ),
    redirect: { name: 'protocolExperimentalTaskGeneralInformation' },
    children: [
      { // Informations générales
        path: '',
        name: 'protocolExperimentalTaskGeneralInformation',
        meta: {
          title: 'Informations générales',
        },
        component: () => import(
          '@/views/protocols/ExperimentalTask/SingleSubviews/GeneralInformationSubview.vue'
        ),
      },
      // Notations - Variables étudiées
      {
        path: 'variables-etudiees',
        name: 'protocolExperimentalTaskVariableStudiedList',
        meta: {
          title: 'Variables étudiées',
          public: false,
        },
        component: () => import(
          '@/views/protocols/ExperimentalTask/SingleSubviews/Notation/VariablesStudiedList.vue'
        ),
      },
      {
        path: 'variable-etudiee/:veid/definition',
        name: 'protocolExperimentalTaskVariableStudiedSettings',
        meta: {
          title: 'Variable étudiée',
          public: false,
        },
        component: () => import(
          '@/views/protocols/ExperimentalTask/SingleSubviews/Notation/VariableStudiedSubview.vue'
        ),
      },
      {
        path: 'variable-etudiee/:veid/niveau-saisie',
        name: 'protocolExperimentalTaskVariableStudiedEntryLevel',
        meta: {
          title: 'Variable étudiée',
          public: false,
        },
        component: () => import(
          '@/views/protocols/ExperimentalTask/SingleSubviews/Notation/VariableStudiedSubview.vue'
        ),
      },
      // Variables calculées
      {
        path: 'variables-calculees',
        name: 'protocolExperimentalTaskVariableCalculated',
        meta: {
          title: 'Variables calculées',
          public: false,
        },
        component: () => import(
          '@/views/protocols/ExperimentalTask/SingleSubviews/Notation/VariableCalculatedSubview.vue'
        ),
      },
    ],
  },
  { // Listing des regroupements
    path: '/protocole/:id/regroupements-essais',
    name: 'protocolEssaisGroupings',
    meta: {
      title: 'Regroupements d\'essais d\'un protocole',
      // permissions: 'protocolfichier_show,fichier_list',
    },
    component: () => import(
      '@/views/protocols/Grouping/ProtocolGroupingsView.vue'
    ),
  },
  { // Single d'un regroupement
    path: '/protocole/:id/regroupement-essais/:rid',
    name: 'grouping',
    meta: {
      title: 'Regroupement d\'essais',
      // permissions: 'protocolfichier_show,fichier_list',
    },
    component: () => import(
      '@/views/protocols/Grouping/ProtocolGroupingView.vue'
    ),
  },
  { // Regroupements - Création - Parent
    path: '/protocole/:id/regroupement-essai/creer',
    name: 'protocolEssaisGroupingAdd',
    meta: {
      title: 'Création d\'un regroupement d\'essais',
    },
    component: () => import(
      '@/views/protocols/Grouping/ProtocolGroupingAddEditView.vue'
    ),
    redirect: { name: 'groupingEssaisAdd' },
    children: [
      { // choix des essais
        path: 'choix-des-essais',
        name: 'groupingEssaisAdd',
        meta: {
          title: 'Choix des essais',
        },
        component: () => import(
          '@/views/protocols/Grouping/AddEditSubviews/GroupingEssaisAddEditView.vue'
        ),
      },
      { // choix des modalites
        path: ':rid/choix-des-modalites',
        name: 'groupingModalitiesAdd',
        meta: {
          title: 'Choix des modalités',
        },
        component: () => import(
          '@/views/protocols/Grouping/AddEditSubviews/GroupingContentAddEditView.vue'
        ),
      },
      { // choix des modalites
        path: ':rid/choix-des-variables',
        name: 'groupingVariablesAdd',
        meta: {
          title: 'Choix des Variables',
        },
        component: () => import(
          '@/views/protocols/Grouping/AddEditSubviews/GroupingContentAddEditView.vue'
        ),
      },
      { // récapitulatif
        path: ':rid/recapitulatif',
        name: 'groupingSummaryAdd',
        meta: {
          title: 'Récapitulatif',
        },
        component: () => import(
          '@/views/protocols/Grouping/AddEditSubviews/GroupingSummaryAddEditView.vue'
        ),
      },
    ],
  },
]
