<template>
  <div class="alerts">
    <Alert
      v-for="(alert, index) in alerts"
      :key="index"
      :type="alert.type"
      :content="alert.content"
    />
  </div>
</template>

<script>
import Alert from '@/components/base/Alert.vue'

export default {
  name: 'Alerts',

  components: {
    Alert,
  },

  data() {
    return {
      alerts: [],
    }
  },

  mounted() {
    // Listen to pub/sub bus events
    this.emitter.on('alert', this.addAlert)
  },

  methods: {
    // Find a free alert ID
    getNextID() {
      let id = 0
      let found = false
      /* eslint no-loop-func: 0 */
      while (!found) {
        if (this.alerts.filter((alert) => alert.id === id).length > 0) {
          id += 1
        } else {
          found = true
        }
      }
      return id
    },
    // Create a new alert in the list
    addAlert(alert) {
      const nextID = this.getNextID()
      this.alerts.unshift({
        id: nextID,
        type: alert.type,
        content: alert.content,
      })
      window.setTimeout(() => {
        this.removeAlert(nextID)
      }, 3000)
    },
    // Delete an alert by ID
    removeAlert(id) {
      const index = this.alerts.map((x) => x.Id).indexOf(id)
      this.alerts.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.alerts {
  position: fixed;
  z-index: 99999; // TODO: Nico, maybe a better solution ^^'
  bottom: 0;
  right: 0;
  left: 0;
  padding: $gutter-eighth;
  display: flex;
  flex-direction: column-reverse;

  > .alert {
    margin: $gutter-eighth;
  }

  @include bp($breakpoint-sidebar-compact) {
    left: $sidebar-width-tablet;
  }

  @include bp($breakpoint-sidebar-full) {
    left: $sidebar-width-desktop;
  }
}
</style>
