<template>
  <svg :class="className" xmlns="http://www.w3.org/2000/svg">
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink" />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',

  props: {
    name: {
      type: String,
      required: true,
    },

    size: {
      type: String,
      default: null,
    },
  },

  computed: {
    iconPath() {
      let icon = require(`@/assets/icons/${this.name}.svg`) // eslint-disable-line import/no-dynamic-require, global-require

      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default
      }

      return icon.url
    },

    className() {
      return `icon icon-${this.name} icon-${this.size}`
    },
  },
}
</script>

<style lang="scss" scoped>
/* SVG ICON */

.icon {
  display: inline-block;
  fill: currentColor;
  @include size($icon-size-base);
}

.icon-xxs {
  @include size($icon-size-xxs);
}
.icon-xs {
  @include size($icon-size-xs);
}
.icon-sm {
  @include size($icon-size-sm);
}
.icon-lg {
  @include size($icon-size-lg);
}
.icon-xl {
  @include size($icon-size-xl);
}
.icon-xxl {
  @include size($icon-size-xxl);
}
.icon-nopicture {
  display: block;
  margin: auto;
  color: $color-gray-light;
  width: 90%;
  height: 90%;
}
</style>
