import FetchService from './fetch.service'

class ProductService {
  constructor() {
    this.emitter = ''
    this.store = {}
    this.datas = {}
    this.repeatables = {}
  }

  setEmitter(emitter) {
    this.emitter = emitter
  }

  setStore(store) {
    this.store = store
  }

  // eslint-disable-next-line class-methods-use-this
  setDatas(datas) {
    this.datas = datas
    return this
  }

  // eslint-disable-next-line class-methods-use-this
  setRepeatables(repeatables) {
    this.repeatables = repeatables
    return this
  }

  // eslint-disable-next-line class-methods-use-this
  getRepeatables(form) {
    return {
      noms_commerciaux: {
        items: {
          group: [
            {
              label: 'Fournisseur',
              name: 'fournisseur_uid',
              meta: {
                optionKey: 'uid',
                optionValue: 'nom',
                apiEndpoint: 'fournisseur',
              },
            },
            { label: 'Nom', name: 'nom' },
            { label: 'Code interne', name: 'code_interne' },
          ],
        },
      },
      composants: {
        items: {
          group: [
            {
              label: 'Substance active',
              name: 'uid',
              meta: {
                optionKey: 'uid',
                optionValue: 'nom',
                apiEndpoint: 'composant',
              },
            },
            {
              label: 'Quantité', name: 'quantite', type: 'number', options: { min: 0, step: 0.0001 },
            },
            {
              label: 'Unité',
              name: 'unite_uid',
              meta: {
                optionKey: 'uid',
                optionValue: 'valeur',
                apiEndpoint: 'unite',
              },
            },
          ],
        },
      },
      doses: {
        items: {
          group: [
            {
              label: 'Dose', name: 'dose', type: 'number', options: { min: 0, step: 0.0001 },
            },
            {
              label: 'Unité',
              name: 'unite_uid',
              meta: {
                optionKey: 'uid',
                optionValue: 'valeur',
                apiEndpoint: 'unite',
              },
            },
            {
              label: 'Culture',
              name: 'culture_uid',
              meta: {
                optionKey: 'uid',
                optionValue: 'nom',
                apiEndpoint: 'culture',
                apiParams: { limit: 0, sort: 'nom.ASC' },
              },
            },
          ],
        },
      },
      doses_health: {
        items: {
          group: [
            {
              label: 'Culture',
              name: 'culture_uid',
              meta: {
                optionKey: 'uid',
                optionValue: 'nom',
                apiEndpoint: 'culture',
                apiParams: { limit: 0, sort: 'nom.ASC' },
              },
            },
            {
              label: 'Cible',
              name: 'cible_id',
              meta: {
                optionKey: 'id',
                optionValue: 'valeur',
                apiEndpoint: `dictionnaire/referentiel/${form.references.cible.id}/valeur`,
              },
            },
            {
              label: 'Dose', name: 'dose', type: 'number', options: { min: 0, step: 0.0001 },
            },
            {
              label: 'Unité',
              name: 'unite_uid',
              meta: {
                optionKey: 'uid',
                optionValue: 'valeur',
                apiEndpoint: 'unite',
              },
            },
          ],
        },
      },
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getEntities(parentOnly = false) {
    const query = await FetchService.get('authentification/profil')
    const response = await query
    const profil = response.data
    if (parentOnly) {
      return profil
    }
    let childs = []
    if ('enfants' in profil.entite) {
      childs = [...profil.entite.enfants]
    }

    childs.unshift(profil.entite)

    return childs
  }

  // eslint-disable-next-line class-methods-use-this
  getRepeatablesToDisplay(...repeatablesIds) {
    repeatablesIds.forEach((name) => {
      switch (name) {
        case 'noms_commerciaux':
          if (this.datas.noms_commerciaux.length > 0) {
            this.repeatables.noms_commerciaux = this.datas.noms_commerciaux
              .map((n) => {
                const data = {
                  Fournisseur: n.fournisseur.nom,
                  Nom: n.nom,
                  'Code interne': n.code_interne,
                }
                return data
              })
          }
          break
        case 'composants':
          if (this.datas.composants.length > 0) {
            this.repeatables.composants = this.datas.composants
              .map((n) => {
                const data = {
                  'Substance active': n.nom,
                  Quantité: n.pivot.quantite,
                  Unité: n.pivot.unite.valeur,
                }
                return data
              })
          }
          break
        case 'doses':
          if (this.datas.doses && this.datas.doses.length > 0) {
            this.repeatables.doses = this.datas.doses
              .map((n) => {
                const data = {
                  Dose: n.dose,
                  Culture: n.culture.nom,
                  Unité: n.unite.valeur,
                }
                return data
              })
          }
          break
        case 'doses_health':
          if (this.datas.doses && this.datas.doses.length > 0) {
            this.repeatables.doses = this.datas.doses
              .map((n) => {
                const data = {
                  Culture: n.culture.nom,
                  Cible: n.cible.valeur,
                  Dose: n.dose,
                  Unité: n.unite.valeur,
                }
                return data
              })
          }
          break
        default: break
      }
    })
    return this.repeatables
  }

  // eslint-disable-next-line class-methods-use-this
  getRepeatablesFromProduct(...repeatablesIds) {
    repeatablesIds.forEach((name) => {
      switch (name) {
        case 'doses_health':
          if (this.datas.doses && this.datas.doses.length > 0) {
            this.repeatables.doses_health.default = this.datas.doses
              .map((composant) => {
                const data = {
                  id: composant.id,
                  culture_uid: {
                    key: composant.culture.uid,
                    value: composant.culture.nom,
                  },
                  cible_id: {
                    key: composant.cible.id,
                    value: composant.cible.valeur,
                  },
                  dose: composant.dose,
                  unite_uid: {
                    key: composant.unite.uid,
                    value: composant.unite.valeur,
                  },
                }
                return data
              })
          }
          break
        case 'doses':
          if (this.datas.doses && this.datas.doses.length > 0) {
            this.repeatables.doses.default = this.datas.doses
              .map((composant) => {
                const data = {
                  id: composant.id,
                  unite_uid: {
                    key: composant.unite.uid,
                    value: composant.unite.valeur,
                  },
                  culture_uid: {
                    key: composant.culture.uid,
                    value: composant.culture.nom,
                  },
                  dose: composant.dose,
                }
                return data
              })
          }
          break
        case 'composants':
          if (this.datas.product.composants.length > 0) {
            this.repeatables.composants.default = this.datas.product.composants
              .map((composant) => {
                const data = {
                  uid: {
                    key: composant.uid,
                    value: composant.nom,

                  },
                  quantite: composant.pivot.quantite,
                  unite_uid: {
                    key: composant.pivot.unite.uid,
                    value: composant.pivot.unite.valeur,
                  },
                }
                return data
              })
          }
          break
        case 'noms_commerciaux':
          if (this.datas.product.noms_commerciaux.length > 0) {
            this.repeatables.noms_commerciaux.default = this.datas.product.noms_commerciaux
              .map((composant) => {
                const data = {
                  id: composant.id,
                  fournisseur_uid: {
                    key: composant.fournisseur.uid,
                    value: composant.fournisseur.nom,

                  },
                  nom: composant.nom,
                  code_interne: composant.code_interne,
                }
                return data
              })
          }
          break
        default:
          break
      }
    })
    return this.repeatables
  }

  // eslint-disable-next-line class-methods-use-this
  async getReferenceByUids(...uids) {
    const references = await FetchService.get('dictionnaire/referentiel', {
      limit: 0,
    })
    const datas = {}
    uids.forEach((uid) => {
      datas[uid] = null
      datas[uid] = references.data
        .find((elem) => elem.uid === uid)
    })
    return datas
  }

  // eslint-disable-next-line class-methods-use-this
  async getPictos() {
    const references = await FetchService.get('pictogramme')
    const datas = await references
    return datas.data
  }

  // eslint-disable-next-line class-methods-use-this
  async getThematique(uid) {
    const response = await FetchService.get(`dictionnaire/thematique?filters=uid:lk(${uid})`)
    const datas = await response
    return datas.data.slice(0, 1).shift()
  }

  // eslint-disable-next-line class-methods-use-this
  sanitizeParams(param, isPicto = false) {
    let result = null
    if (param && 'key' in param && !isPicto) {
      result = param.key
    } else if (param && 'uid' in param) {
      result = { uid: param.uid || param.value }
    } else if (param) {
      result = Object.values(param).map((p, i) => {
        if (isPicto) {
          // eslint-disable-next-line no-param-reassign
          p = this.sanitizeParams(p, true)
          // eslint-disable-next-line no-param-reassign
          param[i] = p
        } else {
          // eslint-disable-next-line no-param-reassign
          p = Object.keys(p).map((k) => {
            if (typeof p[k] === 'object') {
              // eslint-disable-next-line no-param-reassign
              p[k] = this.sanitizeParams(p[k])
            }
            return { ...p, [k]: p[k] }
          })
        }
        return param
      }).shift()
    }
    return result
  }

  // eslint-disable-next-line class-methods-use-this
  getNamesToStore(postParams, repeatables) {
    let namesIdsFromDb = []
    if (repeatables) {
      namesIdsFromDb = repeatables.map((name) => name?.id)
    }

    const namesUpdated = postParams.noms_commerciaux.filter((name) => (
      name.id ? name : false
    ))

    const idsNamesUpdated = namesUpdated.map((name) => name.id)

    const idsNamesDeleted = namesIdsFromDb.filter((i) => !idsNamesUpdated.includes(i))

    const namesCreated = postParams.noms_commerciaux.filter((name) => (
      !(name.id) ? name : false
    ))

    return { namesCreated, namesUpdated, idsNamesDeleted }
  }

  // eslint-disable-next-line class-methods-use-this
  getDosesToStore(postParams, datas) {
    const dosesIdsFromDb = datas.map((dose) => dose?.id)

    const dosesUpdated = postParams.doses.filter((dose) => (
      dose.id ? dose : false
    ))

    const idsDosesUpdated = dosesUpdated.map((dose) => dose.id)

    const idsDosesDeleted = dosesIdsFromDb.filter((i) => !idsDosesUpdated.includes(i))

    const dosesCreated = postParams.doses.filter((dose) => (
      !(dose.id) ? dose : false
    ))

    return { dosesCreated, dosesUpdated, idsDosesDeleted }
  }

  delete(id, routeName) {
    this.emitter.emit('open-loader')

    FetchService.delete(`produit/${id}`).then(
      () => {
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Le produit a bien été supprimé.',
        })
        this.emitter.emit('list-refresh')
        this.emitter.emit('close-loader')
        this.$router.push({ name: routeName })
      },
      (responseError) => {
        this.emitter.emit('alert', {
          type: 'error',
          content: 'data' in responseError ? responseError.data : responseError.message,
        })
        this.emitter.emit('close-loader')
      },
    )
  }
}

export default new ProductService()
