import axios from 'axios'

class FileService {
  constructor() {
    this.emitter = {}
    this.store = {}
    this.data = []
    this.axios = axios
  }

  setStore(store) {
    this.store = store
  }

  setEmitter(emitter) {
    this.emitter = emitter
  }

  // eslint-disable-next-line class-methods-use-this
  setData(data) {
    const formData = new FormData()

    formData.append('morceau', data.morceau, data.meta.nom)
    formData.append('position', data.position)
    Object.keys(data.meta).forEach((key) => {
      formData.append(`meta[${key}]`, data.meta[key])
    })

    return formData
  }

  /**
   * Convert bytes to the correct unit of measure
   *
   * @param bytes
   * @returns {string}
   */
  // eslint-disable-next-line class-methods-use-this
  formatBytes(bytes) {
    const marker = 1024
    const decimal = 0
    const kiloBytes = marker
    const megaBytes = marker * marker
    const gigaBytes = marker * marker * marker

    if (bytes < kiloBytes) return `${bytes} bytes`
    if (bytes < megaBytes) return `${(bytes / kiloBytes).toFixed(decimal)}Ko`
    if (bytes < gigaBytes) return `${(bytes / megaBytes).toFixed(decimal)}Mo`
    return `${(bytes / gigaBytes).toFixed(decimal)}Go`
  }

  // eslint-disable-next-line class-methods-use-this
  formatMegaBytes(megabytes) {
    const marker = 1024
    return megabytes * marker * marker
  }

  /**
   * Check if the file has the correct extensions
   * @param file
   * @param extensions
   * @returns {*}
   */
  // eslint-disable-next-line class-methods-use-this
  hasExtensions(file, extensions) {
    const fileExtension = file.nom.split('.').pop().toLowerCase()
    return extensions.find((extension) => fileExtension === extension)
  }

  displayImage(token) {
    if (token) {
      return new Promise((successCallback, failureCallback) => {
        this.axios(`${process.env.VUE_APP_ROOT_API}utilitaire/media/${token}.bin`, {
          responseType: 'blob',
          headers: {
            Accept: 'application/octet-stream',
            Authorization: `Bearer ${this.store.state.auth.user.token}`,
          },
        }).then((response) => {
          successCallback({
            data: URL.createObjectURL(response.data),
          })
        }).catch((error) => {
          failureCallback(error)
        })
      })
    }
    return Promise.resolve(false)
  }

  exportFile(type, ressource, rid, childRessource = null, params = false) {
    return new Promise((successCallback, failureCallback) => {
      this.emitter.emit('open-loader')
      let endpoint = `taches/type/${type}/ressource/${ressource}/${rid}`
      if (childRessource) {
        endpoint = `taches/type/${type}/ressource/${ressource}/${rid}/${childRessource}`
      }

      if (params) {
        const str = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&')
        endpoint += `?${str}`
      }

      const filename = childRessource ? `${ressource}_${rid}_${childRessource}` : `${ressource}_${rid}`

      this.downloadFile(endpoint, filename, type).then(
        (response) => {
          successCallback(response)
        },
        (errorResponse) => {
          failureCallback(errorResponse)
        },
      ).finally(() => {
        this.emitter.emit('close-loader')
      })
    })
  }

  downloadFile(endpoint, name, type = null) {
    return new Promise((successCallback, failureCallback) => {
      this.emitter.emit('open-loader')
      this.axios(`${process.env.VUE_APP_ROOT_API}${endpoint}`, {
        method: 'GET',
        headers: {
          Accept: 'application/octet-stream',
          Authorization: `Bearer ${this.store.state.auth.user.token}`,
        },
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL

        fileLink.setAttribute('download', type ? `${name}.${type}` : name)

        if (type === 'xls') {
          fileLink.setAttribute('download', `${name}.xlsx`)
        }

        document.body.appendChild(fileLink)

        fileLink.click()
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Le fichier a bien été téléchargé.',
        })

        successCallback({ fileLink })
        this.emitter.emit('close-loader')
      }).catch((error) => {
        failureCallback(error)
        this.emitter.emit('close-loader')
      })
    })
  }

  downloadEssaiExport(endpoint, params, fileName = 'export') {
    return new Promise((successCallback, failureCallback) => {
      this.emitter.emit('open-loader')
      this.axios(`${process.env.VUE_APP_ROOT_API}${endpoint}`, {
        method: 'POST',
        headers: {
          Accept: 'application/octet-stream',
          Authorization: `Bearer ${this.store.state.auth.user.token}`,
        },
        responseType: 'blob',
        data: params,
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL

        fileLink.setAttribute('download', `${fileName}.xlsx`)

        document.body.appendChild(fileLink)

        fileLink.click()
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Le fichier a bien été téléchargé.',
        })

        successCallback({ fileLink })
        this.emitter.emit('close-loader')
      }).catch((error) => {
        failureCallback(error)
        this.emitter.emit('close-loader')
      })
    })
  }
}

export default new FileService()
