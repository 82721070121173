import fetchService from './fetch.service'

class ProtocolService {
  constructor() {
    this.emitter = ''
    this.store = {}
    this.schema = {}
    this.observations = []
    this.protocol = {}
    this.formService = {}
  }

  setEmitter(emitter) {
    this.emitter = emitter
  }

  setStore(store) {
    this.store = store
  }

  // eslint-disable-next-line class-methods-use-this
  setSchema(schema) {
    this.schema = schema
    return this
  }

  // eslint-disable-next-line class-methods-use-this
  setProtocol(protocol) {
    this.protocol = protocol
    return this
  }

  setFormService(formService) {
    this.formService = formService
    return this
  }

  getSchema() {
    return this.schema
  }

  populateForm() {
    this.schema = this.formService.populateShema(this.protocol)

    this.schema.annee_recolte_id = {
      key: this.protocol.annee_recolte?.id,
      value: this.protocol.annee_recolte?.valeur,
    }

    this.schema.cultures = this.protocol.cultures?.map((culture) => ({
      key: culture?.uid,
      value: culture?.nom,
    }))

    this.schema.cible_id = {
      key: this.protocol.cible?.id,
      value: this.protocol.cible?.valeur,
    }

    this.schema.cibles = this.protocol.cibles?.map((cible) => ({
      key: cible?.id,
      value: cible?.valeur,
    }))

    this.schema.typedispositif_uid = {
      key: this.protocol.type_dispositif?.uid,
      value: this.protocol.type_dispositif?.designation,
    }

    this.schema.implantation_temoins_id = {
      key: this.protocol.implantation_temoins?.id,
      value: this.protocol.implantation_temoins?.valeur,
    }

    this.schema.type_uid = {
      key: this.protocol.type?.uid,
      value: this.protocol.type?.designation,
    }

    this.schema.declaration_protocole_id = {
      key: this.protocol.declaration_protocole?.id,
      value: this.protocol.declaration_protocole?.valeur,
    }

    this.schema.unite_uid = {
      key: this.protocol.unite?.uid,
      value: this.protocol.unite?.valeur,
    }

    this.schema.thematique_uid = {
      key: this.protocol.thematique?.uid,
      value: this.protocol.thematique?.designation,
    }

    this.schema.sous_thematiques = this.protocol.sous_thematiques?.map((thematique) => ({
      key: thematique?.uid,
      value: thematique?.nom,
    }))

    this.formService.setFormValues(this.schema)
    this.setSchema(this.schema)
  }

  async getObservations() {
    const response = await fetchService.get(`protocole/${this.protocol.id}/modalite_observation`, {
      limit: 0,
    })
    this.observations = await response.data
    return this.observations
  }

  // eslint-disable-next-line
  filterByAgriculturalSeason(key, isReturningObject = false, filterName="annee") {
    const date = new Date()
    const data = this.store.state.app.annee

    let handleYears = null
    // Filtering protocols to match the agricultural season
    if (date.getMonth() >= 7 && date.getMonth() < 10) { // August 1st
      // Current year and next year
      handleYears = data
        .filter((year) => year.valeur === date.getFullYear().toString() || year.valeur === (date.getFullYear() + 1).toString())
        .map((year) => ({
          key: year[key],
          value: year.valeur,
        }))
    } else if ((date.getDate() === 31 && date.getMonth() === 10) || date.getMonth() >= 10) { // October 31st
      // Next year
      handleYears = data
        .filter((year) => year.valeur === (date.getFullYear() + 1).toString())
        .map((year) => ({
          key: year[key],
          value: year.valeur,
        }))
    } else {
      // current year
      handleYears = data
        .filter((year) => year.valeur === date.getFullYear().toString())
        .map((year) => ({
          key: year[key],
          value: year.valeur,
        }))
    }

    let value = ''
    if (handleYears) {
      const years = handleYears
        .map((year) => (key === 'id' ? year.key : year.value))
        .join(',')

      value = `${filterName}.${key}:in(${years})`
    }

    if (isReturningObject) {
      return {
        years: handleYears,
        filters: value,
      }
    }

    return value
  }
}

export default new ProtocolService()
