export default [
  { // Listing des logs
    path: '/:ressource/:ressourceid/logs/:from',
    name: 'ressourceLogs',
    meta: {
      public: false,
      title: 'Consulter des logs',
      permissions: 'log_list',
    },
    component: () => import(
      '@/views/logs/LogsView.vue'
    ),
  },
  { // Details du log
    path: '/:ressource/:ressourceid/log/:logid/:from',
    name: 'ressourceLog',
    meta: {
      public: false,
      title: 'Consulter un log',
      permissions: 'log_show',
    },
    component: () => import(
      '@/views/logs/LogView.vue'
    ),
  },
]
