/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const initialState = {
  permission: {
    data: [],
  },
}

// initial state
const state = () => (initialState)

// actions
const actions = {
  selection({ commit }, data) {
    commit('clearSelection')
    commit('updateSelection', data)
  },
  remove({ commit }, data) {
    commit('removeItem', data)
  },
  clear({ commit }) {
    commit('clearSelection')
  },
}

// mutations
const mutations = {
  clearSelection(state) {
    state.permission.data = []
  },
  updateSelection(state, data) {
    state.permission.data = data
  },
  removeItem(state, data) {
    state.permission.data.splice(state.permission.data.indexOf(data.item), 1)
  },
}

const getters = {
  getSelectedPermissions(state) {
    return state.permission.data
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
