export default [
  // gestion des parcelles
  {
    path: '/parcelles',
    name: 'parcels',
    meta: {
      title: 'Liste des parcelles',
      public: false,
      permissions: 'parcelle_list',
    },
    component: () => import(
      '@/views/parcels/ParcelsView.vue'
    ),
  },
  {
    path: '/parcelle/:id',
    name: 'parcel',
    meta: {
      public: false,
      title: 'Parcelle',
      permissions: 'parcelle_show',
    },
    component: () => import(
      '@/views/parcels/ParcelView.vue'
    ),
  },
  {
    path: '/parcelle/creer',
    name: 'parcelAdd',
    meta: {
      public: false,
      title: 'Parcelle',
      permissions: 'parcelle_add',
    },
    component: () => import(
      '@/views/parcels/ParcelAddEditView.vue'
    ),
  },
  {
    path: '/parcelle/editer/:id',
    name: 'parcelEdit',
    meta: {
      public: false,
      title: 'Parcelle',
      permissions: 'parcelle_edit',
    },
    component: () => import(
      '@/views/parcels/ParcelAddEditView.vue'
    ),
  },

]
