/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import fetchService from '../../services/fetch.service'

const initialState = {
  user: {
    loggedIn: false, token: null, expireAt: (new Date()), refreshed: 0, data: {},
  },
}

// initial state
const state = () => (initialState)

// actions
const actions = {
  login({ commit }, user) {
    return new Promise((successCallback, failureCallback) => {
      fetchService.post('authentification/connexion', user).then(
        (response) => {
          commit('preparlogin', response.data)

          if (response.data.utilisateur.profils.length > 0) {
            const { profils } = response.data.utilisateur
            profils.forEach((profil) => {
              fetchService.get(`profil/${profil.id}/droit`).then(
                (responseProfil) => {
                  commit('addPermissions', responseProfil.data)
                  commit('loginSuccess')
                  successCallback(responseProfil)
                },
                (responseProfilError) => {
                  failureCallback(responseProfilError)
                },
              )
            })
          } else {
            successCallback(response)
          }
        },
        (responseError) => {
          commit('logout')
          failureCallback(responseError)
        },
      )
    })
  },
  updateUser({ commit }, user) {
    let path = 'authentification/profil'
    if (user.user_id) {
      path = `utilisateur/${user.user_id}`
    }
    return new Promise((successCallback, failureCallback) => {
      fetchService.put(path, user).then(
        (response) => {
          commit('updateUser', response.data)
          let profilsLength = 0
          if ('utilisateur' in response.data) {
            profilsLength = response.data.utilisateur.profils.length
          } else {
            profilsLength = response.data.profils.length
          }

          if (profilsLength > 0) {
            const { profils } = response.data.utilisateur || response.data
            profils.forEach((profil) => {
              fetchService.get(`profil/${profil.id}/droit`).then(
                (responseProfil) => {
                  commit('addPermissions', responseProfil.data)
                  successCallback(responseProfil)
                },
                (responseProfilError) => {
                  failureCallback(responseProfilError)
                },
              )
            })
          }
          successCallback(response)
        },
        (responseError) => {
          failureCallback(responseError)
        },
      )
    })
  },
  refreshToken({ commit, state }, auto) {
    return new Promise((successCallback, failureCallback) => {
      fetchService.get('authentification/rafraichir').then(
        (response) => {
          const { data } = response
          data.refreshed = 0
          if (auto) {
            data.refreshed = state.user.refreshed + 1
          }
          commit('refreshToken', data)
          successCallback(response)
        },
        (responseError) => {
          commit('logout')
          failureCallback(responseError)
        },
      )
    })
  },
  refreshUser({ commit, state }) {
    return new Promise((successCallback, failureCallback) => {
      fetchService.get(`utilisateur/${state.user.data.id}`).then((response) => {
        commit('updateUser', response.data)

        if (response.data.profils.length > 0) {
          const { profils } = response.data
          profils.forEach((profil) => {
            fetchService.get(`profil/${profil.id}/droit`).then(
              (responseProfil) => {
                commit('addPermissions', responseProfil.data)
                successCallback(responseProfil)
              },
              (responseProfilError) => {
                failureCallback(responseProfilError)
              },
            )
          })
        }
        successCallback(response)
      },
      (responseError) => {
        failureCallback(responseError)
      })
    })
  },
  logout({ commit }) {
    localStorage.removeItem('referentials')
    commit('logout')
  },
}

// mutations
const mutations = {
  preparlogin(state, data) {
    const date = new Date()
    date.setSeconds(date.getSeconds() + (data.expire_dans - 240))
    state.user.loggedIn = false
    state.user.token = data.jeton
    state.user.data = data.utilisateur
    state.user.data.permissions = []
    state.user.expireAt = date.getTime()
    state.user.refreshed = 0
  },
  loginSuccess(state) {
    state.user.loggedIn = true
  },
  logout(state) {
    state.user.loggedIn = false
    state.user.token = null
    state.user.expireAt = (new Date()).getTime()
    state.user.refreshed = 0
    state.user.data = {}
  },
  updateUser(state, data) {
    state.user.data = data
    state.user.data.permissions = []
  },
  refreshToken(state, data) {
    const date = new Date()
    date.setSeconds(date.getSeconds() + (data.expire_dans - 240))
    state.user.loggedIn = true
    state.user.token = data.jeton
    state.user.expireAt = date.getTime()
    state.user.refreshed = data.refreshed
  },
  addPermissions(state, data) {
    const { permissions } = state.user.data

    data.forEach((res) => {
      if (!permissions.find((perm) => perm.id === res.id)) {
        permissions.push(res)
      }
    })

    state.user.data.permissions = permissions
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
