export default [
  // gestion des notation
  {
    path: '/notation/:id/localisation/:localisation/etape/:etape',
    name: 'notation',
    meta: {
      title: 'Notation',
      public: false,
      offline: true,
      notation: true,
    },
    props: true,
    component: () => import(
      '@/views/notations/NotationView.vue'
    ),
  },
  {
    path: '/notation/:id/adventices',
    name: 'adventice',
    meta: {
      title: 'Adventices',
      public: false,
      offline: true,
      adventice: true,
    },
    component: () => import(
      '@/views/notations/AdventiceView.vue'
    ),
  },
  {
    path: '/notation/:id/resume',
    name: 'notationSummary',
    meta: {
      title: 'Résumé',
      public: false,
      offline: true,
      notation: true,
    },
    component: () => import(
      '@/views/notations/SummaryView.vue'
    ),
  },
]
