export default [
  // Fertilisants
  { // Listing
    path: '/produits/fertilisant',
    name: 'fertilizers',
    meta: {
      title: 'Fertilisants',
      permissions: 'produit_list',
    },
    component: () => import(
      '@/views/products/Fertilizer/FertilizersView.vue'
    ),
  },

  { // Ajout
    path: '/produit/fertilisant/creer',
    name: 'fertilizerAdd',
    meta: {
      title: 'Ajouter un produit fertilisant',
      permissions: 'produit_add',
    },
    component: () => import(
      '@/views/products/Fertilizer/FertilizerAddEditView.vue'
    ),
  },
  { // Edition
    path: '/produit/fertilisant/:id?/editer',
    name: 'fertilizerEdit',
    meta: {
      title: 'Modifier un produit fertilisant',
      permissions: 'produit_edit',
    },
    component: () => import(
      '@/views/products/Fertilizer/FertilizerAddEditView.vue'
    ),
  },
  { // Consultation
    path: '/produit/fertilisant/:id',
    name: 'fertilizer',
    meta: {
      title: 'Consulter un produit fertilisant',
      permissions: 'produit_show',
    },
    component: () => import(
      '@/views/products/Fertilizer/FertilizerView.vue'
    ),
  },

  // Santé végétale
  { // Listing
    path: '/produits/sante-vegetale',
    name: 'healths',
    meta: {
      title: 'Santé végétale',
      permissions: 'produit_list',
    },
    component: () => import(
      '@/views/products/Health/HealthsView.vue'
    ),
  },
  { // Ajout
    path: '/produit/sante-vegetale/creer',
    name: 'healthAdd',
    meta: {
      title: 'Ajouter un produit de santé végétale',
      permissions: 'produit_add',
    },
    component: () => import(
      '@/views/products/Health/HealthAddEditView.vue'
    ),
  },
  { // Edition
    path: '/produit/sante-vegetale/:id?/editer',
    name: 'healthEdit',
    meta: {
      title: 'Modifier un produit de santé végétale',
      permissions: 'produit_edit',
    },
    component: () => import(
      '@/views/products/Health/HealthAddEditView.vue'
    ),
  },
  { // Consultation
    path: '/produit/sante-vegetale/:id',
    name: 'health',
    meta: {
      title: 'Consulter un produit de santé végétale',
      permissions: 'produit_show',
    },
    component: () => import(
      '@/views/products/Health/HealthView.vue'
    ),
  },

  // Semences
  { // Listing
    path: '/produits/semence',
    name: 'seeds',
    meta: {
      title: 'Semence',
      permissions: 'produit_list',
    },
    component: () => import(
      '@/views/products/Seed/SeedsView.vue'
    ),
  },
  { // Ajout
    path: '/produit/semence/creer',
    name: 'seedAdd',
    meta: {
      title: 'Ajouter une semence',
      permissions: 'produit_add',
    },
    component: () => import(
      '@/views/products/Seed/SeedAddEditView.vue'
    ),
  },
  { // Édition
    path: '/produit/semence/:id?/edition',
    name: 'seedEdit',
    meta: {
      title: 'Modifier une semence',
      permissions: 'produit_edit',
    },
    component: () => import(
      '@/views/products/Seed/SeedAddEditView.vue'
    ),
  },
  { // Consultation
    path: '/produit/semence/:id',
    name: 'seed',
    meta: {
      title: 'Consulter une semence',
      permissions: 'produit_show',
    },
    component: () => import(
      '@/views/products/Seed/SeedView.vue'
    ),
  },

  // Fournisseurs
  { //
    path: '/produit/fournisseurs',
    name: 'suppliers',
    meta: {
      title: 'Fournisseurs',
      permissions: 'fournisseur_add,fournisseur_edit',
    },
    component: () => import(
      '@/views/products/Supplier/SuppliersView.vue'
    ),
  },
  { // Ajout
    path: '/produit/fournisseurs/creer',
    name: 'supplierAdd',
    meta: {
      title: 'Ajouter un fournisseur',
      permissions: 'fournisseur_add',
    },
    props: true,
    component: () => import(
      '@/views/products/Supplier/SupplierAddEditView.vue'
    ),
  },
  {
    // Editer
    path: '/produit/fournisseurs/:id/editer',
    name: 'supplierEdit',
    meta: {
      title: 'Modifier un fournisseur',
      permissions: 'fournisseur_edit',
    },
    props: true,
    component: () => import(
      '@/views/products/Supplier/SupplierAddEditView.vue'
    ),
  },

  // Contact fournisseurs
  { // Listing
    path: '/produit/fournisseurs/:fId/contacts',
    name: 'supplierContacts',
    meta: {
      title: 'Contact fournisseurs',
      permissions: 'fournisseurcontact_olist',
    },
    props: true,
    component: () => import(
      '@/views/products/Supplier/SupplierContactsView.vue'
    ),
  },
  {
    // Ajouter
    path: '/produit/fournisseurs/:fId/contacts/creer',
    name: 'supplierContactAdd',
    meta: {
      title: 'Ajouter le contact au fournisseur',
      permissions: 'fournisseurcontact_oadd',
    },
    props: true,
    component: () => import(
      '@/views/products/Supplier/SupplierContactAddEditView.vue'
    ),
  },
  {
    // Editer
    path: '/produit/fournisseurs/:fId/contacts/:cId/editer',
    name: 'supplierContactEdit',
    meta: {
      title: 'Modifier le contact du fournisseur',
      permissions: 'fournisseurcontact_oedit',
    },
    props: true,
    component: () => import(
      '@/views/products/Supplier/SupplierContactAddEditView.vue'
    ),
  },
  {
    // Editer
    path: '/produit/fournisseurs/:fId/contacts/:cId',
    name: 'supplierContact',
    meta: {
      title: 'Consulter le contact fournisseur',
      permissions: 'fournisseurcontact_oshow',
    },
    props: true,
    component: () => import(
      '@/views/products/Supplier/SupplierContactView.vue'
    ),
  },
]
