import fetchService from './fetch.service'
import helperService from './helper.service'

class TaskService {
  constructor() {
    this.emitter = ''
    this.store = {}
    this.router = {}
    this.modalities = []
    this.formattedModalities = []
    this.headers = []
    this.fiche = {}
    this.route = {}
    this.essaiId = ''
    this.taskId = ''
    this.formService = {}
    this.fileService = {}
    this.schema = {}
  }

  setEmitter(emitter) {
    this.emitter = emitter
  }

  setStore(store) {
    this.store = store
  }

  setFileService(fileService) {
    this.fileService = fileService
  }

  setFormService(formService) {
    this.formService = formService
  }

  setRoute(route) {
    this.route = route
    this.essaiId = this.route.params.id
    this.taskId = this.route.params.tid

    return this
  }

  setRouter(router) {
    this.router = router
  }

  async getFiche(route, type) {
    this.emitter.emit('open-loader')

    this.setRoute(route)

    await fetchService
      .get(`essai/${this.essaiId}/evenement/${this.taskId}/fiche/${type}`)
      .then((response) => {
        const data = response.data[0]
        this.fiche = data

        this.emitter.emit('close-loader')
      })

    return this.fiche
  }

  async initializeComponent(route) {
    this.setRoute(route)

    await this.fetchData()

    return {
      modalities: this.formattedModalities,
      headers: this.headers,
    }
  }

  fetchData() {
    return this.getModalities().then((rm) => {
      this.modalities = rm

      this.getHeaders()
      this.getFormatModalities(this.modalities).then((rfm) => {
        this.formattedModalities = rfm
      })
    })
  }

  getModalities() {
    return new Promise((resolve) => {
      const endpoint = `essai/${this.essaiId}/evenement/${this.taskId}/fiche/destruction/modalite`
      fetchService.get(endpoint, { limit: 0 }).then((res) => {
        resolve(res.data)
      })
    })
  }

  getFormatModalities(modalities) {
    return new Promise((resolve) => {
      let datas = []
      modalities.forEach((modality) => {
        let data
        if (this.isVegetation()) {
          data = {
            id: modality.id,
            ordre: modality.modalite?.ordre,
            designation: modality.modalite.designation ?? '---',
            mecanique_date: modality.mecanique_date,
            mecanique_moyen: modality.mecanique_moyen,
            chimique_date: modality.chimique_date,
            chimique_produit: modality.chimique_produit,
            chimique_dose: modality.chimique_dose,
            unite: modality.unite?.valeur,
          }
        } else {
          data = {
            id: modality.id,
            ordre: modality.modalite?.ordre,
            designation: modality.modalite.designation ?? '---',
            quantite: modality.quantite,
          }
        }
        datas.push(data)
      })
      datas = helperService.sortByOrder(datas)
      this.loading = false
      resolve(datas)

      this.formattedModalities = datas
    })
  }

  getHeaders() {
    if (this.isVegetation()) {
      this.headers = [
        {
          type: {
            designation: 'Désignation',
          },
          sub_headers: [],
        },
        {
          type: {
            designation: 'Mécanique',
          },
          sub_headers: [
            'Date',
            'Moyen',
          ],
        },
        {
          type: {
            designation: 'Chimique',
          },
          sub_headers: [
            'Date',
            'Produit',
            'Dose',
            'Unité',
          ],
        },
      ]
      this.staticHeaders = []
    } else {
      this.headers = [
        {
          type: {
            designation: 'Désignation',
          },
        },
        {
          type: {
            designation: 'Quantité',
          },
        },
      ]
      this.staticHeaders = []
    }
  }

  handleExperimentalTaskRedirect(route, message = null) {
    this.emitter.emit('alert', {
      type: 'success',
      content: `${message ?? 'La fiche'} a bien été modifiée.`,
    })

    this.router.push({
      name: route.name.replace('Edit', ''),
      params: {
        id: route.params.id,
        tid: route.params.tid,
      },
    })
  }

  isVegetation() {
    return this.route.name.includes('Vegetation')
  }

  // eslint-disable-next-line class-methods-use-this
  concatValueUnit(value, unit) {
    return `${value} ${unit}`
  }

  // eslint-disable-next-line class-methods-use-this
  getValueOnly(string) {
    if (string) {
      const r = string.split(' ')
      if (r.length > 1) {
        const value = r.shift()
        if (Number.isNaN(parseFloat(value))) {
          return null
        }
        return value
      }
      if (Number.isFinite(r.pop())) {
        return r.pop()
      }
    }

    return string
  }

  // eslint-disable-next-line class-methods-use-this
  getSuffixe(string) {
    if (string !== null) {
      let r = string.split(' ')
      const t = []
      if (r.length > 2) {
        t.push(r[0])
        let unit = ''
        for (let i = 1; i < r.length; i += 1) {
          unit = `${unit} ${r[i]}`
        }
        t.push(unit)
        r = t
      }
      const suffixe = r.pop()
      if (Number.isFinite(suffixe)) {
        return null
      }
      return suffixe
    }

    return string
  }

  /**
   * Volume non epandus
   */
  getVolumesNonEpandusFiche(populateSchema = false) {
    return new Promise((resolve) => {
      const endpoint = `essai/${this.essaiId}/evenement/${this.taskId}/fiche/volume_non_epandus`
      fetchService.get(endpoint, { limit: 0 }).then((response) => {
        const datas = response.data[0]

        if (populateSchema) {
          this.schema = this.formService.populateShema(datas)
          if (datas.date_validation) {
            this.schema.date_validation = helperService.formatDateForInput(datas.date_validation)
          }
          this.formService.setFormValues(this.schema)
        }

        resolve(datas)
      })
    })
  }

  getVolumesNonEpandusModalities() {
    return new Promise((resolve) => {
      const endpoint = `essai/${this.essaiId}/evenement/${this.taskId}/fiche/volume_non_epandus/modalite`
      fetchService.get(endpoint, { limit: 0, sort: 'id.asc' }).then((res) => {
        resolve(res.data)
      })
    })
  }

  getVolumesNonEpandusFormattedModalities(modalities, volumeTheoriqueBouillie, ecartTolere, modeCalcul) {
    return new Promise((resolve) => {
      const datas = []

      const volume = 0.1
      const designation = 'Essai à blanc'
      let ecartReel = this.volumeNonEpandusEcartReel(
        volume,
        volumeTheoriqueBouillie,
        modeCalcul,
      )

      modalities.forEach((modality) => {
        ecartReel = this.volumeNonEpandusEcartReel(modeCalcul === 'volume_applique' ? modality.volume_applique : modality.volume_restant, volumeTheoriqueBouillie, modeCalcul)
        const data = {
          id: modality.id,
          designation: modality.modalite ? modality?.modalite?.designation : designation,
          volume_restant: modality.volume_restant,
          volume_applique: modality.volume_applique,
          ecart_reel: ecartReel,
          conformite: this.volumeNonEpandusConformite(ecartReel, ecartTolere),
        }
        datas.push(data)
      })

      // Modalité d'essai à blanc en première position
      datas
        // eslint-disable-next-line
        .sort((x, y) => (x.designation === designation ? -1 : y.designation === designation ? 1 : 0))

      resolve(datas)
    })
  }

  downloadVolumeNonEpandusFiche(type, ressource, rid) {
    const endpoint = `taches/type/${type}/ressource/${ressource}/${rid}/volumes-non-epandus`
    const filename = `evenement_${rid}_volumes_non_epandus`
    this.fileService.downloadFile(endpoint, filename, type)
  }

  // eslint-disable-next-line class-methods-use-this
  volumeNonEpandusEcartReel(volume, volumeTheoriqueBouillie, modeCalcul) {
    let calcul
    if (modeCalcul === 'volume_restant') {
      // eslint-disable-next-line
      calcul = volume / volumeTheoriqueBouillie * 100
    } else {
      // eslint-disable-next-line
      calcul = Math.round((volumeTheoriqueBouillie - volume / volumeTheoriqueBouillie) * 100)
    }

    if (!Number.isFinite(calcul)) {
      calcul = 'Non renseigné'
    }

    return calcul
  }

  // eslint-disable-next-line class-methods-use-this
  volumeNonEpandusConformite(ecartReel, ecartTolere) {
    return ecartReel > ecartTolere ? 'Non conforme' : 'Conforme'
  }
}

export default new TaskService()
