<template>
  <div class="app-wrapper" :class="{
    'has-overlay': hasOverlay,
    'has-loader': hasLoader,
    'is-panel-menu-open': isPanelOpen['menu'],
    'is-panel-tool-open': isPanelOpen['tool'],
    'is-panel-user-open': isPanelOpen['user'],
    'is-menu-dropdown-open': isMenuDropdownOpen,
    'is-online': isOnline,
    'is-offline': !isOnline,
    'has-only-content-layout': !hasHeader,
    'has-no-scroll-layout': !hasScroll,
    'has-scrolled-down': hasScrolledDown,
    'has-notation-layout': hasNotationLayout,
    'has-adventice-layout': hasAdventiceLayout,
    'has-default-layout': !hasNotationLayout,
    'has-dashboard-layout': hasDashboardLayout,
  }">
    <div class="site-wrapper">
      <!-- SITE HEADER -->
      <header class="site-header" v-if="hasHeader">
        <top-bar :page-title="pageTitle" />
      </header>

      <!-- SITE ASIDE (aka "navbar") -->
      <aside class="site-aside" v-if="hasHeader">
        <template v-if="$store.state.auth.user?.data?.permissions &&
          $store.state.auth.user?.data?.permissions.length > 0">
          <nav-bar />
        </template>
      </aside>

      <!-- SITE MAIN CONTENT -->
      <main class="site-content" @scroll="onScroll">
        <div class="site-main-content">
          <router-view :page-title="pageTitle" />
          <Alerts />
        </div>
      </main>
      <!-- SITE FOOTER -->
      <!-- <footer class="site-footer">
        Footer
      </footer> -->
    </div>
    <!-- End .site-wrapper -->

    <!-- OVERLAY (for panels and dropdowns) -->
    <Overlay />

    <!-- ANIMATED LOADER -->
    <Loader global />

    <!-- MODALS -->
    <!-- <div id="modal" /> -->

    <Modal title="Information" :active="displayRefreshModal" @modal-close="logout()" size="sm">
      <template v-slot:modal-body>
        <p>
          Vous allez être déconnecté dans quelques instants.<br />
          Souhaitez-vous prolonger votre session&nbsp;?
        </p>
      </template>
      <template v-slot:modal-footer>
        <Btn text="Déconnexion" @click="logout()" />
        <Btn text="Oui" color="primary" @click="refreshToken()" />
      </template>
    </Modal>

    <Modal title="Une mise à jour est disponible" :active="updateExists" @modal-close="updateExists = false" size="sm">
      <template v-slot:modal-body>
        <p>
          Une nouvelle version d'OSS est disponible.
        </p>
        <p>
          Il est recommandé de mettre à jour dès que possible.
        </p>
        <p>
          <em>Version actuelle&nbsp;: {{ currentVersion }}</em>
        </p>
      </template>
      <template v-slot:modal-footer>
        <Btn text="Mettre à jour maintenant" color="primary" @click="refreshVersion()" />
      </template>
    </Modal>

    <!-- DEBUG ZONE -->
    <DebugZone />

  </div>
  <!-- End .app-wrapper -->
</template>

<script>
import TopBar from '@/components/layout/TopBar.vue'
import NavBar from '@/components/layout/NavBar.vue'
import Overlay from '@/components/layout/Overlay.vue'
import Alerts from '@/components/layout/Alerts.vue'
import Loader from '@/components/layout/Loader.vue'
import DebugZone from '@/components/layout/DebugZone.vue'
import Modal from '@/components/layout/Modal.vue'
import Btn from '@/components/base/Btn.vue'
import update from '@/mixins/update'

export default {
  name: 'App',

  components: {
    TopBar,
    NavBar,
    Overlay,
    Alerts,
    Loader,
    Modal,
    Btn,
    DebugZone,
  },

  data() {
    return {
      hasOverlay: false,
      hasLoader: false,
      isPanelOpen: {
        menu: false,
        tool: false,
        user: false,
      },
      isMenuDropdownOpen: false,
      checkTokenInterval: null,
      displayRefreshModal: false,
      refreshModalTimeOut: null,
      hasScrolledDown: false,
      isOnline: navigator.onLine,
      version: process.env.VERSION,
      currentVersion: null,
    }
  },
  mixins: [update],
  computed: {
    // ROUTE'S METAS
    // Shall we display the header (topbar and navbar)?
    hasHeader() { return !(this.$route.meta.noHeader ?? false) },
    // Is the main content area able to scroll vertically?
    hasScroll() { return !(this.$route.meta.noScroll ?? false) },
    // Are we on a notation page?
    hasNotationLayout() { return (this.$route.meta.notation ?? false) },
    // Are we on the "adventices" page?
    hasAdventiceLayout() { return (this.$route.meta.adventice ?? false) },
    // Are we on the "dashboard" page?
    hasDashboardLayout() { return (this.$route.meta.dashboard ?? false) },
    // Get the page title from the current route's meta
    pageTitle() { return this.$route.meta.title },
  },

  mounted() {
    console.log('app mounted')
    // Listen to pub/sub bus events
    this.emitter.on('open-menu-panel', this.openMenuPanel)
    this.emitter.on('toggle-menu-panel', () => this.togglePanel('menu'))
    this.emitter.on('toggle-tool-panel', () => this.togglePanel('tool'))
    this.emitter.on('toggle-user-panel', () => this.togglePanel('user'))
    this.emitter.on('close-overlay-and-panels', this.closeOverlayAndPanels)
    this.emitter.on('close-overlay', this.closeOverlay)
    this.emitter.on('close-dropdown', this.closeDropdown)
    this.emitter.on('open-dropdown', this.openDropdown)
    this.emitter.on('open-loader', this.openLoader)
    this.emitter.on('close-loader', this.closeLoader)

    window.addEventListener('online', this.onLineChange)
    window.addEventListener('offline', this.onLineChange)
    this.currentVersion = process.env.VERSION

    if (
      this.$store.state.auth.user.loggedIn
      && this.$store.state.app.forceRefresh === true
    ) {
      this.emitter.emit('open-loader')
      this.$store.dispatch('app/setReferentials').then(() => {
        this.$store.dispatch('app/setData').then(() => {
          this.emitter.emit('close-loader')
          window.location.reload(true)
        }, () => {
          this.emitter.emit('close-loader')
        })
      })
    }
  },
  updated() {
    if (this.$route.meta.title) {
      document.title = `OSS | ${this.$route.meta.title}`
    }
  },
  created() {
    if (this.isOnline) {
      if (this.$store.state.auth.user.loggedIn) {
        this.fetchService
          .get(`utilisateur/${this.$store.state.auth.user.data.id}`, {})
        this.checkToken()
      }
    }
  },
  beforeUnmount() {
    clearInterval(this.checkTokenInterval)
  },
  methods: {
    // PANELS AND OVERLAY
    closeDropdown() {
      this.isMenuDropdownOpen = false
    },
    openDropdown() {
      this.isMenuDropdownOpen = true
    },
    closeOverlay() {
      this.hasOverlay = false
    },
    closePanels() {
      this.isPanelOpen.menu = false
      this.isPanelOpen.tool = false
      this.isPanelOpen.user = false
    },
    openMenuPanel() {
      // Close other panels
      this.closePanels()
      // Open the menu panel
      this.isPanelOpen.menu = true
      // Show the overlay
      this.hasOverlay = true
    },
    togglePanel(panel) {
      // Toggle the panel
      this.isPanelOpen[panel] = !this.isPanelOpen[panel]

      if (this.isPanelOpen[panel]) {
        // The panel is now opened
        // Show the overlay
        this.hasOverlay = true
        // Close other panels
        this.closePanels()
        // Also close all dropdown sub-menus
        this.emitter.emit('close-dropdown', { dropdownLabel: 'ALL' })
        // Re-open curent panel
        this.isPanelOpen[panel] = true
      } else {
        // The panel is now closed
        // Hide the overlay
        this.hasOverlay = false
        // Also close all dropdown sub-menus
        this.emitter.emit('close-dropdown', { dropdownLabel: 'ALL' })
      }
    },
    closeOverlayAndPanels() {
      this.closeOverlay()
      // Close panels
      this.closePanels()
      // Also close all dropdown sub-menus
      this.emitter.emit('close-dropdown', { dropdownLabel: 'ALL' })
      // Remove dropdown class
      this.isMenuDropdownOpen = false
    },
    // ANIMATED LOADER
    openLoader() {
      this.hasLoader = true
    },
    closeLoader() {
      this.hasLoader = false
    },
    // SCROLL SNIFFING
    onScroll(event) {
      this.hasScrolledDown = event.target.scrollTop > 5
    },
    checkToken() {
      // check if token has expired
      this.checkTokenInterval = setInterval(() => {
        console.log('checkTokenInterval')
        if (this.$store.state.auth.user.loggedIn && this.isOnline) {
          const now = new Date()
          if (
            this.$store.state.auth.user.expireAt - now.getTime() <= 0
            && this.$store.state.auth.user.refreshed < 3
          ) {
            this.refreshToken(true)
          } else if (
            this.$store.state.auth.user.expireAt - now.getTime() <= 0
            && this.$store.state.auth.user.refreshed >= 3
          ) {
            this.displayRefreshModal = true
            if (this.refreshModalTimeOut === null) {
              this.refreshModalTimeOut = setTimeout(() => {
                this.logout()
              }, 20000)
            }
          }
        }
      }, 300000)
    },
    refreshToken(auto = false) {
      console.log('refreshToken', auto)
      this.$store.dispatch('auth/refreshToken', auto).then()
      this.displayRefreshModal = false
    },
    logout() {
      this.$router.push({ name: 'login', query: { logout: true } })
      this.displayRefreshModal = false
    },
    onLineChange() {
      this.isOnline = navigator.onLine
    },
    refreshVersion() {
      this.refreshApp()
    },
  },
}
</script>

<style lang="scss">
/* /!\ DON'T REMOVE THIS MAGIC COMMENT! /!\ */

// For an unknown reason, Vue needs at least
// one non-scoped component style tag.
</style>

<style lang="scss" scoped>
/* APP */

.app-wrapper {}

.site-wrapper {
  height: 100vh;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: $header-height-mobile auto;
  grid-template-areas:
    "header"
    "main";

  @include bp($breakpoint-sidebar-compact) {
    grid-template-columns: $sidebar-width-tablet auto;
    grid-template-rows: $header-height-tablet auto;
    grid-template-areas:
      "header header"
      "aside main";
  }

  @include bp($breakpoint-sidebar-full) {
    grid-template-columns: $sidebar-width-desktop auto;
    grid-template-rows: $header-height-desktop auto;
    grid-template-areas:
      "aside header"
      "aside main";
  }

  background-color: $color-gray-outside-content;

  /*.app-wrapper*/
  .has-only-content-layout & {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: "main";
  }
}

.site-header {
  grid-area: header;

  background-color: white;
  @include shadow(1);
  z-index: $z-index-sticky;

  @include bp($breakpoint-sidebar-compact) {
    background-color: $color-gray-outside-content;
    @include shadow(0);
  }
}

.site-content {
  grid-area: main;
  background-color: $color-gray-outside-content;
  overflow-y: auto;

  .has-no-scroll-layout & {
    overflow-y: hidden;
  }

  .has-notation-layout & {
    position: relative;
    overflow-x: hidden; // Notation prev/next buttons

    @include bp('lg') {
      position: initial;
    }
  }

  .has-adventice-layout & {
    display: flex;
    align-items: center;
  }

  @include bp($breakpoint-content-with-padding) {
    padding: $gutter-half;
  }

  @include bp($breakpoint-sidebar-compact) {
    padding: $gutter;
    padding-top: 0;
  }

  @include bp($breakpoint-sidebar-full) {
    padding: $gutter-and-half;
    padding-top: 0;
  }

  /*.app-wrapper*/
  .is-panel-menu-open & {
    overflow-y: hidden;
  }

  /*.app-wrapper*/
  .has-only-content-layout & {
    @include bp("sm") {
      align-self: center;
    }
  }
}

.site-main-content {
  .has-default-layout & {
    background-color: white;
    border-radius: $border-radius-base;
    @include shadow(1);
  }

  .has-dashboard-layout & {
    background-color: transparent;
    border-radius: 0;
    @include shadow(0);
  }

  .has-adventice-layout & {
    max-width: 64rem;
    @include h-margin(auto);
    flex-grow: 1;
  }

  // NO SCROLL LAYOUT
  .has-no-scroll-layout & {
    height: 100%;
    overflow: hidden;

    :deep(.view) {
      height: 100%;
      display: flex;
      flex-direction: column;

      .view-body {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .section--scroll {
          overflow: hidden;
          // padding-top: 0;
          // @include v-padding(0);

          .container {
            overflow: hidden;
            height: 100%;
            // @include h-margin(0);
          }
        }
      }
    }
  }

  /*.app-wrapper*/
  .has-only-content-layout & {
    max-width: 64rem;
    @include h-margin(auto);
  }

  .has-notation-layout & {
    height: 100%;
  }
}

// ASIDE

.site-aside {
  position: fixed;
  width: $sidebar-width-mobile;
  top: $header-height-mobile;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  // We use margin for in/out animation, instead of 3d transforms
  // This fix a bug with fixed position not relative to the viewport
  // See: https://stackoverflow.com/questions/21091958/css-fixed-child-element-positions-relative-to-parent-element-not-to-the-viewpo
  margin-left: -$sidebar-width-mobile;
  background-color: white;
  @include shadow(1);
  z-index: $z-index-panel;
  transition: all 0.5s $ease-in-out;

  /*.app-wrapper*/
  .is-panel-menu-open & {
    margin-left: 0;
  }

  // Remove mobile vertical scrollbar when sub-menus dropdown are opened
  /*.app-wrapper*/
  .is-menu-dropdown-open & {
    overflow: hidden;
  }

  @include bp($breakpoint-sidebar-compact) {
    top: $header-height-tablet;
    width: $sidebar-width-tablet;
    transition: width 0.5s $ease-in-out;
    margin-left: 0;

    /*.app-wrapper*/
    .is-panel-menu-open & {
      width: $sidebar-width-mobile;
    }
  }

  @include bp($breakpoint-sidebar-full) {
    position: initial;
    height: 100%;
    width: $sidebar-width-desktop;
    left: 0;
    top: 0;
    padding-left: 0;
    overflow-x: visible;
    @include shadow(0);

    /*.app-wrapper*/
    .is-panel-menu-open & {
      width: $sidebar-width-desktop;
    }

    .is-menu-dropdown-open & {
      overflow: visible;
    }
  }
}

// .site-footer {
//     background-color: lightgray;
// }
</style>
