import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import styleguide from './styleguide'
import authentification from './authentication'
import users from './users'
import admin from './admin'
import entity from './entity'
import logs from './logs'
import products from './products'
import protocols from './protocols'
import essais from './essais'
import parcels from './parcels'
import notations from './notations'
import HelperService from '../services/helper.service'

const baseRoutes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Accueil',
      public: false,
      offline: true,
      dashboard: true,
    },
    component: () => import(
      '@/views/dashboard/DashboardView.vue'
    ),
  },
  {
    path: '/acces-interdit',
    name: 'forbidden',
    meta: {
      title: '<span>Erreur 403</span> - Accès interdit',
      public: false,
      offline: true,
    },
    component: () => import(
      '@/views/app/ForbiddenView.vue'
    ),
  },
  {
    path: '/acces-impossible',
    name: 'offline',
    meta: {
      title: 'Accès impossible',
      public: false,
      offline: true,
    },
    component: () => import(
      '@/views/app/OfflineView.vue'
    ),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    meta:
    {
      title: '<span>Erreur 404</span> - Page introuvable',
      public: true,
      offline: true,
    },
    component: () => import('@/views/app/NotFoundView.vue'),
  },
]

const routes = baseRoutes.concat(authentification, users, styleguide, admin,
  entity, logs, products, protocols, essais, parcels, notations)
const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line no-param-reassign
  if (!to.meta.public && !store.state.auth.user.loggedIn) {
    next({ name: 'login' })
  } else if (!navigator.onLine && !to.meta.offline) {
    next({ name: 'offline' })
  } else if (
    to.meta.permissions !== undefined
    && !HelperService.userHasPermission(to.meta.permissions, 'OR')) {
    next({ name: 'forbidden' })
  } else {
    next()
  }
})

export default router
