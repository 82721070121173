<template>
  <li
    class="menu-item"
    :class="{
      'menu-item--dropdown-toggle': itemData.isDropdown,
      'menu-item--dropdown-open': itemData.isDropdown && isDropdownOpen,
      'menu-item--back': itemData.isBack,
    }"
    ref="root"
  >
    <component
      :is="
        itemData.isDropdown || itemData.isBack || !itemData.route
          ? 'button'
          : 'router-link'
      "
      :type="
        itemData.isDropdown || itemData.isBack || !itemData.route
          ? 'button'
          : null
      "
      :to="itemData.route || null"
      class="menu-item-link"
      @click="onMenuItemClick"
      v-ripple
    >
      <SvgIcon
        v-if="itemData.iconName"
        :name="itemData.iconName"
        :class="itemData.iconClass"
        size="sm"
      />
      <span>{{ itemData.label }}</span>
      <SvgIcon
        v-if="itemData.isDropdown"
        class="dropdown-caret"
        name="chevron-right"
      />
    </component>

    <slot></slot>
  </li>
</template>

<script>
/**
 * @deprecated
 */

import { ref } from 'vue'
import SvgIcon from '@/components/base/SvgIcon.vue'

export default {
  name: 'MenuItem',

  components: {
    SvgIcon,
  },

  props: {
    itemData: Object,
  },

  setup() {
    const root = ref(null)

    return {
      root,
    }
  },

  data() {
    return {
      isDropdownOpen: false,
    }
  },

  mounted() {
    // Listen to pub/sub bus events
    this.emitter.on('close-dropdown', this.closeDropdown)

    // Debug zone: window resize and init
    window.addEventListener('resize', this.computeDropdownY)

    // Compute desktop navbar dropdowns vertical positions
    this.emitter.on('navbar-scroll', this.computeDropdownY)
    // Ugly initial positioning
    window.setTimeout(() => {
      this.computeDropdownY()
    }, 3000)
  },

  methods: {
    onMenuItemClick() {
      if (this.itemData.isBack) {
        // The menu item is a dropdown back button
        // So we close the corresponding sub-menu
        this.emitter.emit('close-dropdown', {
          dropdownLabel: this.itemData.label,
        })
      } else if (this.itemData.isDropdown) {
        // The item is a dropdown toggle button
        // So we toggle the dropdown
        if (this.isDropdownOpen) {
          // Close the current dropdown
          this.isDropdownOpen = false
          this.emitter.emit('close-dropdown', {
            dropdownLabel: this.itemData.label,
          })
          if (window.innerWidth < 1200) {
            // On mobile, only close the overlay
            this.emitter.emit('close-overlay')
          } else {
            // On desktop, also (pseudo-)close the menu panel
            this.emitter.emit('close-overlay-and-panels')
          }
        } else {
          // Set desktop dropdown vertical position
          this.computeDropdownY()
          // Close all dropdowns
          this.emitter.emit('close-dropdown', { dropdownLabel: 'ALL' })
          // Then open the current one
          this.isDropdownOpen = true
          // Also open the tablet main menu
          // (in case it's still in compact mode)
          this.emitter.emit('open-menu-panel')
          this.emitter.emit('open-dropdown', {
            dropdownLabel: this.itemData.label,
          })
        }
      } else {
        // The item is a simple router link
        // Close all panels and hide overlay
        this.emitter.emit('close-overlay-and-panels')
      }
    },

    closeDropdown(e) {
      if (
        (e.dropdownLabel === this.itemData.label
          || e.dropdownLabel === 'ALL')
        && this.itemData.isDropdown
        && this.isDropdownOpen
      ) {
        // console.log('closing dropdown', e.dropdownLabel);
        this.isDropdownOpen = false
      }
    },

    // Ugly dropdowns vertical position routine (desktop only)
    computeDropdownY() {
      const $el = this.root

      if ($el && $el.classList.contains('menu-item--dropdown-toggle')) {
        const $dropdown = $el.querySelector('.navbar-menu--dropdown')
        const dropdownHeight = $dropdown.offsetHeight
        $dropdown.removeAttribute('style')

        if (window.innerWidth >= 1200) {
          const dropdownToggleY = Math.round($el.getBoundingClientRect().top)

          // Check if the dropdown has enough vertical space
          if (dropdownHeight > window.innerHeight) {
            // Dropdown is higher the viewport, use all available space
            $dropdown.style.top = '0'
            $dropdown.style.bottom = '0'
          } else if (dropdownToggleY + dropdownHeight <= window.innerHeight) {
            // Dropdown can fit downside, we only prevent Y<0 (upside overflow)
            $dropdown.style.top = `${Math.max(dropdownToggleY, 0)}px`
          } else {
            // Dropdown can fit upside, stick it to bottom
            $dropdown.style.top = 'auto'
            $dropdown.style.bottom = '0'
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/* MENU ITEM */

.menu-item {
  @include v-padding(
    ($navbar-menu-item-height-mobile - $touch-target-size-base) / 2
  );
  @include h-padding(
    ($sidebar-width-tablet - $icon-size-base - $gutter-half) / 2
  );
  border-bottom: 1px solid $color-gray-lighter;

  .navbar-menu & {
    &:first-child {
      border-top: 1px solid $color-gray-lighter;
    }

    @include bp($breakpoint-sidebar-full) {
      border: none;
      @include v-padding($gutter-eighth);

      &:first-child {
        border: none;
      }
    }
  }

  .topbar-menu-nav--alt & {
    border-color: white;
  }

  .topbar-menu & {
    &:last-child {
      border-bottom: none;
    }
  }

  .tool-dropdown-nav & {
    @include v-padding($gutter-eighth);
    border-bottom: none;

    &:first-child {
      margin-top: $gutter-quarter;
    }
    &:last-child {
      margin-bottom: $gutter-quarter;
    }
  }
}

// MENU ITEM LINK

.menu-item-link {
  @include reset-button();
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  min-height: $touch-target-size-base;
  @include h-padding($gutter-quarter);
  border-radius: $border-radius-base;
  color: $color-primary;
  transition: all 0.25s;

  @include bp($breakpoint-sidebar-compact) {
    max-height: $touch-target-size-base;
  }

  @include bp($breakpoint-sidebar-full) {
    max-height: none;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 0.5rem;
    height: 100%;
    top: 0;
    left: -1rem;
    background-color: $color-primary-light;
    border-top-right-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
    transition: transform 0.25s;
    transform: translateX(-100%);
  }

  &.router-link-exact-active {
    color: $color-primary-dark;
    background-color: $color-gray-lightest;

    &::after {
      transform: translateX(0);
    }
  }

  .tool-dropdown-nav & {
    color: $body-color-base;
  }

  > span {
    flex-grow: 1;
    // font-size: $font-size-small;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    text-align: left;
    transition: all 0.25s;

    .navbar-menu & {
      @include bp($breakpoint-sidebar-compact) {
        opacity: 0;

        /*.app-wrapper*/
        .is-panel-menu-open & {
          opacity: 1;
        }
      }

      @include bp($breakpoint-sidebar-full) {
        opacity: 1;
      }
    }

    .topbar-menu-nav--alt & {
      font-weight: normal;
      text-transform: uppercase;
      color: $color-gray-darker;
    }

    .tool-dropdown-nav & {
      font-weight: $font-weight-semibold;
    }
  }

  > .icon {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-primary-dark;

    &:first-child {
      margin-right: $gutter-half;
    }

    &:last-child {
      margin-left: $gutter-half;

      @include bp($breakpoint-sidebar-compact) {
        position: absolute;
        color: $color-gray-light;

        .is-panel-menu-open & {
          position: initial;
          color: $color-primary-dark;
        }
      }

      @include bp($breakpoint-sidebar-full) {
        position: initial;
        color: $color-primary-dark;
      }
    }

    .topbar-menu-nav--alt & {
      @include size($gutter);

      color: white;
      background-color: $color-primary-light;
      border-radius: $border-radius-base;
    }

    .tool-dropdown-nav & {
      color: $body-color-base;
    }
  }

  @include hocus() {
    background-color: $color-gray-lightest;

    .topbar-menu-nav--alt & {
      background-color: $color-gray-lighter;

      > span {
        color: black;
      }
    }
  }
}

// MENU IIEM DROPDOWN TOGGLE

.menu-item--dropdown-toggle {
  &.menu-item--dropdown-has-active-child {
    > .menu-item-link {
      background-color: $color-gray-lightest;

      &::after {
        transform: translateX(0);
      }
    }
  }
}

// MENU ITEM BACK

.menu-item--back {
  background-color: $color-primary;

  @include bp($breakpoint-sidebar-full) {
    display: none;
  }

  :deep(.menu-item-link) {
    color: white;

    @include hocus() {
      background-color: $color-primary-dark;
    }

    .icon {
      color: white;
    }
  }
}

.navbar-menu--dropdown {
  .menu-item {
    @include bp($breakpoint-sidebar-full) {
      &:not(:last-child) {
        border-bottom: 1px solid $color-gray-lighter;
      }
    }
  }
}

// NavigationDropdown menu items

.navigation-dropdown .menu-item {
  .menu-item-link span {
    color: $body-color-base;
  }
}
/* .navigation-dropdown .menu li {
  &:nth-child(1) {
    .icon {
      color: $color-navdropdown-1;
    }
  }
  &:nth-child(2) {
    .icon {
      color: $color-navdropdown-2;
    }
  }
  &:nth-child(3) {
    .icon {
      color: $color-navdropdown-3;
    }
  }
  &:nth-child(4) {
    .icon {
      color: $color-navdropdown-4;
    }
  }
  &:nth-child(5) {
    .icon {
      color: $color-navdropdown-5;
    }
  }
  &:nth-child(6) {
    .icon {
      color: $color-navdropdown-6;
    }
  }
} */

.menu-item--protocol-details .icon {
  color: $color-protocol-details;
}
.menu-item--protocol-versions .icon {
  color: $color-protocol-versions;
}
.menu-item--protocol-sharing .icon {
  color: $color-protocol-sharing;
}
.menu-item--protocol-files .icon {
  color: $color-protocol-files;
}
.menu-item--protocol-grouping .icon {
  color: $color-protocol-grouping;
}
</style>
