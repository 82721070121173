export default [
  {
    path: '/connexion',
    name: 'login',
    meta: {
      public: true,
      noHeader: true,
    },
    component: () => import(
      '@/views/authentication/LogInView.vue'
    ),
  },
  {
    path: '/mot-de-passe-oublie',
    name: 'passwordForgottenStep1',
    meta: {
      public: true,
      noHeader: true,
    },
    component: () => import(
      '@/views/authentication/PasswordForgottenStep1View.vue'
    ),
  },
  {
    path: '/mot-de-passe-oublie/:token',
    name: 'passwordForgottenStep2',
    meta: {
      public: true,
      noHeader: true,
    },
    component: () => import(
      '@/views/authentication/PasswordForgottenStep2View.vue'
    ),
  },
  {
    path: '/identifiant-oublie',
    name: 'usernameForgotten',
    meta: {
      public: true,
      noHeader: true,
    },
    component: () => import(
      '@/views/authentication/UsernameForgottenView.vue'
    ),
  },
]
