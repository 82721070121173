<template>
  <teleport to="#modal">
    <div
      class="modal-overlay"
      :class="{ 'modal-overlay--active': active }"
      @click="$emit('modal-close')"
    />

    <div
      class="modal-container"
      :class="{
        'modal-container--active': active,
        'modal-container--no-scroll': noScroll,
      }"
    >
      <dialog
        class="modal"
        :class="{
          'modal--no-scroll': noScroll,
          'modal--no-padding': noPadding,
          'modal--scroll': !noScroll,
          'modal--sm': size === 'sm',
          'modal--lg': size === 'lg',
          'modal--xl': size === 'xl',
          'modal--full': size === 'full',
        }"
        role="dialog"
        aria-labelledby="modal-title"
        :aria-hidden="!active"
        :open="active"
      >
        <component
          class="modal-wrapper"
          :is="containForm ? 'form' : 'div'"
          @submit.prevent="onSubmit"
        >
          <div class="modal__header">
            <div class="modal__title h3" id="modal-title">
              <slot name="modal-title" :data="data">
                <div v-html="title" />
              </slot>
            </div>

            <Btn
              class="modal__btn-close"
              icon="times-circle"
              round
              grow
              @click="$emit('modal-close')"
            >
              <span class="sr-only">Close</span>
            </Btn>
          </div>

          <div class="modal__body">
            <slot name="modal-body" :data="data" />
          </div>

          <div class="modal__footer">
            <slot name="modal-footer" :data="data" />
          </div>
        </component>
      </dialog>
    </div>
  </teleport>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import FormService from '@/services/form.service'

export default {
  name: 'Modal',

  components: {
    Btn,
  },

  props: {
    type: {
      type: String,
      default: 'global',
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    active: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
    },
    data: {
      type: Object,
    },
    containForm: {
      type: Boolean,
      default: false,
    },
    modalValidationSchema: {
      type: Object,
      default: null,
    },
    modalAction: {
      type: String,
    },
    key: {
      type: String,
    },
  },

  emits: [
    'modal-close',
    'modal-form-submitted',
    'modal-form-errors',
    'modal-form-values',
  ],

  data(props) {
    let FS
    if (props.containForm) {
      FS = new FormService()
      FS.setEmitter(this.emitter)

      const { errors, currentFormValues, isSubmitting } = FS.initFrom(
        props.modalValidationSchema,
      )
      const onSubmit = FS.handleSubmit((values) => {
        this.$emit('modal-form-submitted', values)
      })

      return {
        FS,
        schema: {},
        errors,
        currentFormValues,
        isSubmitting,
        onSubmit,
      }
    }
    return {
      FS,
      schema: {},
      onSubmit: null,
      errors: null,
      coumputedValues: null,
    }
  },
  watch: {
    active() {
      if (!this.active) {
        if (this.containForm) {
          this.FS.resetForm()
        }
      } else if (this.containForm) {
        this.setSchema()
      }
    },
    errors() {
      this.$emit('modal-form-errors', this.errors)
    },
    data() {
      if (this.containForm) {
        this.setSchema()
      }
    },
  },
  mounted() {
    if (this.containForm) {
      this.$emit('modal-form-values', this.currentFormValues)
    }
  },
  unmounted() {
    if (this.containForm) {
      this.FS = undefined
    }
  },

  methods: {
    setSchema() {
      if (this.data && 'id' in this.data) {
        if (this.modalAction !== 'delete') {
          this.schema = this.FS.populateShema(this.data)
          this.FS.setFormValues(this.schema)
        }
      }
    },
    noop() {
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
/* MODAL */

////////////////////////////////////////////////////////////////////////////////
// MIXINS

@mixin modal-height() {
  max-height: calc(100vh - #{$gutter});

  @include bp("xs") {
    max-height: calc(100vh - #{$gutter-double});
  }

  @include bp("sm") {
    max-height: 75vh;
  }
}

@mixin modal() {
  z-index: $z-index-modal;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  opacity: 0;
  width: 100%;
  max-width: calc(100vw - #{$gutter});
  border: none; // Reset default style
  padding: 0; // Reset default style
  margin: 0; // Reset "auto" default style
  overflow: hidden;
  transition: all 0.25s ease-in-out;

  &.modal--scroll {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, calc(-50% - 10vh), 0);
  }

  &.modal--no-scroll {
    position: static !important;
    transform: translate3d(0, -10vh, 0);
    @include h-margin(auto);
  }

  @include bp("sm") {
    max-width: 40rem;

    &:not(.modal--sm) {
      max-width: 64rem;
    }
  }

  @include bp("md") {
    &.modal--lg {
      max-width: 96rem;
    }
    &.modal--xl {
      max-width: 115rem;
    }
  }

  &.modal--full {
    max-width: none;

    &.modal--scroll {
      max-height: none;
      width: calc(100vw - #{$gutter});
      height: calc(100vh - #{$gutter});
    }

    &.modal--no-scroll {
      max-height: none;
      width: 100%;
    }
  }

  // Opened modal
  /*body*/

  /*.has-modal*/
  &[open] {
    &.modal--scroll {
      transform: translate3d(-50%, -50%, 0);
    }
    &.modal--no-scroll {
      transform: translate3d(0, 0, 0);
    }

    opacity: 1;
    pointer-events: initial;
    @include shadow(5);
  }
}

////////////////////////////////////////////////////////////////////////////////
// CLASSES

@if $generate_classes and $generate-modals-classes {
  // CONTAINER

  .modal-container {
    z-index: $z-index-modal;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: $gutter-half;
    overflow: auto;
    pointer-events: none;
    opacity: 0;
    transition: all 0.25s;

    &.modal-container--active {
      opacity: 1;

      &.modal-container--no-scroll {
        // Needed for scroll event, but blocks close-on-overlay-click
        pointer-events: initial;
      }
    }
  }

  // MODAL

  .modal {
    @include modal();

    &.modal--scroll {
      @include modal-height();
    }
  }

  // Modal inner wrapper
  .modal-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .modal--scroll & {
      @include modal-height();
    }

    .modal--full & {
      max-height: none;
    }
  }

  // Modal layout

  .modal__header {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid $color-gray-lighter;
  }

  .modal__footer {
    padding: $gutter;
    // text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $gutter-half;
    flex-wrap: wrap;
  }

  .modal__body {
    @include h-padding($gutter);
    padding-top: $gutter;

    .modal--no-padding & {
      padding: 0;
    }

    position: relative;

    .modal--scroll & {
      overflow: auto;
    }

    .modal--full & {
      flex-grow: 1;
    }
  }

  // Modal title

  .modal__title {
    flex-grow: 1;
    padding: $gutter;
    // padding-bottom: $gutter-half;

    &.h3 {
      margin-bottom: 0;
    }
  }

  // Modal buttons

  .modal__btn-close {
    margin-top: 2rem;
    margin-right: 2rem;
  }
}

// MODAL OVERLAY

.modal-overlay {
  z-index: $z-index-modal - 500;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);

  opacity: 0;
  transition: opacity 0.5s, transform 0.25s;
  backdrop-filter: blur(3px);
  pointer-events: none; // Let events pass through

  &.modal-overlay--active {
    opacity: 1;
    pointer-events: initial; // Allows click on overlay for closing modal
  }
}
</style>
