/*
eslint-disable
*/
import fetchService from './fetch.service'
import helperService from './helper.service'

class NotationService {
  constructor() {
    this.emitter = {}
    this.router = {}
    this.store = {}
    this.notations = this.getFromLocalStorage('notations')
    this.stades = this.getFromLocalStorage('stades')
    this.reservationStatus = []
    this.reservations = this.getFromLocalStorage('reservations')
    this.adventices = this.getFromLocalStorage('adventices')

  }

  setStore(store) {
    this.store = store
    this.reservationStatus = this.store.state.app.reservationStatus
  }


  // Notation actions
  async bookNotation(reservation) {
    console.log('bookNotation', reservation)

    // eslint-disable-next-line no-param-reassign
    reservation = JSON.parse(JSON.stringify(reservation))
    const etat = this.reservationStatus.find((item) => item.uid === 'RESERVE')
    const dates = this.getDates()

    await this.cancelReservation(reservation, false)
    console.log('cancelReservationEND')

    fetchService.post(`essai/${reservation.essai.id}/evenement/${reservation.evenement.id}/fiche/notation/${reservation.notation.id}/reservation`,
      {
        etat_uid: etat.uid,
        date_validite: dates.validity,
        date: dates.now,
      }).then((responseReservation) => {

        console.log('post')

        // eslint-disable-next-line no-param-reassign
        reservation = { ...reservation, ...responseReservation.data }

        if (reservation.frames != undefined) {
          // eslint-disable-next-line no-param-reassign
          reservation.notation = { ...reservation.notation, ...{ frames: reservation.frames } }
        }

        // eslint-disable-next-line no-param-reassign
        delete reservation.frames
        delete reservation.essai.protocole
        delete reservation.essai.plan
        delete reservation.evenement.essai
        delete reservation.notation.evenement
        delete reservation.utilisateur.entite
        delete reservation.utilisateur.profils

        reservation.notation.frames.terrain.forEach((frame) => {
          if (frame?.microparcelle?.modalite?.protocole) {
            delete frame.microparcelle.modalite.protocole
          }
        })

        reservation.notation.frames.labo.forEach((frame) => {
          if (frame?.microparcelle?.modalite?.protocole) {
            delete frame?.microparcelle?.modalite?.protocole
          }
        })

        this.setItem('reservations', reservation)

        this.emitter.emit('notation-refresh', 'event')
      })
  }

  cancelReservation(reservation, refresh = true) {
    return new Promise((resolve) => {
      this.emitter.emit('open-loader')
      console.log('cancelReservation')

      //On supprime les reservations sur le serveur
      fetchService.get(
        `essai/${reservation.essai.id}/evenement/${reservation.evenement.id}/fiche/notation/${reservation.notation.id}/reservation`,
        { limit: 0, sort: 'id.ASC' },
      ).then((responseReservation) => {

        const promiseArray = []
        fetchService.setHandleErrors(false)
        responseReservation.data.forEach((resa) => {
          promiseArray.push(fetchService.delete(`essai/${reservation.essai.id}/evenement/${reservation.evenement.id}/fiche/notation/${reservation.notation.id}/reservation/${resa.id}`, null, null, false))
        })

        Promise.all(promiseArray).then(() => {
          //On supprime les reservations local
          let localResaToDelete = this.reservations.filter((item) => item.notation.id == reservation.notation.id)
          localResaToDelete.forEach((localResa) => {
            this.cleanStorage(localResa)
          })

          console.log('deleteend')
        }).catch(() => {
          this.emitter.emit('close-loader')
          if (refresh) {
            this.emitter.emit('notation-refresh', 'cancel')
          }
          resolve()
        }).finally(() => {

          //On supprime les reservations local
          let localResaToDelete = this.reservations.filter((item) => item.notation.id == reservation.notation.id)
          localResaToDelete.forEach((localResa) => {
            this.cleanStorage(localResa)
          })

          this.emitter.emit('close-loader')
          if (refresh) {
            this.emitter.emit('notation-refresh', 'cancel')
          }
          resolve()
        })
      })

    })
  }

  startNotation(reservationId) {
    console.log('startNotation')
    const reservation = this.getItem('reservations', reservationId)
    const etat = this.reservationStatus.find((item) => item.uid === 'ENCOURS')
    const dates = this.getDates()
    reservation.etat = etat
    this.setItem('reservations', reservation)

    if (navigator.onLine) {
      fetchService.put(
        `essai/${reservation.essai.id}/evenement/${reservation.evenement.id}/fiche/notation/${reservation.notation.id}/reservation/${reservation.id}`,
        {
          etat_uid: etat.uid,
          date_validite: dates.validity,
          date: dates.now,
        },
      )
        .then(() => {
          this.router.push({
            name: 'adventice',
            params: { id: reservation.id },
          })
        })
    } else {
      this.router.push({
        name: 'adventice',
        params: { id: reservation.id },
      })
    }
  }

  continueNotation(reservationId) {
    console.log('continueNotation')
    const reservation = this.getItem('reservations', reservationId)
    const { notation } = reservation

    if (reservation.frames) {
      // eslint-disable-next-line no-param-reassign
      reservation.notation = { ...reservation.notation, ...{ frames: reservation.frames } }

      // eslint-disable-next-line no-param-reassign
      delete reservation.frames

      this.setItem('reservations', reservation)
    }

    if (notation.frames !== undefined) {
      const type = 'terrain'
      this.router.push({
        name: 'notation',
        params: {
          id: reservation.id,
          localisation: type,
          etape: notation.frames.terrainIndex === 0
            ? notation.frames.terrainIndex + 1
            : notation.frames.terrainIndex,
          notationProps: JSON.stringify(notation),
        },
      })
    } else {
      this.router.push({
        name: 'adventice',
        params: { id: reservation.id },
      })
    }
  }

  endNotation(reservation, force = false) {
    console.log('endNotation', reservation)
    const etat = this.reservationStatus.find((item) => item.uid === 'ATTENTESYNCHRO')

    if (!force) {
      // eslint-disable-next-line no-param-reassign
      reservation.etat = etat
      this.setItem('reservations', reservation)
    }

    if (navigator.onLine) {
      const dates = this.getDates()

      if (!force) {
        console.log('endNotation startsynchro force', reservation)
        this.synchronizeNotation(reservation.id).then(() => {
          fetchService.patch(
            `essai/${reservation.essai.id}/evenement/${reservation.evenement.id}/fiche/notation/${reservation.notation.id}/reservation/${reservation.id}/cloture`,
          ).then((responseReservation) => {

            const returnReservation = responseReservation.data
            // eslint-disable-next-line no-param-reassign
            reservation.etat = returnReservation.etat

            if (responseReservation.data.etat.uid === 'REALISE') {
              fetchService
                .put(`essai/${reservation.essai.id}/evenement/${reservation.evenement.id}`, {
                  date_realisation: dates.now,
                })
                .then(() => {
                  console.log('endNotation endsynchro REALISE', reservation)
                  this.cleanStorage(reservation)
                  console.log('reservation', reservation)
                  this.router.push({ name: 'essaiExperimentalTaskNotationState', params: { id: reservation.essai.id, tid: reservation.evenement.id } })
                })
            } else {
              console.log('endNotation endsynchro not REALISE', reservation)
              this.cleanStorage(reservation)
              //this.emitter.emit('notation-refresh', 'event')
              console.log('reservation', reservation)
              this.router.push({ name: 'essaiExperimentalTaskNotationState', params: { id: reservation.essai.id, tid: reservation.evenement.id } })
            }
          })
        })
      } else {
        const etatRealise = this.reservationStatus.find((item) => item.uid === 'REALISE')
        console.log('endNotation startsynchro', reservation)
        fetchService.put(
          `essai/${reservation.essai.id}/evenement/${reservation.evenement.id}/fiche/notation/${reservation.notation.id}/reservation/${reservation.id}`,
          {
            etat_uid: etatRealise.uid,
            date_validite: dates.validity,
            date: dates.now,
          },
        )
          .then(() => {
            fetchService
              .put(`essai/${reservation.essai.id}/evenement/${reservation.evenement.id}`, {
                date_realisation: dates.now,
              })
              .then(() => {
                this.cleanStorage(reservation)
                //this.emitter.emit('notation-refresh', 'event')
                console.log('reservation', reservation)
                this.router.push({ name: 'essaiExperimentalTaskNotationState', params: { id: reservation.essai.id, tid: reservation.evenement.id } })
              })
          })
      }
    } else {
      this.router.push({ name: 'home' })
    }
  }

  synchronizeNotation(reservationId = null) {
    try {
      console.log('synchronizeNotation')
      this.emitter.emit('progress-modal-open')

      const reservation = this.getItem('reservations', reservationId)
      const etat = this.reservationStatus.find((item) => item.uid === 'ERREURSYNCHRO')

      reservation.etat = etat
      this.setItem('reservations', reservation)

      return new Promise((resolve) => {
        if (navigator.onLine) {
          const currenteNotes = this.getNotes(reservationId)
          const notes = helperService.cloneObject(currenteNotes)

          this.emitter.emit('progress-modal-data', notes)

          if (process.env.VUE_APP_MODE !== 'dev') {
            fetchService.post('utilitaire/mail/erreur', { erreur: { status: 'save', reservationId, notationId: reservation.notation.id, notes } })
          }

          const formattedValues = []
          if (notes) {
            notes.forEach((note) => {
              // eslint-disable-next-line
              delete note.id
              // eslint-disable-next-line
              note.nonapplicable = Boolean(note.nonapplicable)
              formattedValues.push(note)
            })
          }

          fetchService
            .delete(`essai/${reservation.essai.id}/evenement/${reservation.evenement.id}/fiche/notation/${reservation.notation.id}/variable_etudiee/note`)
            .then(async () => {

              var size = 8;
              var dataLength = formattedValues.length
              var totalIteration = Math.ceil(dataLength / size);

              var iteration = 0
              for (var i = 0; i < dataLength; i += size) {
                const tmpArray = formattedValues.slice(i, i + size);

                await fetchService.post(`essai/${reservation.essai.id}/evenement/${reservation.evenement.id}/fiche/notation/${reservation.notation.id}/variable_etudiee/note`, tmpArray).then(
                  () => {
                    iteration += 1
                    this.emitter.emit('progress-modal-progress', { current: iteration, total: totalIteration })
                  },
                  (error) => {
                    iteration += 1
                    this.emitter.emit('alert', {
                      type: 'error',
                      content: 'Une erreur de  synchronisation est survenue.',
                    })
                    if (process.env.VUE_APP_MODE !== 'dev') {
                      fetchService.post('utilitaire/mail/erreur', { erreur: { status: 'ko', error, reservationId, notationId: reservation.notation.id } })
                    }
                  }
                )
              }

              fetchService.get(
                `essai/${reservation.essai.id}/evenement/${reservation.evenement.id}/fiche/notation/${reservation.notation.id}/reservation`,
                { limit: 0 },
              )
                .then((responseReservation) => {
                  const returnReservation = responseReservation.data.pop()
                  reservation.etat = returnReservation.etat
                  this.setItem('reservations', reservation)
                  this.emitter.emit('alert', {
                    type: 'success',
                    content: 'Les données ont bien été synchronisées.',
                  })
                  this.emitter.emit('progress-modal-close')
                  resolve(reservation)
                }, () => {
                  resolve(reservation)
                })

            },
              (error) => {
                this.emitter.emit('alert', {
                  type: 'error',
                  content: 'Une erreur de  synchronisation est survenue.',
                })

                if (process.env.VUE_APP_MODE !== 'dev') {
                  fetchService.post('utilitaire/mail/erreur', { erreur: { reservationId, notationId: reservation.notation.id, error } })
                }
              })
        }
      }).finally()
    } catch (e) {
      console.log('synchroerror', e)
      if (process.env.VUE_APP_MODE !== 'dev') {
        fetchService.post('utilitaire/mail/erreur', { erreur: { reservationId, notationId: reservation.notation.id, error: e } })
      }
    }
  }

  cleanStorage(reservation) {
    this.removeItem('adventices', reservation.notation.id)
    // this.removeItem('notations', reservation.notation.id)
    this.removeItem('reservations', reservation.id)
    this.emitter.emit('close-loader')
  }

  // LOCALSTORAGE METHODS
  // eslint-disable-next-line class-methods-use-this
  async setCollectionFromDb(type, endpoint, reservationId = null) {
    const datas = await fetchService.get(endpoint)
    const result = await datas.data
    if (!['variables', 'essai'].includes(type)) {
      this[type] = result
      this.setLocalStorage(type)
    }

    const reservation = this.getItem('reservations', reservationId)
    const { notation } = reservation

    if (result.length > 0 || 'id' in result) {
      if (reservationId !== null) {
        let ep; let cibles; let items; let rval; let
          rorg
        switch (type) {
          case 'variables':
            ep = endpoint.replace('?limit=0', '')
            items = await Promise.all(
              result.map(async (r) => {
                rval = await fetchService.get(`${ep}/${r.id}/valeur?limit=0`)
                // eslint-disable-next-line no-param-reassign
                r.valeurs = await rval.data
                rorg = await fetchService.get(`${ep}/${r.id}/niveau_saisie?sort=ordre.ASC&limit=0`)
                // eslint-disable-next-line no-param-reassign
                r.organes = await rorg.data
                return r
              }),
            )

            // eslint-disable-next-line no-param-reassign
            notation[`${type}_etudiees`] = items

            cibles = []
            cibles = items.map((item) => (item.cible)).filter((item) => item !== null)
            cibles = cibles.filter(
              (value, index, self) => self.map((x) => x.id).indexOf(value.id) === index,
            )

            // eslint-disable-next-line no-param-reassign
            notation.cibles = cibles.filter((cible) => 'id' in cible)

            this.setItem('reservation', reservation)
            break
          case 'essai':
            // eslint-disable-next-line no-param-reassign
            notation.essai = { id: result.id, nom: result.nom }

            this.setItem('reservation', reservation)
            break
          default: break
        }
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setItem(type, item) {
    const index = this[type].find((n) => item.id === n.id)
    if (index) {
      this[type].forEach((d, i) => {
        if (index.id === d.id) {
          this[type].splice(i, 1)
        }
      })
    }
    this[type].push(item)

    this.setLocalStorage(type)
  }

  setAdventices(collection) {
    collection.forEach((item) => {
      const index = this.adventices.find(
        (n) => item.notation === n.notation && item.cible.id === n.cible.id,
      )
      if (index) {
        this.adventices.forEach((d, i) => {
          if (index.id === d.id) {
            this.adventices.splice(i, 1)
          }
        })
      }
      this.adventices.push(item)
    })

    this.setLocalStorage('adventices')
  }

  // eslint-disable-next-line class-methods-use-this
  getItem(type, id) {
    return this[type]
      .find((item) => parseInt(item.id, 10) === parseInt(id, 10))
  }

  // eslint-disable-next-line class-methods-use-this
  removeItem(type, id) {
    const index = this[type].find((n) => id === n.id)
    if (index) {
      this[type].forEach((d, i) => {
        if (index.id === d.id) {
          this[type].splice(i, 1)
        }
      })
    }
    this.setLocalStorage(type)
  }

  setLocalStorage(itemKey) {
    if (!localStorage.getItem(itemKey)) {
      localStorage.setItem(itemKey, JSON.stringify(this[itemKey]))
    } else {
      localStorage.removeItem(itemKey)
      this.setLocalStorage(itemKey)
    }
  }

  getFromLocalStorage(itemKey) {
    if (localStorage.getItem(itemKey)) {
      this[itemKey] = JSON.parse(localStorage.getItem(itemKey))
      return this[itemKey]
    }
    return []
  }

  // DATABASE METHODS

  // GLOBALS METHODS

  // eslint-disable-next-line class-methods-use-this
  getDates() {
    const d1 = new Date()
    d1.setHours(d1.getHours() + 1)
    const d2 = new Date()
    d2.setDate(d1.getDate() + parseInt(process.env.VUE_APP_RESERVATION_VALIDITY, 10))
    d2.setHours(d2.getHours() + 1)
    const validity = d2.toISOString().slice(0, 19).replace('T', ' ')
    return {
      now: d1.toISOString().slice(0, 19).replace('T', ' '),
      validity,
    }
  }

  setRouter(router) {
    this.router = router
  }

  setEmitter(emitter) {
    this.emitter = emitter
  }

  /**
   * {
   * valeur:,
   * valeurautre,
   * nonapplicable,
   * numeroechantillon,
   * variable_id,
   * valeur_liste_id,
   * organe_id,
   * microparcelle_id,
   * }
   */
  // eslint-disable-next-line class-methods-use-this

  setNote(newNote, reservationId) {
    const notes = this.getNotes(reservationId)

    for (let i = 0; i <= notes.length; i += 1) {
      if (notes[i] && notes[i]?.euid === newNote.euid) {
        notes.splice(i, 1)
      }
    }

    if (this.hasToPush(newNote, true)) {
      notes.push(newNote)
    }

    const reservation = this.getItem('reservations', reservationId)
    reservation.notation.notes = notes
    this.setItem('reservations', reservation)
  }

  getNotes(reservationId) {
    const reservation = this.getItem('reservations', reservationId)
    return reservation.notation.notes
  }

  geStartingNote(reservationId) {
    const reservation = this.getItem('reservations', reservationId)
    return reservation.notation.startingnote
  }

  // eslint-disable-next-line class-methods-use-this
  checkValues(item) {
    return (
      (item?.valeur !== null && item?.valeur !== '')
      || (item?.valeur_liste_id !== null && item?.valeur_liste_id !== '' && item?.valeur_liste_id !== undefined)
      || (item?.valeurautre !== null && item?.valeurautre !== '')
    )
  }

  // eslint-disable-next-line class-methods-use-this
  hasToPush(item, all) {
    if (all) {
      return this.checkValues(item) || item?.nonapplicable === true
    }
    return this.checkValues(item) || item?.nonapplicable !== true
  }

  // eslint-disable-next-line class-methods-use-this
  getModalites(frames) {
    const modalites = []
    Object.keys(frames).forEach((localisation) => {
      switch (localisation) {
        default:
          break
        case 'terrain':
        case 'labo':
          if (frames[localisation].length > 0) {
            frames[localisation].forEach((frame) => {
              const { modalite } = frame.microparcelle
              const found = modalites.find((item) => item.id === modalite.id)
              if (!found) {
                modalites.push(modalite)
              }
            })
          }
          break
      }
    })

    return modalites
  }

  getFieldsForFrame(frame, variable) {
    const fields = []

    frame.variables_etudiees.forEach((ve) => {
      if (variable.id === ve.id) {
        if (frame.groupe === 'ORGANE' && ve.organes !== null) {
          ve.organes.forEach((organe) => {
            for (let i = 0; i < organe.nb_echantillons; i++) {
              fields.push(
                {
                  id: null,
                  euid: this.findIdPattern(frame, ve, i, organe),
                  // Numeric
                  valeur: null,
                  // Varchar, date, boolean
                  valeurautre: null,
                  nonapplicable: false,
                  // List
                  valeur_liste_id: undefined,
                  numeroechantillon: i + 1,
                  variable_id: ve.id,
                  organe_id: organe.id,
                  modalite: frame?.modalite?.id,
                  microparcelle_id: frame?.microparcelle?.id,
                  error: false,
                  errorMsg: null,
                }
              )
            }
          })
        } else {

          let numeroechantillon = ve.echantillon ? ve.echantillon : null

          if (frame.groupe === 'ECHANTILLON') {
            numeroechantillon = ve.echantillon + 1

            if (ve.echantillon !== variable.echantillon) {
              return null
            }
          }

          fields.push(
            {
              id: null,
              euid: this.findIdPattern(frame, ve, numeroechantillon, null),
              // Numeric
              valeur: null,
              // Varchar, date, boolean
              valeurautre: null,
              nonapplicable: false,
              // List
              valeur_liste_id: undefined,
              numeroechantillon,
              variable_id: ve.id,
              organe_id: null,
              modalite: frame?.modalite?.id,
              microparcelle_id: frame?.microparcelle?.id,
              error: false,
              errorMsg: null,
            }
          )
        }
      }
    })
    return fields
  }

  // eslint-disable-next-line class-methods-use-this
  getNotesForFrame(idReservation, frame) {
    const notes = this.getNotes(idReservation)
    const veIds = []
    const organeIds = []
    const indexEch = []
    frame.variables_etudiees.forEach((ve) => {
      veIds.push(ve.id)

      if (ve.organes) {
        ve.organes.forEach((organe) => {
          organeIds.push(organe.id)
        })
      }

      if (frame.groupe === 'ECHANTILLON') {
        indexEch.push((ve.echantillon + 1))
      }

    })

    const frameNotes = []
    notes.forEach((note) => {
      if (note.microparcelle_id) {
        if (note.microparcelle_id === frame?.microparcelle?.id) {
          if (veIds.includes(note.variable_id)) {

            if (frame.groupe !== null && organeIds.includes(note.organe_id)) {
              if (frame.groupe === 'ECHANTILLON' && indexEch.includes(note.numeroechantillon)) {
                frameNotes.push(JSON.parse(JSON.stringify(note)))
              }
              if (frame.groupe !== 'ECHANTILLON') {
                frameNotes.push(JSON.parse(JSON.stringify(note)))
              }
            } else {
              frameNotes.push(JSON.parse(JSON.stringify(note)))
            }
          }
        }
      }
      if (note.modalite) {
        if (note.modalite === frame?.modalite?.id) {
          if (veIds.includes(note.variable_id)) {
            frameNotes.push(JSON.parse(JSON.stringify(note)))
          }
        }
      }
    })

    return frameNotes
  }

  findIdPattern(frame, variable, index = 0, organe = null) {

    let euid = ''
    let echantillon = null
    if (index !== null) {
      echantillon = parseInt(index, 10)
    }

    if (frame.groupe === 'ECHANTILLON') {
      // eslint-disable-next-line
      echantillon = variable.echantillon + 1
    }

    if (frame.groupe === 'ORGANE') {
      // eslint-disable-next-line
      echantillon = index + 1
    }

    if (frame.groupe === null && !frame?.microparcelle?.id) {
      euid = organe
        ? `${frame.modalite.id}-${variable.id}-${organe.id}`
        : `${frame.modalite.id}-${variable.id}`
    } else {
      euid = organe
        ? `${frame.microparcelle.id}-${variable.id}-${organe.id}`
        : `${frame.microparcelle.id}-${variable.id}`
    }

    if (echantillon) {
      euid = `${euid}-${echantillon}`
    }

    return euid
  }

}
export default new NotationService()
