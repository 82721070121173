<template>
  <component
    :is="getBtnTag()"
    :href="href"
    :to="to"
    class="btn"
    :class="{
      [`btn-${color}`]: color,
      [`btn-${size}`]: size,
      'btn-block': block,
      'btn-wrap': wrap,
      'btn-round': round,
      'btn-hollow': hollow,
      'btn-fat': fat,
      'btn-bg-grow': grow,
    }"
    :disabled="disabled"
    :type="btnType"
    v-ripple
  >
    <SvgIcon
      v-if="icon && !iconAfter"
      :name="icon"
      :size="getIconSize()"
    />

    <span v-if="text">
      {{ text }}
    </span>

    <SvgIcon
      v-if="icon && iconAfter"
      :name="icon"
      :size="getIconSize()"
    />

    <slot></slot>

  </component>
</template>

<script>
import SvgIcon from '@/components/base/SvgIcon.vue'

const iconSize = {
  xs: 'xxs',
  sm: 'xxs',
  default: 'xs', // Default btn's icon size
  lg: 'xs', // Default icon size
  xl: '',
}

export default {
  name: 'Btn',

  components: {
    SvgIcon,
  },

  props: {
    text: {
      type: String,
    },
    color: {
      type: String,
    },
    size: {
      type: String,
    },
    block: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    hollow: {
      type: Boolean,
      default: false,
    },
    fat: {
      type: Boolean,
      default: false,
    },
    grow: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    iconAfter: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
    },
    to: {
      type: [String, Object],
    },
    btnType: {
      type: String,
      default: 'button',
    },
  },

  methods: {
    getBtnTag() {
      if (this.href) {
        return 'a'
      }
      if (this.to) {
        return 'router-link'
      }
      return 'button'
    },
    getIconSize() {
      if (this.iconSize) {
        return this.iconSize
      } if (this.size) {
        return iconSize[this.size]
      }
      return iconSize.default
    },
  },
}
</script>

<style lang="scss" scoped>
/* BTN */

@mixin btn($mods: "") {
  @each $mod in $mods {
    // BASE

    @if $mod == "" {
      position: relative;
      // display: inline-block;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: $gutter-quarter; // Flex gap FTW!!!

      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle; // For icon buttons (fix alignment bug when icon only)
      text-transform: $btn-text-transform;
      font-weight: $btn-font-weight;

      touch-action: manipulation;
      cursor: pointer;
      background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214

      padding: $btn-padding; // Magic value!
      border: none;
      border-radius: $btn-border-radius;
      color: $body-color-base;
      background-color: transparent;

      transition: all 0.25s;

      &:not(:disabled) {
        // @include hocus {
        &:hover {
          outline: none; // Reset links default focus style
          color: $body-color-base;
        }
      }

      &:active {
        @include shadow(0);
      }

      &:disabled {
        @include shadow(0);
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    // LAYOUT

    @else if $mod == "block" {
      // display: block;
      display: flex;
      width: 100%;
    } @else if $mod == "round" {
      border-radius: 100rem;

      &::before {
        border-radius: 100rem;
      }
    } @else if $mod == "wrap" {
      white-space: normal !important;
    }

    // SIZES

    @else if $mod == "xs" {
      padding: 0.8rem;
      // min-height: 2.8rem;
      font-size: $font-size-smaller;
    } @else if $mod == "xxs" {
      padding: 0.4rem;
      // min-height: 2.8rem;
      font-size: $font-size-smaller;
    } @else if $mod == "sm" {
      padding: 1rem;
      // min-height: 3.4rem;
      font-size: $font-size-small;
    } @else if $mod == "lg" {
      padding: 1.4rem;
      // min-height: 4.8rem;
      font-size: $font-size-big;
    } @else if $mod == "xl" {
      padding: 1.6rem;
      // min-height: 5.6rem;
      font-size: $font-size-bigger;
    }

    // COLORS

    @else if $mod == "default" {
      background-color: $color-gray-lightest;
      color: $body-color-base;
      @include shadow($btn-shadow-active);

      &:not(:disabled) {
        @include hocus() {
          @if $btn-color-active {
            background-color: $color-gray-lighter;
          }
          color: black;
        }

        // &:active {
        //   background-color: $color-gray-lightest;
        // }
      }
    } @else if $mod == "primary" {
      background-color: $color-primary-lighty;
      color: white;
      @include shadow($btn-shadow-active);

      &:not(:disabled) {
        @include hocus() {
          @if $btn-color-active {
            background-color: $color-primary-light;
          }
          color: white;
        }

        // &:active {
        //   background-color: $color-primary-lightest;
        // }
      }
    } @else if $mod == "secondary" {
      background-color: $color-secondary-lighty;
      color: white;
      @include shadow($btn-shadow-active);

      &:not(:disabled) {
        @include hocus() {
          @if $btn-color-active {
            background-color: $color-secondary-light;
          }
          color: white;
        }

        // &:active {
        //   background-color: $color-secondary-lightest;
        // }
      }
    } @else if $mod == "accent" {
      background-color: $color-accent-lighty;
      color: white;
      @include shadow($btn-shadow-active);

      &:not(:disabled) {
        @include hocus() {
          @if $btn-color-active {
            background-color: $color-accent-light;
          }
          color: white;
        }

        // &:active {
        //   background-color: $color-accent-lightest;
        // }
      }
    } @else if $mod == "black" {
      background-color: black;
      color: $color-gray-lighter;
      @include shadow($btn-shadow-active);

      &:not(:disabled) {
        @include hocus() {
          @if $btn-color-active {
            background-color: $color-gray-darkest;
          }
          color: white;
        }
      }

      // &:active {
      //   background-color: $color-gray;
      // }
    } @else if $mod == "gray" {
      background-color: $color-gray;
      color: $color-gray-lightest;
      @include shadow($btn-shadow-active);

      &:not(:disabled) {
        @include hocus() {
          @if $btn-color-active {
            background-color: $color-gray-light;
          }
          color: white;
        }

        // &:active {
        //   background-color: $color-gray-lightest;
        // }
      }
    } @else if $mod == "white" {
      background-color: white;
      color: $color-gray;
      @include shadow($btn-shadow-active);

      &:not(:disabled) {
        @include hocus() {
          color: $color-gray-darkest;
        }

        // &:active {
        //   background-color: $color-gray-lightest;
        // }
      }
    } @else if $mod == "caret" {
      position: relative;
      padding-left: $gutter;
      padding-right: $gutter;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: $gutter;
        @include triangle("right", currentColor, $gutter-eighth * 3);

        opacity: 0;
        transform: translate3d(0, -50%, 0);
        transition: all 0.25s;
      }

      &:not(:disabled) {
        @include hocus() {
          padding-left: $gutter-quarter * 3;
          padding-right: $gutter-quarter * 5;

          &::after {
            opacity: 1;
            transform: translate3d($gutter-quarter, -50%, 0);
          }
        }
      }
    } @else if $mod == "bg-grow" {
      background-color: transparent;
      z-index: 0;
      color: $body-color-base;
      color: inherit;
      @include shadow(0);

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) scale(0);
        opacity: 0;
        width: 100%;
        height: 100%;
        background-color: $color-gray-lightest;
        transition: all 0.25s;
        z-index: -1;
      }

      &:not(:disabled) {
        // @include hocus() {
        &:hover {
          @include shadow(0);
          background-color: transparent;

          &::before {
            transform: translate3d(-50%, -50%, 0) scale(1);
            opacity: 1;
          }
        }
      }

      // &.btn-default {}

      &.btn-black {
        &::before {
          background-color: black;
        }
      }

      &.btn-gray {
        &::before {
          background-color: $color-gray;
        }
      }

      &.btn-white {
        &::before {
          background-color: white;
        }
      }

      &.btn-primary {
        &::before {
          background-color: $color-primary;
        }
      }

      &.btn-secondary {
        &::before {
          background-color: $color-secondary;
        }
      }

      &.btn-accent {
        &::before {
          background-color: $color-accent;
        }
      }
    } @else {
      @error '@mixin btn(): unknown modifier #{$mod}!';
    }
  }
}

// BUTTON CLASSES

@if $generate_classes and $generate-buttons-classes {
  .btn {
    @include btn();
  }

  // BUTTON SIZES

  .btn-xs {
    @include btn("xs");
  }
  .btn-xxs {
    @include btn("xxs");
  }
  .btn-sm {
    @include btn("sm");
  }
  .btn-lg {
    @include btn("lg");
  }
  .btn-xl {
    @include btn("xl");
  }

  // BUTTONS COLORS

  .btn-default {
    @include btn("default");
  }

  .btn-primary {
    @include btn("primary");
  }
  .btn-secondary {
    @include btn("secondary");
  }
  .btn-accent {
    @include btn("accent");
  }

  .btn-black {
    @include btn("black");
  }
  .btn-gray {
    @include btn("gray");
  }
  .btn-white {
    @include btn("white");
  }

  // HOLLOW BUTTONS
  // TODO: into the mixin too!!!

  .btn-hollow {
    background-color: transparent;
    border: $btn-border-size-hollow solid;
    padding: calc(#{$btn-padding} - #{$btn-border-size-hollow});
    @include shadow(0);

    &.btn-fat {
      border-width: $btn-border-size-fat;
      padding: calc(#{$btn-padding} - #{$btn-border-size-fat});
    }

    &:not(:disabled) {
      @include hocus() {
        @include shadow(0);
      }
    }

    &.btn-default {
      color: $color-gray-light;
      border-color: $color-gray-light;
      &:not(:disabled) {
        @include hocus() {
          background-color: $color-gray-light;
          color: black;
        }

        &:active {
          background-color: $color-gray-lightest;
        }
      }
    }
    &.btn-primary {
      color: $color-primary;
      border-color: $color-primary;

      &:not(:disabled) {
        @include hocus() {
          background-color: $color-primary;
          color: white;
        }

        &:active {
          background-color: $color-primary-lightest;
        }
      }
    }
    &.btn-secondary {
      color: $color-secondary;
      border-color: $color-secondary;

      &:not(:disabled) {
        @include hocus() {
          background-color: $color-secondary;
          color: white;
        }

        &:active {
          background-color: $color-secondary-lightest;
        }
      }
    }
    &.btn-accent {
      color: $color-accent;
      border-color: $color-accent;

      &:not(:disabled) {
        @include hocus() {
          background-color: $color-accent;
          color: white;
        }

        &:active {
          background-color: $color-accent-lightest;
        }
      }
    }
    &.btn-black {
      color: black;
      border-color: black;

      &:not(:disabled) {
        @include hocus() {
          background-color: black;
          color: $color-gray-lighter;
        }

        &:active {
          background-color: $color-gray;
        }
      }
    }
    &.btn-gray {
      color: $color-gray;
      border-color: $color-gray;

      &:not(:disabled) {
        @include hocus() {
          background-color: $color-gray;
          color: white;
        }

        &:active {
          background-color: $color-gray-lightest;
        }
      }
    }
    &.btn-white {
      color: white;
      border-color: white;

      &:not(:disabled) {
        @include hocus() {
          background-color: white;
          color: $color-gray-darker;
        }
      }
    }
  }

  // ROUNDED BUTTONS

  .btn-round {
    @include btn("round");
  }

  // BLOCK BUTTONS

  .btn-block {
    @include btn("block");
  }

  // WRAP BUTTONS (multiline)

  .btn-wrap {
    @include btn("wrap");
  }

  // CARET BUTTONS

  .btn-caret {
    @include btn("caret");
  }

  // BG GROW FX

  .btn-bg-grow {
    @include btn("bg-grow");
  }
}

// ICONS IN BUTTONS

.btn {
  .icon {
    // Prevents icon shrink with .btn-wrap and long content
    flex-shrink: 0;
  }

  // // Space between icons and text span
  // // Uncomment this if flex gap isn't supported
  // // Icon before text
  // .icon + span {
  //   margin-left: $gutter-quarter;
  // }

  // // Icon after text
  // span + .icon {
  //   margin-left: $gutter-quarter;
  // }
}

// RIPPLE FX

button,
.btn {
  position: relative;
  overflow: hidden;
}

// FROM OTHER COMPONENTS

// Forms buttons

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

// Submit button

input[type='submit']/*,
button[type='submit']*/ {
  @include btn();
  @include btn("primary");
}

// Tabs buttons

.styleguide-tabs-toggles {
  .tab-toggle {
    &.active {
      @include btn("default");
    }
  }
}

// List "sort by" buttons

.btn {

  .list-col--sortable & {
    justify-content: flex-start;
    @include h-padding(0);
  }
}

</style>
