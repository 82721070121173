<template>
  <div class="topbar">
    <!-- TOPBAR LEFT -->
    <div class="topbar-header">

      <!-- Main menu btn ('hamburger') -->
      <hamburger />

      <!-- Navigation dropdown (teleports here) -->
      <div id="topbar-navigation-dropdown"></div>

      <!-- Logo and baseline -->
      <router-link v-if="!isOnline" :to="{ name: 'home' }" class="topbar-logo" v-ripple>
        <img class="topbar-logo-logotype" alt="Le logo de OSS" src="../../assets/logo-oss-mobile.svg" />
        <!-- <img
          class="topbar-logo-baseline"
          alt="Outil de saisie et de synthèse"
          src="../../assets/baseline-oss-mobile.svg"
        /> -->
      </router-link>
      <a href="#" v-if="isOnline" @click="toHome()" class="topbar-logo" v-ripple>
        <img class="topbar-logo-logotype" alt="Le logo de OSS" src="../../assets/logo-oss-mobile.svg" />
        <!-- <img
          class="topbar-logo-baseline"
          alt="Outil de saisie et de synthèse"
          src="../../assets/baseline-oss-mobile.svg"
        /> -->
      </a>

    </div>
    <!-- End .topbar-header -->

    <!-- TOPBAR MIDDLE -->
    <div class="topbar-body">
      <h1 class="topbar-page-title" v-html="pageTitle"></h1>
    </div>

    <!-- TOPBAR RIGHT -->
    <div class="topbar-footer">

      <!-- Tool menu ('kefta') -->
      <!-- Views will teleport menu content here -->
      <div class="topbar-menu topbar-menu--tool" id="tool-menu" />

      <!-- Help button -->
      <!-- Views will teleport help button here -->
      <div class="topbar-menu topbar-menu--help" id="topbar-help-button" />

      <!-- User menu -->
      <div class="topbar-menu topbar-menu--user">
        <template v-if="!isOnline">offLine</template>
        <Btn class="btn-toggle-user-panel" round grow @click="emitEventToggleUserMenu">
          <UserID v-if="loggedIn" :userNameFirstName="user.prenom" :userNameLastName="user.nom" userNameSize="small"
            userNameLayout="block" :userAvatarColor="user.couleur" userAvatarSize="small" />
        </Btn>

        <nav class="topbar-menu-nav topbar-menu-nav--user">

          <ul class="menu">
            <MenuItem :item-data="{
        label: 'Mon profil',
        route: { name: 'myAccount' },
        iconName: 'user',
      }" />
            <MenuItem :item-data="{
        label: 'Modifier le mot de passe',
        route: { name: 'myAccountEditPassword' },
        iconName: 'key',
      }" />
            <MenuItem :item-data="{
        label: 'À propos d\'OSS',
        route: { name: 'about' },
        iconName: 'question-circle',
      }" />
            <MenuItem :item-data="{
        label: 'Se déconnecter',
        route: { name: 'login', query: { logout: true } },
        iconName: 'window-close',
      }" />
          </ul>
        </nav>
      </div>
    </div>
    <!-- End .topbar-footer -->
  </div>
  <!-- End .topbar -->
</template>

<script>
import Hamburger from '@/components/layout/Hamburger.vue'
import Btn from '@/components/base/Btn.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import UserID from '@/components/user/UserID.vue'

export default {
  name: 'TopBar',

  components: {
    Hamburger,
    Btn,
    MenuItem,
    UserID,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      isOnline: navigator.onLine,
    }
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.user.loggedIn
    },
    user() {
      return this.$store.state.auth.user.data
    },
  },
  methods: {
    // EVENT EMITTING
    emitEventToggleUserMenu() {
      this.emitter.emit('toggle-user-panel')
    },
    toHome() {
      window.location.href = '/'
    },
  },
}
</script>

<style lang="scss" scoped>
/* TOPBAR */

.topbar {
  display: flex;
  justify-content: space-between;
  height: $header-height-mobile;
  transition: all 0.25s;

  @include bp($breakpoint-sidebar-compact) {
    height: $header-height-tablet;

    .has-scrolled-down & {
      @include shadow(1);
    }
  }

  @include bp($breakpoint-sidebar-full) {
    height: $header-height-desktop;
  }
}

.topbar-header,
.topbar-body,
.topbar-footer {
  display: flex;
  align-items: center;
  @include h-padding($gutter-half);
}

.topbar-body {
  display: none;

  @include bp('md') {
    // display: flex; // TMP: uncommment this to activate topbar title
    flex-grow: 1;
  }
}

.topbar-header {
  @include bp($breakpoint-sidebar-compact) {
    &::after {
      content: "";
      position: absolute;
      display: block;
      @include size($header-height-tablet);
      background-color: white;
      left: 0;
      top: 0;
      @include shadow(1);
    }
  }

  @include bp($breakpoint-sidebar-full) {
    &::after {
      display: none;
    }
  }
}

.topbar-footer {
  @include bp($breakpoint-sidebar-compact) {
    padding-right: $gutter;
  }

  @include bp($breakpoint-sidebar-full) {
    padding-right: $gutter-and-half;
  }

  > * {
    &:not(:last-child) {
      margin-right: $gutter-quarter;
    }
  }
}

// NAVIGATION DROPDOWN

#topbar-navigation-dropdown {
  margin-left: $gutter-half;
  display: flex;

  @include bp($breakpoint-sidebar-compact) {
    margin-left: $gutter + $gutter-eighth;
  }

  @include bp($breakpoint-sidebar-full) {
    margin-left: $gutter;
  }
}

// TOPBAR LOGO

.topbar-logo {
  // margin-left: $gutter-half;
  display: flex;

  @include bp($breakpoint-sidebar-compact) {
    // margin-left: $gutter + $gutter-eighth;
  }

  @include bp($breakpoint-sidebar-full) {
    display: none;
  }

  > img {
    display: block;
    transform: translateY(2px); // Vertical align (art direction)
  }

  > .topbar-logo-baseline {
    margin-left: $gutter-quarter;
    display: none;

    @include bp("xxs") {
      display: block;
    }
  }
}

#topbar-navigation-dropdown:not(:empty) + .topbar-logo {
  display: none;
}

// MAIN MENU BUTTON ("hamburger")

.btn-toggle-menu-panel {
  .topbar & {
    @include bp($breakpoint-sidebar-compact) {
      position: relative;
      margin-left: -$gutter-quarter;
      margin-right: $gutter-quarter;
    }

    @include bp($breakpoint-sidebar-full) {
      display: none;
    }
  }
}

// TOPBAR BUTTONS

.btn-toggle-menu-panel,
::v-deep(.btn-toggle-tool-panel) {
  color: $color-gray-dark; // TODO: better
}

// TOOL AND USER MENUS

::v-deep(.topbar-menu) {
  position: relative;
}

::v-deep(.topbar-menu-nav) {
  overflow: hidden;
  position: absolute;
  width: 24rem;
  right: 0;
  top: 4rem + $gutter-half;
  border-radius: $border-radius-base;

  transform: translateY(-$gutter-double);
  opacity: 0;
  pointer-events: none; // Let events pass through
  transition: all 0.25s $ease-in-out;

  background-color: white;
  @include shadow(3);

  &.topbar-menu-nav--alt {
    background-color: $color-gray-lightest;
    border: 3px solid white;
  }
}

::v-deep(.topbar .btn-bg-grow) {
  @include bp($breakpoint-sidebar-compact) {
    &::before {
      background-color: $color-gray-lighter;
    }
  }
}

// USER MENU BUTTON (avatar + name)

.btn-toggle-user-panel {
  padding: 0;
  border-radius: 1000rem;

  @include bp($breakpoint-user-menu-with-name) {
    padding-right: $gutter-half;
  }

  /*.app-wrapper*/
  .is-panel-user-open & {
    background-color: $color-primary;

    &:hover {
      background-color: $color-primary;
    }

    :deep(.user-avatar) {
      color: $color-primary;
    }

    :deep(.user-name) {
      color: white;
    }

    &::before {
      background-color: rgba(white, 0.5);
    }
  }
}

.topbar-menu-nav--user {

  /*.app-wrapper*/
  .is-panel-user-open & {
    transform: translateY(0);
    opacity: 1;
    pointer-events: initial;
  }
}

// Page title on scroll
.topbar-page-title {
  margin-bottom: 0;
  font-size: $font-size-bigger;
  opacity: 0;
  transform: translateY($gutter-quarter);
  transition: all 0.25s;

  .has-scrolled-down & {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
