export default [
  {
    path: '/mon-compte',
    name: 'myAccount',
    meta: {
      public: false,
      title: 'Mon profil',
    },
    component: () => import(
      '@/views/users/MyAccountView.vue'
    ),
  },
  {
    path: '/a-propos',
    name: 'about',
    meta: {
      public: false,
      title: 'À propos d\'OSS',
    },
    component: () => import(
      '@/views/users/AboutView.vue'
    ),
  },
  {
    path: '/mon-compte/edition',
    name: 'myAccountEdit',
    meta: {
      public: false,
      title: 'Modifier mon profil',
    },
    component: () => import(
      '@/views/users/MyAccountEditView.vue'
    ),
  },
  {
    path: '/mon-compte/modifier-mot-de-passe',
    name: 'myAccountEditPassword',
    meta: {
      public: false,
      title: 'Modifier le mot de passe',
    },
    component: () => import(
      '@/views/users/MyAccountEditPasswordView.vue'
    ),
  },
  {
    path: '/utilisateurs',
    name: 'users',
    meta: {
      public: false,
      title: 'Liste des utilisateurs',
      permissions: 'utilisateur_list_full, utilisateur_list_entite',
    },
    component: () => import(
      '@/views/users/UsersView.vue'
    ),
  },
  {
    path: '/utilisateur/:userid',
    name: 'user',
    meta: {
      public: false,
      title: 'Utilisateur',
      permissions: 'utilisateur_show_full,utilisateur_show_entite',
    },
    component: () => import(
      '@/views/users/UserView.vue'
    ),
  },
  {
    path: '/utilisateur/creer',
    name: 'userAdd',
    meta: {
      public: false,
      title: 'Créer un utilisateur',
      permissions: 'utilisateur_add_full,utilisateur_add_entite',
    },
    component: () => import(
      '@/views/users/UserAddView.vue'
    ),
  },
  {
    path: '/utilisateur/:userid/edition',
    name: 'userEdit',
    meta: {
      public: false,
      title: 'Edition utilisateur',
      permissions: 'utilisateur_edit_full,utilisateur_edit_entite',
    },
    component: () => import(
      '@/views/users/UserEditView.vue'
    ),
  },
  { // Listing
    path: '/utilisateur/:userid/droits',
    name: 'userPermissions',
    meta: {
      public: false,
      title: 'Log',
    },
    component: () => import(
      '@/views/users/UserProfilView.vue'
    ),
  },
]
