<template>
  <div @click="emitEventCloseOverlayAndPanels" class="overlay"></div>
</template>

<script>
export default {
  name: 'Overlay',

  methods: {
    // EVENT EMITTING
    emitEventCloseOverlayAndPanels() {
      this.emitter.emit('close-overlay-and-panels')
    },
  },
}
</script>

<style lang="scss" scoped>
/* OVERLAY */

////////////////////////////////////////////////////////////////////////////////
// MIXINS

// OVERLAY

@mixin overlay() {
  z-index: $z-index-overlay;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  // background-image: repeating-linear-gradient(
  //     45deg,
  //     transparent,
  //     transparent 2px,
  //     rgba(0, 0, 0, 0.125) 2px,
  //     rgba(0, 0, 0, 0.125) 4px
  // );

  opacity: 0;
  transition: opacity 0.5s, transform 0.25s;
  backdrop-filter: blur(3px);
  pointer-events: none; // Let events pass through

  /*.app-wrapper*/
  .has-overlay & {
    opacity: 1;
    pointer-events: initial; // Allows click on overlay for closing modal
  }
}

////////////////////////////////////////////////////////////////////////////////
// CLASSES

@if $generate_classes and $generate-overlay-classes {
  // OVERLAY

  // No page scrolling when overlay is active
  /*.app-wrapper*/
  .has-overlay {
    overflow-y: hidden;
  }

  .overlay {
    @include overlay();
  }
}
</style>
