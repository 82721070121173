<template>
  <div class="loader"
    :class="{
      [`loader-${size}`]: size,
      'loader--active': active,
      'loader--global': global
    }">
    <div class="loader__overlay"></div>
    <div class="loader__spinner">
      <div class="sk-loader">
        <div class="sk-circle-fade">
          <div class="sk-circle-fade-dot"></div>
          <div class="sk-circle-fade-dot"></div>
          <div class="sk-circle-fade-dot"></div>
          <div class="sk-circle-fade-dot"></div>
          <div class="sk-circle-fade-dot"></div>
          <div class="sk-circle-fade-dot"></div>
          <div class="sk-circle-fade-dot"></div>
          <div class="sk-circle-fade-dot"></div>
          <div class="sk-circle-fade-dot"></div>
          <div class="sk-circle-fade-dot"></div>
          <div class="sk-circle-fade-dot"></div>
          <div class="sk-circle-fade-dot"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loader',

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    global: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
/* LOADER */

.loader,
.loader__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.loader--global {
  z-index: $z-index-modal;
}

.loader {
  opacity: 0;
  transition: all 0.25s;

  &.loader--active,
  .has-loader & {
    opacity: 1;
  }
}

.loader__overlay {
  background-color: rgba(255, 255, 255, 0.75);
}

.loader,
.loader__overlay,
.loader__spinner {
  pointer-events: none; // Let events pass through
}

.has-loader {

  .loader,
  .loader__overlay,
  .loader__spinner {
    pointer-events: auto;
  }
}

// See: https://tobiasahlin.com/spinkit/

/* Config */
$sk-size: 4rem;
$sk-color: $color-gray-dark;

/* Loader */
.sk-loader {
  @include size($sk-size);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/*  Circle Fade */
.sk-circle-fade {
  width: $sk-size;
  height: $sk-size;
  position: relative;
}

.sk-circle-fade-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle-fade-dot:before {
  content: "";
  display: block;
  width: 15%;
  height: 15%;
  background-color: $sk-color;
  border-radius: 100%;
  animation: sk-circle-fade 1.2s infinite ease-in-out both;
}

.sk-circle-fade-dot:nth-child(1) {
  transform: rotate(30deg);
}

.sk-circle-fade-dot:nth-child(2) {
  transform: rotate(60deg);
}

.sk-circle-fade-dot:nth-child(3) {
  transform: rotate(90deg);
}

.sk-circle-fade-dot:nth-child(4) {
  transform: rotate(120deg);
}

.sk-circle-fade-dot:nth-child(5) {
  transform: rotate(150deg);
}

.sk-circle-fade-dot:nth-child(6) {
  transform: rotate(180deg);
}

.sk-circle-fade-dot:nth-child(7) {
  transform: rotate(210deg);
}

.sk-circle-fade-dot:nth-child(8) {
  transform: rotate(240deg);
}

.sk-circle-fade-dot:nth-child(9) {
  transform: rotate(270deg);
}

.sk-circle-fade-dot:nth-child(10) {
  transform: rotate(300deg);
}

.sk-circle-fade-dot:nth-child(11) {
  transform: rotate(330deg);
}

.sk-circle-fade-dot:nth-child(1):before {
  animation-delay: -1.1s;
}

.sk-circle-fade-dot:nth-child(2):before {
  animation-delay: -1s;
}

.sk-circle-fade-dot:nth-child(3):before {
  animation-delay: -0.9s;
}

.sk-circle-fade-dot:nth-child(4):before {
  animation-delay: -0.8s;
}

.sk-circle-fade-dot:nth-child(5):before {
  animation-delay: -0.7s;
}

.sk-circle-fade-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

.sk-circle-fade-dot:nth-child(7):before {
  animation-delay: -0.5s;
}

.sk-circle-fade-dot:nth-child(8):before {
  animation-delay: -0.4s;
}

.sk-circle-fade-dot:nth-child(9):before {
  animation-delay: -0.3s;
}

.sk-circle-fade-dot:nth-child(10):before {
  animation-delay: -0.2s;
}

.sk-circle-fade-dot:nth-child(11):before {
  animation-delay: -0.1s;
}

.loader-xs {
  .sk-loader {
    @include size(2rem);
  }
  .sk-circle-fade {
  width:2rem;
  height: 2rem;
}
}

@keyframes sk-circle-fade {

  0%,
  39%,
  100% {
    opacity: 0;
    transform: scale(0.6);
  }

  40% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
