/* eslint-disable no-console */

import { register } from 'register-service-worker'

const currentDate = new Date()
const timestamp = currentDate.getTime()

// eslint-disable-next-line
const CACHE_NAME = `${timestamp}`

register(`${process.env.BASE_URL}service-worker.js`, {

  ready() {
    console.log(
      'App is being served from cache by a service worker.\n'
      + 'For more details, visit https://goo.gl/AFskqB',
    )
  },
  registered(registration) {
    console.log('Service worker has been registered.', registration)
    registration.update()
    setInterval(() => {
      registration.update()
    }, 120000) // e.g. hourly checks 1000 * 60 * 60
  },
  cached() {
    console.log('Content has been cached for offline use.')
  },
  updatefound() {
    console.log('New content is downloading.')
  },
  update() {
    console.log('update interval')
  },
  updated(registration) {
    document.dispatchEvent(
      new CustomEvent('swUpdated', { detail: registration }),
    )
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error(error) {
    console.error('Error during service worker registration:', error)
  },

  install() {
    console.log('install Service worker')
  },

  activate(registration) {
    console.log('Service worker has been activate.', registration)
  },

})
